import playlistSlug from "./slug"

const handleShare = (c, show, playlist) => {
	return (event) => {
		event.preventDefault()
		const elementTocopy = document.createElement('textarea')
		elementTocopy.className = "hidden"
		const { location: { origin, pathname } } = window
		const path = pathname.replace(/^(\/[^\/]*).*$/, "$1")
		const playlistPath = playlistSlug(playlist)
		elementTocopy.value = `${origin}${path}/playlists/${playlistPath}`
		document.body.appendChild(elementTocopy)
		elementTocopy.select()
		document.execCommand('copy')
		document.body.removeChild(elementTocopy)
		show({
			message: c("copied-link")
		})
	}
}

export default handleShare
