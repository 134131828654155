import image, { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"
import Image from "components/common/image"

export default ({product, type, title, loading}) => {
	return (
		<figure className={loading ? "skeleton" : ""}>
			<picture>
				<Image
					src={image(product, type || PRODUCT_PHOTOGRAMME)}
					alt={title || product.name}
				/>
			</picture>
		</figure>
	)
}