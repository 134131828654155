import { useEffect, useState, useContext, createContext } from "react"
import { useApolloClient } from "@apollo/react-hooks"
import { SVOD_RAIL_TVOD, metadata } from "lacinetek-common/src/metadata"

import { i18n } from "lib/i18n"
import { useCountry } from "lib/country"
import { useTranslation } from "lib/i18n/translation"
import { useLanguages } from "lib/languages"

import { filmFilterDataLayer } from "lib/gtm/interactions"
import { FETCH_CATEGORIES, FETCH_SVOD_PRODUCTS } from "lib/graphql"

import {
	TYPE,
	GENRE,
	MOVEMENT
} from "lib/tools/categories"

import isBrowser from "lib/tools/is-browser"
import { isAvailable, isAvailableSoon } from "./availability"

const productsContext = createContext()
const productsFiltersContext = createContext()

export const ProductsConsumer = productsContext.Consumer
export const ProductsFiltersConsumer = productsFiltersContext.Consumer

export const ProvideProducts = ({ children, catalog, lang, fetchAllFilms, initFilms, getFilms, ssrFilmsBase }) => {
	const value = useProvideProducts(catalog, lang, fetchAllFilms, initFilms, getFilms, ssrFilmsBase)

	return (
		<productsContext.Provider value={value}>
			{children}
		</productsContext.Provider>
	)
}

export const ProvideProductsFilters = ({ children }) => {
	const filters = useProvideProductsFilters()

	return (
		<productsFiltersContext.Provider value={filters}>
			{children}
		</productsFiltersContext.Provider>
	)
}

export const useProducts = () => {
	return useContext(productsContext)
}

export const useProductsFilters = () => {
	return useContext(productsFiltersContext)
}

export const CATEGORY_FILTER = "category"
export const DISPLAY_FILTER = "display"
export const TOP_FILTER = "top"
export const BOTTOM_FILTER = "bottom"
export const CUSTOM_FILTER = "custom"

export const ALL_FILMS_CATEGORY = "all-films-category"
export const DIRECTORS_CATEGORY = "directors-category"
export const YEAR_CATEGORY = "year-category"
export const COUNTRY_CATEGORY = "country-category"
export const ACTOR_CATEGORY = "actor-category"

export const LIST_DISPLAY = "list"
export const GRID_DISPLAY = "grid"

export const ALL_TOP_FILTER = "all"
export const QUOTED_TOP_FILTER = "quoted"
export const AVAILABLE_TOP_FILTER = "available"

export const GENRE_FILTER = "genre"
export const PUBLIC_FILTER = "public"
export const TYPE_FILTER = "type"
export const MOVEMENT_FILTER = "movement"

const baseFilters = {
	base: true,
	category: ALL_FILMS_CATEGORY,
	display: LIST_DISPLAY,
	top: AVAILABLE_TOP_FILTER,
	bottom: [],
	custom: []
}

const baseNotPurchasableFilters = {
	base: true,
	category: ALL_FILMS_CATEGORY,
	display: LIST_DISPLAY,
	top: QUOTED_TOP_FILTER,
	bottom: [],
	custom: []
}

export const available = {
	categories: [
		{ title: ALL_FILMS_CATEGORY, icon: "reel" },
		{ title: DIRECTORS_CATEGORY, icon: "chair" },
		{ title: YEAR_CATEGORY, icon: "calendar" },
		{ title: COUNTRY_CATEGORY, icon: "location" },
	],
	display: [
		{ title: GRID_DISPLAY },
		{ title: LIST_DISPLAY }
	],
	top: [
		{ title: AVAILABLE_TOP_FILTER },
		{ title: QUOTED_TOP_FILTER }
	],
	bottom: [
		{ title: TYPE_FILTER, values: [] },
		{ title: GENRE_FILTER, values: [] },
		{ title: MOVEMENT_FILTER, values: [], sticky: true }
	]
}

export const availableNotPurchasable = {
	categories: [
		{ title: ALL_FILMS_CATEGORY, icon: "reel" },
		{ title: DIRECTORS_CATEGORY, icon: "chair" },
		{ title: YEAR_CATEGORY, icon: "calendar" },
		{ title: COUNTRY_CATEGORY, icon: "location" },
	],
	display: [
		{ title: GRID_DISPLAY },
		{ title: LIST_DISPLAY }
	],
	top: [
		{ title: QUOTED_TOP_FILTER }
	],
	bottom: [
		{ title: TYPE_FILTER, values: [] },
		{ title: GENRE_FILTER, values: [] },
		{ title: MOVEMENT_FILTER, values: [], sticky: true }
	]
}


function useProvideProducts(ssrCatalog, lang, fetchAllFilms, initFilms, getFilms, ssrFilmsBase) {
	const { filters, setLoading } = useProductsFilters()
	const { current } = useLanguages()
	const [displayMode, setDisplayMode] = useState(baseFilters.display)
	const { c } = useTranslation("common")
	const { country } = useCountry()

	const onLanguageChange = (language) => {
		if (language !== current) {
			fetchCatalog()
		}
	}

	initFilms(ssrFilmsBase)

	useEffect(() => {
		if (filters.display !== displayMode) {
			setDisplayMode(filters.display)
			setLoading(false)
		} else if (catalogLoaded && !filters.base) {
			fetchCatalog()
		}

		i18n.on('languageChanged', onLanguageChange)
		return () => {
			i18n.off('languageChanged', onLanguageChange)
		}

	}, [filters])

	const [data, setData] = useState({
		products: !isBrowser ? fetchAllFilms()[lang] : [],
		selectedProducts: [],
		sections: []
	})

	const isVisible = (item) => {
		if (item.products) {
			return item.products.filter(p => isVisible(p)).length > 0
		} else if (item.sections) {
			return item.sections.filter(s => isVisible(s)).length > 0
		} else {
			return data.selectedProducts.includes(item.id ? item.id.toString() : item.toString())
		}
	}

	const [catalog, setCatalog] = useState(ssrCatalog ? (ssrCatalog.sections || ssrCatalog) : [])
	const [currentSection, setCurrentSection] = useState(null)
	const [catalogLoading, setCatalogLoading] = useState(false)
	const [catalogLoaded, setCatalogLoaded] = useState(ssrCatalog ? true : false)
	const [totalProductsCount, setTotalProductsCount] = useState(ssrCatalog ? ssrCatalog.total : 0)
	const [productsCount, setProductsCount] = useState(ssrCatalog ? ssrCatalog.selectedTotal : 0)

	const fetchCatalog = (baseFilters) => {
		setCatalogLoading(true)
		setLoading(true)

		let appliedFilters = filters

		if (baseFilters) {
			baseFilters.forEach(f => {
				appliedFilters[f.key] = filters[f.key].concat([f.value])
			})
		}

		fetch("/fetch-catalog", {
			method: "POST",
			headers: {
				"Accept": "application/json, text/plain, */*",
				"Content-Type": "application/json",
				"country": country.isoCode
			},
			body: JSON.stringify(appliedFilters)
		})
			.then(response => {
				response.json()
					.then(body => {
						if (filters[CATEGORY_FILTER] == COUNTRY_CATEGORY) {
							body.sections = sortCountries(body.sections)
						}
						if (filters[CATEGORY_FILTER] == DIRECTORS_CATEGORY) {
							body.sections = sortDirectors(body.sections)
						}
						setTotalProductsCount(body.total)
						setProductsCount(body.selectedTotal)
						setCatalog([...(body.sections ? body.sections : body)])
						setCurrentSection((body.sections && body.sections.length > 0) ? body.sections[0].title : null)
						setCatalogLoading(false)
						setCatalogLoaded(true)
						setLoading(false)
						setDisplayMode(filters.display)
					})
			})
	}

	const customSectionSorting = (sections, subSectionSorting, subSectionIdentity) => {
		let newSections = {}
		let currentSection = null
		let currentSubsection = null
		sections.forEach(section => {
			if (section.type == "section") {
				currentSection = section.title
				newSections[currentSection] = {}
				currentSubsection = null
			} else if (section.type == "subsection") {
				currentSubsection = c(section.title)
				newSections[currentSection][currentSubsection] = []
			} else if (currentSubsection) {
				newSections[currentSection][currentSubsection].push(section)
			}
		})

		let allSubsections = []
		Object.keys(newSections).forEach(key => {
			Object.keys(newSections[key]).forEach(sKey => {
				allSubsections.push({ key: sKey, sections: newSections[key][sKey] })
			})
		})

		allSubsections = allSubsections.sort(subSectionSorting)
		let result = []
		currentSection = null
		allSubsections.forEach(sub => {
			const identity = subSectionIdentity(sub)
			if (identity !== currentSection) {
				currentSection = identity
				result.push({ type: "section", title: currentSection })
				result.push({ empty: true })
				result.push({ empty: true })
				result.push({ empty: true })
			}
			result.push({ type: "subsection", title: sub.key })
			result = [...result, ...sub.sections]
		})
		return result
	}

	const sortCountries = (sections) => {
		return customSectionSorting(
			sections,
			(a, b) => a.key.localeCompare(b.key),
			sub => (sub.key[0].toUpperCase()).normalize('NFD').replace(/[\u0300-\u036f]/g, "")
		)
	}

	const sortDirectors = (sections) => {
		return customSectionSorting(
			sections,
			(a, b) => compareDirectorsLastName(a.key, b.key),
			sub => getDirectorLastName(sub.key)[0].toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
		)
	}

	const getDirectorLastName = (director) => {
		return director.split(/\s+/).pop()
	}

	const compareDirectorsLastName = (directorA, directorB) => {
		return getDirectorLastName(directorA).localeCompare(getDirectorLastName(directorB))
	}

	const fetchSection = (section) => {
		fetch(`/fetch-section/${JSON.stringify({ title: section.title, count: section.current + section.perPage })}`, {
			method: "POST",
			headers: {
				"Accept": "application/json, text/plain, */*",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(filters)
		})
			.then(response => {
				response.json()
					.then(body => {
						let newCatalog = [...catalog]
						newCatalog[newCatalog.map(s => s.title).indexOf(body.title)] = body
						setCatalog(newCatalog)
					})
			})
	}

	return {
		productsCount,
		totalProductsCount,
		catalog,
		fetchCatalog,
		currentSection,
		fetchSection,
		...data,
		isAvailable,
		isAvailableSoon,
		isVisible,
		displayMode,
		setDisplayMode,
		getFilms,
		ssrFilmsBase
	}
}

const useProvideProductsFilters = () => {
	const client = useApolloClient()

	const { originCountry, country, svodAvailable } = useCountry()

	const [availableFilters, setAvailableFilters] = useState(originCountry ? available : availableNotPurchasable)
	const [filters, setFilters] = useState(originCountry ? baseFilters : baseNotPurchasableFilters)
	const [loading, setLoading] = useState(false)
	const [subscriptionCategoriesId, setSubscriptionCategoriesId] = useState([])

	const initProductsInSubscription = () => {
		if (!svodAvailable) {
			return
		}
		client.query({
			query: FETCH_SVOD_PRODUCTS,
			variables: { query: `abonnement-${country.isoCode}` }
		})
			.then(({ data: { cms: { categories: { items } } } }) => {
				let categoriesId = []

				items[0].children.items.forEach(category => {
					if (category.products.items.length > 0) {
						categoriesId = categoriesId.concat(category.id)
					}
					if (category.children.items.length > 0) {
						category.children.items.forEach(subCategory => {
							if (subCategory.products.items.length > 0 && metadata(subCategory,SVOD_RAIL_TVOD) !== "Oui") {
								categoriesId = categoriesId.concat(subCategory.id)
							}
						})
					}
				})
				setSubscriptionCategoriesId(categoriesId)
			})
	}

	useEffect(() => {
		initialize()
		initProductsInSubscription()
	}, [])

	useEffect(() => {
		initialize()
	}, [i18n.language])

	const updateFilters = (key, value) => {
		setLoading(true)
		const change = {}
		change[key] = value
		const currentFilters = Object.assign({}, filters, change)
		currentFilters.base = false
		filmFilterDataLayer(filters[key], value)
		setFilters(currentFilters)
		if (isBrowser) window.scrollTo(0, 0)
	}

	const initializeAvailableFilters = () => {
		let categories = process.env.CATEGORIES.filter(c => [TYPE, GENRE, MOVEMENT].includes(c.key))
		client.query({ query: FETCH_CATEGORIES, variables: { ids: categories.map(c => c.id) }, fetchPolicy: "network-only" })
			.then(({ data: { cms: { categories: { items } } } }) => {
				let aF = Object.assign({}, availableFilters)
				categories.forEach(category => {
					aF.bottom.find(f => f.title == category.key).values = items.find(i => i.id == category.id).children.items.map(i => ({ ...i, parent: category.id }))
				})
				setAvailableFilters(aF)
			})
	}

	const initialize = () => {
		initializeAvailableFilters()
	}

	return {
		availableFilters,
		filters,
		initialize,
		updateFilters,
		loading,
		setLoading,
		subscriptionCategoriesId
	}
}
