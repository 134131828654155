import {
	FETCH_LANGUAGES,
	FETCH_PAGE,
	FETCH_SUBSCRIPTIONS,

	FETCH_PLATFORM_ACCESS,
	FETCH_ADMIN_COUNTRIES,
	FETCH_MAINTENANCE_COUNTRY,

	FETCH_RENEWALS,
	FETCH_FESTIVALS
} from "lib/graphql"

import slugify from "slugify"
import { getToken } from "lib/graphql/apollo/token"

import { start } from "lib/tools/timer"

export default {
	boot: async ({ client, pathname, cookies }) => {

		start("bootstrap")
		const token = getToken()
		const data = await baseQueries(client, token, pathname)
		const dependentData = await dependentQueries(client, data, cookies)
		const others = await Promise.all([
			subscriptions(client, dependentData.initialCountries.countries),
			festivals(client, dependentData.initialCountries.country)
		])

		return {
			user: data.user,
			languages: data.langs.data.platform.languages.items,
			subscriptions: others[0],
			festivals: others[1].data.cms.categories.items,
			...dependentData.initialCountries
		}
	}
}

const baseQueries = async (client, token, pathname) => {
	return Promise.all([
		access(client),
		languages(client),
		page(client, pathname),
	])
		.then(results => {
			return {
				platformAccess: results[0],
				langs: results[1]
			}
		})
}

const dependentQueries = async (client, data, cookies) => {
	return Promise.all([
		countries(client, data.platformAccess.data.platform, cookies),
		data.user ? renewal(client, parseInt(data.user.profile.id)) : (() => { })(),
	])
		.then(results => {
			return {
				initialCountries: results[0]
			}
		})
}

const renewal = async (client, user) => {
	return client.query({ query: FETCH_RENEWALS, variables: { user }, context: { clientName: "admin" } })
		.catch(e => {
			console.log("ERROR ON FETCH RENEWALS")
			console.log(e)
			return null
		})
}

const access = (client) => {
	return client.query({ query: FETCH_PLATFORM_ACCESS })
		.catch(e => {
			console.log("ERROR ON FETCH PLATFORM ACCESS")
			console.log(e)
			return {}
		})
}

const languages = async (client) => {
	return client.query({ query: FETCH_LANGUAGES })
		.catch(e => {
			return {}
		})
}

const page = async (client, pathname) => {
	let splitted = pathname.split('/')
	splitted.shift()
	splitted.shift()
	let realPath = `/${splitted.join('/')}`
	const slug = realPath == "/" ? "home" : slugify(realPath)
	return client.query({ query: FETCH_PAGE, variables: { query: `linkRewrite.value:"${slug}"` } })
		.catch(e => { return null })
}

const subscriptions = async (client, countries) => {
	let ids = []
	countries.forEach(country => ids = [...ids, ...country.subscriptions.map(s => s.id)])
	const pages = ids.length % 50 > 0 ? Math.floor(ids.length / 50) + 1 : Math.floor(ids.length / 50)

	try {
		const suscriptionsPromises = []

		for (let i = 1; i <= pages; i++) {
			if (i < pages) {
				suscriptionsPromises.push(client.query({
					query: FETCH_SUBSCRIPTIONS,
					variables: { ids: ids.slice(50 * (i - 1), 50 * i) }
				}))
				continue
			}
			suscriptionsPromises.push(client.query({
				query: FETCH_SUBSCRIPTIONS,
				variables: { ids: ids.slice(50 * (i - 1)) }
			}))
		}
		const subscriptions = await Promise.all(suscriptionsPromises)

		return subscriptions.flatMap(sub => sub.data.cms.products.items)
	} catch (e) {
		console.log(e)
	}
}

const festivals = async (client, country) => {
	return client.query({ query: FETCH_FESTIVALS, variables: { query: `parent: ${process.env.CATEGORIES.find(({ key }) => key == "festivals").id}` } })
}

const countries = async (client, data, cookies) => {
	try {
		const access = cookies["access"]
		const originalAccess = cookies["original-access"]
		let { data: { countries } } = await client.query({ query: FETCH_ADMIN_COUNTRIES, variables: { prod: process.env.LACINETEK_ENV == "production" }, context: { clientName: "admin" } })
		let country = countries.find(c => c.isoCode == access.country.toLowerCase())
		let userCountry = countries.find(c => c.isoCode == data.i18n.country.isoCode.toLowerCase())
		let availableCountry = !!country

		if (!country) {
			country = countries.find(c => c.isoCode == "fr")
		}

		const { data: { countryMaintenance: { status: inMaintenance } } } = await client.query({ query: FETCH_MAINTENANCE_COUNTRY, variables: { country: country.isoCode }, context: { clientName: "admin" } })

		if (!userCountry) {
			let isoCode = data.i18n.country.isoCode.toLowerCase()
			if (["re", "ad", "gp", "gf", "mq", "yt", "mc", "nc", "pf", "bl", "mf", "pm", "wf"].includes(isoCode.toLowerCase())) {
				isoCode = "fr"
			}
			userCountry = {
				...(countries.find(c => c.isoCode == "fr")),
				isoCode,
				name: "Unknown"
			}
		}

		return {
			currentIsoCode: data.access.country,
			countries,
			country,
			userCountry,
			availableCountry,
			access,
			originalAccess,
			inMaintenance
		}
	} catch (e) {
		console.log("ERROR WHILE GETTING COUNTRY")
		console.log(e)
		console.log(cookies)
		console.log(cookies["access"])
		console.log(cookies["original-access"])
		return null
	}
}
