import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import PropTypes from "prop-types"

import isBrowser from "lib/tools/is-browser"

const TooltipComponent = ({
	id,
	style = {},
	className,
	align = "center",
	positionRef,
	children
}) => {
	const [isActive, setisActive] = useState(false)

	// Container element is already rendered, so we can reference it now
	const containerRef = useRef(document.getElementById("tooltips"))
	// Toggle element, however, is not rendered yet because it's a sibling of Tooltip
	const toggleRef = useRef(null)

	useEffect(
		() => {
			if (!id) {
				console.error("Tooltip needs an id")

				return
			}

			toggleRef.current = document.querySelector(`.tooltip-toggle[aria-describedby="${id}"]`)

			if (!toggleRef.current) {
				console.error([
					`Tooltip toogle element needs "tooltip-toggle" class`,
					`& aria-describedby="${id}" attribute`
				].join(" "))

				return
			}

			let timerEnter

			const handleMouseEnter = () => {
				timerEnter = setTimeout(() => {
					setisActive(true)
				}, 100)
			}

			const handleMouseLeave = () => {
				setisActive(false)
				clearTimeout(timerEnter)
			}

			toggleRef.current.addEventListener("mouseenter", handleMouseEnter)
			toggleRef.current.addEventListener("mouseleave", handleMouseLeave)

			return () => {
				toggleRef.current.removeEventListener("mouseenter", handleMouseEnter)
				toggleRef.current.removeEventListener("mouseleave", handleMouseLeave)

				clearTimeout(timerEnter)
			}
		},
		[id]
	)

	if (!containerRef.current || !toggleRef.current) {
		return null
	}


	const getPosition = () => {
		const positionElement = (positionRef || toggleRef).current
		const bounding = positionElement.getBoundingClientRect()

		let left = 0

		switch (align) {
			case "center":
				left = bounding.x + bounding.width / 2
				break
			case "end":
				left = bounding.x + bounding.width
				break
			default:
				left = bounding.x
				break
		}

		return {
			left,
			top: bounding.y
		}
	}

	return createPortal(
		<div
			// This id will be duplicated
			// Thanks to Hybrid
			id="tooltipContainer"
			className={className}
			style={{
				...style,
				...getPosition()
			}}
		>
			<div
				id={id}
				className={classNames("tooltip", { "active": isActive })}
				role="tooltip"
			>
				{children}
			</div>
		</div>,
		containerRef.current
  )
}

export const Tooltip = props => {
	if (!isBrowser) {
		return null
	}

	return <TooltipComponent {...props} />
}

Tooltip.propTypes = {
	id: PropTypes.string.isRequired,
	style: PropTypes.object,
	className: PropTypes.string,
	align: PropTypes.oneOf(["start", "center", "end"]),
	positionRef: PropTypes.any,
	children: PropTypes.node
}
