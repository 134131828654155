const hrtime = require("browser-process-hrtime")

let timers = []
export const start = (key) => {
	//console.log(`Launching ${key} timer`)
	timers[key] = hrtime()
}
export const stop = (key) => {
	let hrend = hrtime(timers[key])
	//console.info(`Execution time for ${key}: %ds %dms`, hrend[0], hrend[1] / 1000000)
}
