import { useState } from "react"

import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"

export default ({ startMutation, name }) => {
	const { c } = useTranslation("common")
	const { hide } = useModal()
	const [loading, setLoading] = useState(false)
	const [rename, setRename] = useState(name)
	const [messageChars, setMessageChars] = useState(name ? name.length : 0)

	const handleInputchange = (event) => {
		setRename(event.target.value)
		setMessageChars(event.target.value.length)
	}

	const handleConfirmClick = (event) => {
		event.preventDefault()
		setLoading(true)
		startMutation(rename)
	}

	return (
		<>
			<h2 className="overlay-title icon-playlist">{c("my-playlists-strong")}</h2>
			<p> {c("playlist-rename")} </p>
			<span className="input-container icon-pen">
				<input type="text" placeholder={c("playlist-name")} onChange={handleInputchange} value={rename} maxLength={100} />
			</span>
			{
				rename.length === 0
				&& <p className="form-error">{c("playlist-1-char")}</p>
			}
			<p className="form-error">
				<span>{messageChars}</span>
				<span>/100</span>
			</p>
			<nav className="buttons centered" aria-label="navigation suppression playlist">
				<a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" title="Non" className="icon-blank close">{c("no")}</a>
				<a href="#" onClick={handleConfirmClick} title="Oui" className="icon-check with-icon-after" data-overlay="#overlay-message-playlist">{c("yes")}</a>
			</nav>
		</>
	)
}
