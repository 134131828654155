import SkeletonLink from "components/common/skeleton-link"
import { useTranslation } from "lib/i18n/translation"

const CLink = ({target, as, title, aTitle, onClick, className, indice, ariaLabel, rel}) => {
	const { c } = useTranslation("common")
	let props = {}
	if (indice && indice > 0) {
		props["data-cart-count"] = indice
	}

	if (onClick) {
		return <a href={as || target} className={`${className} link`} title={aTitle} {...props} onClick={e => { e.preventDefault(); onClick() }} aria-label={ariaLabel} rel={rel}>{c(title)}</a>
	}

	return (
		<SkeletonLink href={target} as={as} passHref={true}>
			<a className={`${className} link`} title={aTitle} {...props} aria-label={ariaLabel} rel={rel}>{c(title, { target })}</a>
		</SkeletonLink>
	)
}

export default CLink
