import { useTranslation } from "lib/i18n/translation"
import { useCart } from "lib/cart"
import { useState } from "react"

export default ({beforeApply}) => {

	const { c } = useTranslation("common")
	const { cart, addVoucherToCart } = useCart()
	const [voucher, setVoucher] = useState("")
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [success, setSuccess] = useState(false)

	const applyVoucher = (before=true) => {
		if (!loading || !before) {
			setLoading(true)
			if (before && beforeApply) {
				beforeApply()
					.then(({success}) => {
						if (success) {
							applyVoucher(false)
						} else {
							setLoading(false)
						}
					})
			} else {
				addVoucherToCart(voucher)
					.then(result => {
						setError(!result)
						setSuccess(result)
						setVoucher("")
						setLoading(false)
					})
			}
		}
	}

	return (
		<div className="tr promo">
			<div className="td vertical">
				<p className="introduction">{c("promo-code-question")}</p>
				<span className="input-promo input-container icon-favorite">
					<span className="form-label hidden">{c("promo-code")}</span>
					<input type="text" placeholder={c("promo-code")} aria-label={c("promo-code")} onChange={(e) => { setVoucher(e.target.value)}} value={voucher} />
					<button onClick={applyVoucher} className={loading ? "is-loading" : ""}>{loading ? "" : c("apply")}</button>
				</span>
				{ error
					? <div className="description">{c("error-or-already-consumed-voucher")}</div>
					: null
				}
				{ cart && cart.vouchers
					? cart.vouchers.map(voucher => (
							<div>
								{voucher.name} ({voucher.code}) : {voucher.reductionPercent ? `${voucher.reductionPercent}%` : `${voucher.reductionAmount}€`}
							</div>
						))
					: null
				}
			</div>
		</div>
	)
}
