export const cart = `
	id
	totalPrice
	totalTrial
	availableCheckoutProcesses
	vouchers {
		name
		code
		everyRecurringPayments
		reductionPercent
		reductionAmount
	}
	content(perPage: 50) {
		items {
			accessMode {
				id
				price
				prepaymentBonuses {
					items {
						id
						name
						balanceType
						amount
					}
				}
				... on TVODAccessMode {
					type
					mode
					quality
				}
			}
			product {
				id
				name
				cover
				type
				metadata {
					name
					value
				}
				extension {
					... on ProductTVOD {
						directors {
							items {
								director {
									id
									name
								}
								roles
							}
						}
						accessModes {
							id
							price
							priceFormat
							... on TVODAccessMode {
								type
								mode
								quality
							}
						}
					}
				}
			}
			gift {
				id
				email
				firstname
				lastname
				message
				dateSend
			}
		}
	}
`
