import { useState } from "react"
import * as Yup from "yup"
import moment from "moment"
import { Form, Input } from "@rocketseat/unform"
import classNames from "classnames"

import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"

import GiftCountries from "components/forms/gift-countries"

const schema = (c, option) => {
	let shape = {
		from: Yup.string()
			.matches(/^[^0-9!<>,;?=+()@#"°{}$%]+$/, c("from_invalid"))
			.required(c("from_required")),
		to: Yup.string()
			.matches(/^[^0-9!<>,;?=+()@#"°{}$%]+$/, c("to_invalid"))
			.required(c("to_required")),
		message: Yup.string()
			.required(c("message_required"))
	}
	if (option === "send-mail") {
			shape["email"] = Yup.string().required(c("email_required")).email(c("email_invalid"))
			shape["send"] = Yup.string().matches(/[\d{1,2}\/\d{1,2}\/\d{4}|\d{4}\-\d{1,2}\-\d{1,2}]/, c("invalid_date")).required(c("send_date_required"))
	}

	return Yup.object().shape(shape)
}
const FormReceptionMode = ({ options, currentOption, setCurrentOption }) => {
	const { c } = useTranslation("common")

	return (
		<li>
			<h3>{c("select-reception-mode")}</h3>
			<ul className="radios" role="radiogroup">
				{ options.map((option, index) => (
					<li key={`option-${index}`}>
						<span className="radio-container">
							<input
								type="radio"
								name="gift-option"
								aria-label={c(`gift-option-${option}`)}
								checked={option === currentOption}
								onChange={(e) => setCurrentOption(option)}
							/>
							<span className="form-label">
								{c(`gift-option-${option}`)}
							</span>
						</span>
						<FormReceptionInfos option={option} isActive={currentOption === option} />
					</li>
				))}
			</ul>
		</li>
	)
}

const FormReceptionInfos = ({ option, isActive }) => {
	const { c } = useTranslation("common")

	if (option === "send-mail") {
			return (
				<div className={`collapsible ${isActive ? "active" : ""}`}>
					<span className="input-container icon-mail">
						<span className="form-label hidden">{c("email")}</span>
						<Input
							type="email"
							name="email"
							placeholder={c("email")}
						/>
					</span>
					<span className="input-container icon-calendar">
						<span className="form-label hidden">{c("date-full")}</span>
						<Input
							type="date"
							name="send"
							placeholder={c("date-full")}
						/>
					</span>
				</div>
			)
	}

	return null
}

export const GiftOptionsForm = ({ gift, product, active, onSubmit, error, adding, onCountryChanged, update }) => {
	const { hide } = useModal()
	const { c } = useTranslation("common")
	const [currentOption, setCurrentOption] = useState(gift ? (gift.dateSend ? "send-mail" : "print") : null)
	const [fromChars, setFromChars] = useState(0)
	const [toChars, setToChars] = useState(0)
	const [messageChars, setMessageChars] = useState(0)
	const options = ["print", "send-mail"]

	const handleSubmit = async (values, { resetForm }) => {
		let gift = {
			firstname: values.from,
			lastname: values.to,
			message: values.message
		}

		if (currentOption === "send-mail") {
				gift.email = values.email
				if (values.send.includes("/")) {
					gift.dateSend = moment(values.send, "DD/MM/YYYY").format("YYYY-MM-DD")
				} else {
					gift.dateSend = moment(values.send, "YYYY-MM-DD").format("YYYY-MM-DD")
				}
		}
		onSubmit(gift, currentOption)
	}

	const initialData = gift ? {
		...gift,
		from: gift.firstname,
		to: gift.lastname,
		send: moment(gift.dateSend).format("YYYY-MM-DD")
	} : {}

	const countChars = (e) => {
		switch (e.target.name) {
			case "from":
				setFromChars(e.target.value.length)
				break
			case "to":
				setToChars(e.target.value.length)
				break
			case "message":
				setMessageChars(e.target.value.length)
				break
		}
	}

	const FormNav = () => (
		<>
			{error
				? <div className="description">{error}</div>
				: null
			}
			<nav className="buttons">
				<a
					href="#"
					onClick={e => { e.preventDefault(); hide() }}
					title={c("cancel")}
					rel="nofollow noopener"
					className="icon-blank close"
				>
					{c(`cancel${gift ? "-update" : ""}`)}
				</a>
				<button
					type="submit"
					aria-label={c(gift ? "save-changes" : "add-to-cart")}
					className={classNames(
						"icon-cart close",
						{ "is-loading": adding }
					)}
				>
					{c(gift ? "save-changes" : "add-to-cart")}
				</button>
			</nav>
		</>
	)

	return (
		<>
			{update ?
				<Form
					schema={active ? schema(c, currentOption) : null}
					onSubmit={handleSubmit}
					initialData={initialData}
					aria-label={c("gift-information")}
				>
					<fieldset>
						<legend className="hidden">{c("update-gift-infos")}</legend>
						{!gift && (
							<GiftCountries product={product} onCountryChanged={onCountryChanged} />
						)}
						<ul className="columns has-gutter">
							<FormReceptionMode options={options} currentOption={currentOption} setCurrentOption={setCurrentOption} />
							<li>
								<ul><li>
									<h3>{c("gift-information")}</h3>
									<span className="form-label hidden">
										{c("from-gift")}
									</span>
									<span className="input-container icon-user">
										<Input
											name="from"
											type="text"
											placeholder={c("from-gift")}
											maxLength="32"
											onChange={countChars}
										/>
									</span>
									<p className="form-error gift">
										<span className="form-counter">
											{fromChars}
										</span>
										<span className="form-total">/32</span>
									</p>
								</li></ul>
								<span className="form-label hidden">
									{c("for")}
								</span>
								<span className="input-container icon-user">
									<Input
										name="to"
										type="text"
										placeholder={c("for")}
										maxLength="32"
										onChange={countChars}
									/>
								</span>
								<p className="form-error gift">
									<span className="form-counter">
										{toChars}
									</span>
									<span className="form-total">/32</span>
								</p>
								<span className="form-label hidden">
									{c("your-message")}
								</span>
								<Input
									type="text"
									multiline
									name="message"
									placeholder={c("your-message-with-length")}
									maxLength="200"
									onChange={countChars}
								/>
								<p className="form-error gift">
									<span className="form-counter">
										{messageChars}
									</span>
									<span className="form-total">/200</span>
								</p>
							</li>
						</ul>
					</fieldset>
					<FormNav />
				</Form>
				: <Form
					schema={active ? schema(c, currentOption) : null}
					onSubmit={handleSubmit}
					initialData={initialData}
					aria-label={c("gift-information")}
				>
					<div
						id="add-cinepass-gift-options"
						className={classNames(
							"collapsible",
							{ "active": active }
						)}
					>
						{!gift && (
							<GiftCountries product={product} onCountryChanged={onCountryChanged} />
						)}
						<ul className="columns has-gutter">
							<FormReceptionMode options={options} currentOption={currentOption} setCurrentOption={setCurrentOption} />
							<li>
								<h3>{c("gift-information")}</h3>
								<span className="form-label hidden">
									{c("from-gift")}
								</span>
								<span className="input-container icon-user">
									<Input
										name="from"
										type="text"
										placeholder={c("from-gift")}
										maxLength="32"
										onChange={countChars}
									/>
								</span>
								<p className="form-error gift">
									<span className="form-counter">
										{fromChars}
									</span>
									<span className="form-total">/32</span>
								</p>
								<span className="form-label hidden">
									{c("for")}
								</span>
								<span className="input-container icon-user">
									<Input
										name="to"
										type="text"
										placeholder={c("for")}
										maxLength="32"
										onChange={countChars}
									/>
								</span>
								<p className="form-error gift">
									<span className="form-counter">
										{toChars}
									</span>
									<span className="form-total">/32</span>
								</p>
								<span className="form-label hidden">
									{c("your-message")}
								</span>
								<Input
									type="text"
									multiline
									name="message"
									placeholder={c("your-message-with-length")}
									maxLength="200"
									onChange={countChars}
								/>
								<p className="form-error gift">
									<span className="form-counter">
										{messageChars}
									</span>
									<span className="form-total">/200</span>
								</p>
							</li>
						</ul>
					</div>
					<FormNav />
				</Form>
			}
		</>
	)
}

export default GiftOptionsForm
