import { useEffect, useState } from "react"
import classNames from "classnames"
import moment from "moment"

import { useCart } from "lib/cart"
import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"

import { GiftOptionsForm } from "components/forms/gift-options"
import CinepassBig from "static/assets/icons/cinepass_big.svg"

const FOR_ME = "me"
const FOR_OFFER = "offer"

const OfferCinePassForm = ({ offer, product }) => {
	const { c } = useTranslation("common")
	const { purchasable } = useCountry()
	const {
		addProductToCart,
		adding,
		prepayments,
	} = useCart()

	const [current, setCurrent] = useState(null)
	const [currentTarget, setCurrentTarget] = useState((offer || !purchasable) ? FOR_OFFER : FOR_ME)
	const [error, setError] = useState(false)

	const handleSubmitted = (gift, option) => {
		setError(false)
		if (currentTarget == FOR_OFFER) {
			if (!current) {
				setError(c("cinepass-required"))
			} else if (!option) {
				setError(c("gift-option-required"))
			} else if (gift.dateSend && moment(gift.dateSend) < moment().startOf("day")) {
				setError(c("gift-date-send-to-low"))
			} else {
				addProductToCart(current, { gift })
					.catch(() => {
						setError(c("error-while-adding-gift-to-cart"))
					})
			}
		} else {
			if (!current) {
				setError(c("cinepass-required"))
			} else {
				addProductToCart(current)
			}
		}
	}

	return (
		<>
			<h2 className="overlay-title icon-cinepass">{c("cinepass-big-infos")}</h2>
			<form aria-label={c("buy-cinepass")}>
				<fieldset>
					<legend className="hidden">{c("choose-cinepass-for-me-or-to-offer")}</legend>
					<nav class="switch nav-tabs">
						<a
							href="#"
							title={c("for-me")}
							class={classNames({ "active": currentTarget === FOR_ME })}
							onClick={() => { setCurrentTarget(FOR_ME) }}
						>
							{c("for-me")}
						</a>
						<a
							href="#"
							title={c("to-offer")}
							class={classNames({ "active": currentTarget === FOR_OFFER })}
							onClick={() => { setCurrentTarget(FOR_OFFER) }}
						>
							{c("to-offer")}
						</a>
					</nav>
					<p dangerouslySetInnerHTML={{ __html: c("Cinépass-offer-description") }} />
					<p dangerouslySetInnerHTML={{ __html: c("Cinépass-offer-gift") }} />
					<div className="tab active">
						<nav className="offers blocks">
							{prepayments.length === 0 && (
								<div className="loader"></div>
							)}
							{prepayments.map((cinepass, index) => (
								<div
									key={`cinepass-item-${index}`}
									className="item"
								>
									<a
										href="#"
										onClick={e => { e.preventDefault(); setCurrent(cinepass) }}
										rel="nofollow noopener"
										title={c("choose-this-cinepass")}
										className={classNames("item-content", { "active": current && current.id == cinepass.id })}>
										<figure><CinepassBig title={c("pm_cinepass")} /></figure>
										<div className="infos">
											<h3 className="title">
												{c("cinepass-big-infos")} <span className="price">{cinepass.extension.amount}€</span>
											</h3>
										</div>
									</a>
								</div>
							))}
						</nav>
					</div>
					<GiftOptionsForm
						product={product}
						active={currentTarget == FOR_OFFER}
						error={error}
						adding={adding}
						onSubmit={handleSubmitted}
					/>
				</fieldset>
			</form>
		</>
	)
}

export default OfferCinePassForm
