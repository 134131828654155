import moment from "moment"

export const isSubscriptionTrialOver = (access) => {
		// calcule pour savoir si on est dans la période d'essai
		// si la différence de jours entre la date d'ajout de l'abonnement + la periode d'essaie et la date actuelle est supérieur à 0
		// alors la période d'essai est toujours en cours
		if (
			access.product.extension.trialPeriod > 0
			&& moment(access.dateAdd).add(access.product.extension.trialPeriod, 'days').diff(moment(), 'days', true) > 0
		) {
			return false
		}

		return true
}
