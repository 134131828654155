import { useEffect, useState } from "react"
import { useApolloClient } from "@apollo/react-hooks"
import slugify from "slugify"

import { useTranslation } from "lib/i18n/translation"
import { useAuth } from "lib/auth"
import { FETCH_STRIPE_CUSTOMER, FETCH_CARDS } from "lib/graphql/queries/admin-queries"
import { REMOVE_CARD } from "lib/graphql/mutations/admin-mutations"
import { useCountry } from "lib/country"
import { useAnchorNav } from "lib/anchor-nav"

import SkeletonLink from "components/common/skeleton-link"

import CB1 from "static/assets/icons/cb2.svg"

const MyCard = ({title}) => {
	const { c } = useTranslation("common")
	const { user } = useAuth()
	const client = useApolloClient()
	const { userCountry } = useCountry()
	const [stripeCustomer, setStripeCustomer] = useState(null)
	const [cards, setCards] = useState(null)
	const [currentCard, setCurrentCard] = useState(null)
	const [loadingCards, setLoadingCards] = useState(true)
	const [editCurrent, setEditCurrent] = useState(false)
	const [error, setError] = useState(false)

	const [removing, setRemoving] = useState(false)

	useEffect(() => {
		client.query({
			query: FETCH_STRIPE_CUSTOMER,
			variables: { email: user.profile.email },
			context: { clientName: "admin", headers: { country: userCountry.isoCode } }
		})
			.then(({data: { stripeCustomer: { id } }}) => {
				setStripeCustomer(id)
				client.query({
					query: FETCH_CARDS,
					variables: { email: user.profile.email },
					context: { clientName: "admin", headers: { country: userCountry.isoCode } }
				})
					.then(({data: { cards }}) => {
						setCards(cards)
						setLoadingCards(false)
					})
		})
	}, [])

	const chooseCard = (card) => {
		setCurrentCard(card)
		setError(false)
	}

	const deleteCard = () => {
		if (user.renewal && user.renewal.card_id == currentCard.id) {
			return
		}

		setError(false)
		setRemoving(true)
		client.mutate({
			mutation: REMOVE_CARD,
			variables: { card: currentCard.id, customer: stripeCustomer },
			context: { clientName: "admin", headers: { country: userCountry.isoCode } }
		})
			.then(deleted => {
				setRemoving(false)
				if (!deleted) {
					setError(c("unable-to-delete-card"))
				} else {
					setCards(cards => cards.filter(c => c.id !== currentCard.id))
					setCurrentCard(null)
				}
			})
	}

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	useEffect(
		() => {
			if (! loadingCards) {
				setSectionLoaded( title )
			}
		},
		[isSectionsSetted, loadingCards]
	)

	return (
		<section id={ slugify( c( title ) ).toLowerCase() } className="section-anchor">
			<h2 className="section-title icon-card">{c("my-credit-card-strong")}</h2>
			<div className="splitted large invert">
				<figure className="object"><CB1 title={c("pm_cb")} /></figure>
				<div className="infos">
					<h3><b>{c("credit-cart-infos")}</b></h3>
					{ loadingCards
						? <div className="is-loading" />
						: <form aria-label={c("update-payment-mean")}>
							<fieldset>
								<legend className="hidden">{c("select-and-update-payment-mean")}</legend>
								<ul className="inline full">
									<li>
										<span className="select-container">
											<select
												defaultValue={'DEFAULT'}
												onChange={(e) => { chooseCard(cards[e.target.value]) }}
												aria-label={c("select-credit-card")}
											>
												<option value="DEFAULT" disabled hidden>{c("choose-a-card")}</option>
												{ cards.map((card, index) => (
														<option value={index}>{card.name} | **** **** **** {card.last4} | {card.exp_month}/{card.exp_year}</option>
												))}
											</select>
										</span>
									</li>
									<li>
										<nav className="buttons">
											<a
												href="#"
												onClick={e => { e.preventDefault(); deleteCard() }}
												rel="nofollow noopener"
												title={c("delete-this-card")}
												className={`icon-trash ${currentCard ? "" : "disabled"} ${removing ? "is-loading" : ""} ${(currentCard && user.renewal && currentCard.id == user.renewal.card_id) ? "disabled" : ""}`}
											>
												{c("delete-this-card")}
											</a>
										</nav>
									</li>
								</ul>
								{ currentCard && user.renewal && user.renewal.card_id == currentCard.id
									? <p className="excerpt">{c("renewal-card-not-removable")} <SkeletonLink href="/profile/parameters">{c("my-params")}</SkeletonLink></p>
									: null
								}
								<div id="card-options" className={`collapsible ${editCurrent ? "active" : ""}`}>
									<span className="form-label">{c("card-name")}</span>
									<input type="text" name="cardname" placeholder={c("card-name")} aria-label={c("card-name")} />
									<ul className="inline full">
										<li>
											<span className="form-label">{c("card-number")}</span>
											<input type="text" name="cardnumber" placeholder={c("card-number")} aria-label={c("card-number")} />
										</li>
										<li>
											<span className="form-label">{c("expiration-date")}</span>
											<input type="text" name="cardexpiration" placeholder={c("expiration-date")} aria-label={c("expiration-date")} />
										</li>
										<li>
											<span className="form-label">{c("card-cvv")}</span>
											<input type="text" name="cardcvv" placeholder={c("card-cvv")} aria-label={c("card-cvv")} />
										</li>
									</ul>
									<span className="form-label">{c("default-card")}</span>
									<ul className="radios inline">
										<li>
											<input type="radio" name="card-default" value={c("yes")} aria-label={c("yes")} />
											<span className="form-label">{c("yes")}</span>
										</li>
										<li>
											<input type="radio" name="card-default-not" value={c("no")} aria-label={c("no")} />
											<span className="form-label">{c("no")}</span>
										</li>
									</ul>
									<nav className="buttons small">
										<a
											href="#"
											onClick={e => { e.preventDefault(); }}
											rel="nofollow noopener"
											title={c("cancel")}
											className="icon-blank close"
										>
											{c("cancel")}
										</a>
										<button
											type="submit"
											aria-label={c("card-validate")}
											title={c("card-validate")}
											className="icon-check close"
										>
											{c("card-validated")}
										</button>
									</nav>
								</div>
								</fieldset>
							</form>
					}
				</div>
			</div>
		</section>
	)
}

export default MyCard
