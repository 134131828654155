import { useState } from "react"
import { Form, Input } from "@rocketseat/unform"
import * as Yup from "yup"
import moment from "moment"

import { useAuth } from "lib/auth"
import { useTranslation } from "lib/i18n/translation"

const schema = (c) => {
	return Yup.object().shape({
		firstname: Yup.string()
			.min(2, c("firstname_2_chars"))
			.matches(/^[^0-9]+$/, c("firstname_not_number"))
			.required(c("firstname_required")),
		lastname: Yup.string()
			.min(2, c("lastname_2_chars"))
			.matches(/^[^0-9]+$/, c("lastname_not_number"))
			.required(c("lastname_required")),
		email: Yup.string()
			.email(c("email_invalid"))
			.required(c("email_required")),
		birthdate: Yup.string()
			.min(4, c("year_of_birth_4_digits"))
			.max(4, c("year_of_birth_4_digits"))
			.matches(/^[0-9]+$/, c("year_of_birth_4_digits"))
			.required(c("year_of_birth_required")),
		password: Yup.lazy(value => !value ? Yup.string() : Yup.string().min(5, c("password_5_chars"))),
		passwordConfirmation: Yup.string()
			.oneOf([Yup.ref("password"), null], c("passwords_must_match")),
	})
}

const ProfileForm = ({}) => {
	const auth = useAuth()
	const { c } = useTranslation("common")
	const [error, setError] = useState(false)
	const [saved, setSaved] = useState(false)

	const handleSubmit = (values, { resetForm }) => {
		setError(false)
		setSaved(false)
		auth.updateProfile(values)
			.then(result => {
				if (typeof result == "boolean") {
					setSaved(result)
					setError(!result)
				}
			})
			.catch(error => {
				console.log(error)
				setSaved(false)
				setError(true)
			})
	}

	let initialData = { ...auth.user.profile }
	initialData["birthdate"] = moment(auth.user.profile.birthdate).format("YYYY")

	return (
		<Form schema={schema(c)} onSubmit={handleSubmit} initialData={initialData} aria-label={c("update-my-coords")}>
			<fieldset>
				<legend className="hidden">{c("update-my-coords")}</legend>
				<ul className="columns">
					<li>
						<span className="form-label hidden">{c("first-name")}</span>
						<span className="input-container icon-user">
							<Input name="firstname" type="text" placeholder={c("ph_firstname")} />
						</span>
						<span className="form-label hidden">{c("name")}</span>
						<span className="input-container icon-user">
							<Input name="lastname" type="text" placeholder={c("ph_lastname")} />
						</span>
						<span className="form-label hidden">{c("year_of_birth")}</span>
						<span className="input-container icon-calendar">
							<Input name="birthdate" type="text" placeholder={c("ph_year_of_birth")} />
						</span>
					</li>
					<li>
						<span className="form-label hidden">{c("email")}</span>
						<span className="input-container icon-mail">
							<Input name="email" type="text" placeholder={c("ph_email")} />
						</span>
						<span className="form-label hidden">{c("password")}</span>
						<span className="input-container icon-lock-on">
							<Input name="password" type="password" placeholder={c("ph_password")} autoComplete="false" />
						</span>
						<span className="form-label hidden">{c("password-confirm")}</span>
						<span className="input-container icon-lock-on">
							<Input name="passwordConfirmation" type="password" placeholder={c("ph_password_confirm")} />
						</span>
					</li>
				</ul>
				<button type="submit" title={c("save-my-coords")} aria-label={c("save-my-coords")} className={`button icon-refresh full ${auth.updating ? "is-loading" : ""}`}>{c("save")}</button>
				{ error
					? <span className="red">{c("unable_to_save")}</span>
					: null
				}
				{ saved
					? <span>{c("data-saved")}</span>
					: null
				}
			</fieldset>
		</Form>
	)
}

export default ProfileForm
