import { useEffect, useState } from "react"
import { useTranslation } from "lib/i18n/translation"
import { Elements, StripeProvider, CardElement, injectStripe } from "react-stripe-elements"
import { useModal } from "lib/modal"
import { useApolloClient } from "@apollo/react-hooks"
import { UPDATE_PAYMENT_METHOD } from "lib/graphql"
import { useCountry } from "lib/country"

const loadScript = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', () => {
      resolve({ successful: true })
    })
    script.addEventListener('error', event => {
      reject({ error: event })
    })
    document.head.appendChild(script)
  }) 


export default ({access}) => {

	const { c } = useTranslation("common")
	const { hide } = useModal()
	const [succeed, setSucceed] = useState(false)
	const [stripeLoaded, setStripeLoaded] = useState(null)
	const { userCountry } = useCountry()

	useEffect(() => {
		const fetchData = async () => {
			const result = await loadScript('https://js.stripe.com/v3')
			setStripeLoaded(result)
		}
		fetchData()
	}, [])

	const onSucceed = () => {
		setSucceed(true)
	}

	if (succeed) {
		return (
			<>
				<p className="excerpt">{c("payment-method-update-succeed")}</p>
				<p className="centered">
					<a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" title={c("close")} className="button" style={{marginRight: "1rem"}}>{c("close")}</a>
				</p>	
			</>
		)
	}

	if (!stripeLoaded) {
		return (
			<p className="centered">
				<div className="is-loading" />
			</p>
		)
	}

	if (!stripeLoaded.successful) {
		return <p className="excerpt" dangerouslySetInnerHTML={{ __html: c("stripe-is-unavailable") }} />
	}

	return (
		<>
			<p className="excerpt">{c("update-payment-method-desc")}</p>
			<div className="checkout">
				<StripeProvider apiKey={userCountry.stripe_api_key}>
					<Elements
						fonts={[{
							cssSrc: "https://fonts.googleapis.com/css2?family=Roboto&display=swap"
							//cssSrc: "http://lacinetek.no.codekraft.fr/static/assets/fonts.css"
						}]}
					>
						<UpdateForm c={c} onSucceed={onSucceed} />
					</Elements>
				</StripeProvider>
			</div>
		</>
	)
}

const UpdateForm = injectStripe(({stripe, c, onSucceed}) => {

	const { hide } = useModal()
	const [updating, setUpdating] = useState(false)
	const [error, setError] = useState(false)
	const client = useApolloClient()

	const update = async () => {
		setUpdating(true)
		setError(false)
		const token = await stripe.createToken()
		if (token.token) {
			client.mutate({mutation: UPDATE_PAYMENT_METHOD, variables: { gateway: "stripe", arguments: JSON.stringify({token: token.token.id})}})
				.then(result => {
					setUpdating(false)
					setError(!result)
					if (result) {
						onSucceed()
					}
				})
				.catch(() => {
					setError(true)
				})
		} else {
			setUpdating(false)
			setError(token.error.message)
		}
	}

	return (
		<>
			<CardElement
				style={{
					base: {
						fontSize: '14px',
						iconSize: '20px',
						color: '#424770',
						fontFamily: "'roboto'",
						letterSpacing: '0.025em',
						'::placeholder': {
							color: '#aab7c4',
							fontStyle: "normal"
						},
						padding: 10
					}
				}}
				hidePostalCode={true}
			/>
			{ error
				? <><br /><p>{error == true ? c("error-on-method-update") : error}</p></>
				: null
			}
			<p className="centered">
				<a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" title={c("cancel")} className="button" style={{marginRight: "1rem"}}>{c("cancel")}</a>
				<a href="#" onClick={e => { e.preventDefault(); update() }} rel="nofollow noopener" title={c(`update-payment-method`)} className={`button ${updating ? "is-loading" : ""}`}>{c(`update-payment-method`)}</a>
			</p>
		</>
	)
})
