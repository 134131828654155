import { useTranslation } from "lib/i18n/translation"

import SkeletonLink from "components/common/skeleton-link"

export default ({}) => {

	const { c } = useTranslation("common")

	return (
		<>
			<h2 className="overlay-title">{c("unavailable-device-title")}</h2>
			<p>{c("unavailable-device-desc")}</p>
			<p className="centered">
				<SkeletonLink href="/profile/parameters">
					<a className="button">{c("manage-devices")}</a>
				</SkeletonLink>
			</p>
		</>
	)
}
