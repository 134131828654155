import MinusIcon12 from "static/assets/icons/moins12.svg"
import MinusIcon16 from "static/assets/icons/moins16.svg"
import MinusIcon18 from "static/assets/icons/moins18.svg"

import { metadata,
	FEATURE_CLASSIFICATION,
	FEATURE_CLASSIFICATION_ALL,
	FEATURE_CLASSIFICATION_ALL_WITH_ADV,
	FEATURE_CLASSIFICATION_18,
	FEATURE_CLASSIFICATION_16,
	FEATURE_CLASSIFICATION_12,
} from "lacinetek-common/src/metadata"

export default function Classification(film) {
	let value
	let classif

	if (film.classification) {
		classif = film.classification
	} else {
		classif = metadata(film, FEATURE_CLASSIFICATION)
	}

	switch(classif) {
		case FEATURE_CLASSIFICATION_ALL:
		case FEATURE_CLASSIFICATION_ALL_WITH_ADV:
			value = null
			break
		case FEATURE_CLASSIFICATION_18:
			value = <span className="csa-classification"><MinusIcon18 title="-18" /></span>
			break
		case FEATURE_CLASSIFICATION_16:
			value = <span className="csa-classification"><MinusIcon16 title="-16" /></span>
			break
		case FEATURE_CLASSIFICATION_12:
			value = <span className="csa-classification"><MinusIcon12 title="-12" /></span>
			break
	}

	return value
}
