import { useEffect } from "react"
import slugify from "slugify"

import { useBilling } from "lib/billing"
import { useCart } from "lib/cart"
import { useTranslation } from "lib/i18n/translation"
import { useAnchorNav } from "lib/anchor-nav"

import Cinepass from "static/assets/icons/cinepass.svg"

const MyCinepass = ({ title }) => {
	const { c } = useTranslation("common")
	const { addCinepassToCart } = useCart()
	const { fetchUserBalance, currencyBalance } = useBilling()
	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	useEffect(
		() => {
			if (!isSectionsSetted) {
				return
			}

			const initSection = async () => {
				if (!currencyBalance) {
					await fetchUserBalance()
				}

				setSectionLoaded(title)
			}

			initSection()
		},
		[isSectionsSetted]
	)

	return (
		<section id={slugify(c(title)).toLowerCase()} className="section-anchor">
			<h2 className="section-title icon-cinepass">{c("my-cinepass-strong")}</h2>
			<div className="splitted large invert">
				<figure className="object"><Cinepass title={c("pm_cinepass")} /></figure>
				<ul className="columns">
					<li>
						<h3><b>{c("reload-cinepass")}</b></h3>
						<p>{c("how-to-cinepass")}</p>
						<p>
							<a
								href="#"
								onClick={e => { e.preventDefault(); addCinepassToCart() }}
								rel="nofollow noopener"
								title={c("reload-cinepass")}
								data-overlay="#overlay-add-cinepass-to-cart"
								className="button small icon-cinepass"
							>
								{c("reload-cinepass")}
							</a>
						</p>
						{currencyBalance > 0 && <div className="offer-credit alert">
							<p>{c("you-still-have")} {currencyBalance}&nbsp;€ {c("on-your-cinepass")}</p>
						</div>
						}
					</li>
					<li>
						<h3><b>{c("offer-cinepass-to-someone")}</b></h3>
						<p>{c("how-to-offer-cinepass")}</p>
						<p>
							<a
								href="#"
								onClick={e => { e.preventDefault(); addCinepassToCart({ offer: true }) }}
								rel="nofollow noopener"
								title={c("offer-cinepass")}
								data-overlay="#overlay-add-cinepass-to-cart"
								className="button small icon-cinepass"
							>
								{c("offer-cinepass")}
							</a>
						</p>
					</li>
				</ul>
			</div>
		</section>
	)
}

export default MyCinepass
