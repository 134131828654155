import getConfig from "next/config"

import { Query } from "components/common"
import {
	FETCH_BLOG_CATEGORY,
	FETCH_BLOG_CATEGORY_BY_SLUG,
	FETCH_COMPLETE_BLOG_CATEGORY_BY_SLUG
} from "lib/graphql"

const { publicRuntimeConfig } = getConfig()

export default ({categoryId, categorySlug, complete, renderLoading, children}) => {
	const env = publicRuntimeConfig.lacinetekEnv

		return (
		<Query
			query={categoryId ? FETCH_BLOG_CATEGORY(env) : (complete ? FETCH_COMPLETE_BLOG_CATEGORY_BY_SLUG : FETCH_BLOG_CATEGORY_BY_SLUG)}
			options={{variables: {id: categoryId, slug: categorySlug}}}
			renderLoading={renderLoading}
		>
			{({cms: { blogCategories: { items: [category] }}}) => children(category)}
		</Query>
	)
}
