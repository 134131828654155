import { Query } from "components/common"
import { FETCH_PAGE } from "lib/graphql/queries"

export default ({}) => (
	<Query
		query={FETCH_PAGE}
		options={{variables: { query: "id:14" }}}
	>
		{({cms: { pages: { items: [page] } } }) => (
			<div className="description" dangerouslySetInnerHTML={{ __html: page.content }} />
		)}
	</Query>
)
