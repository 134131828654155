import { gql } from "apollo-boost"

export * from "./admin-mutations"
import { cart } from "lib/graphql/common.js"

export const AUTHENTICATE_MUTATION = gql`
  mutation Authenticate($email: String!, $password: String!) {
		signin(credentials: { email: $email, password: $password }) {
			accessToken,
			refreshToken
			tokenType,
			expiresIn,
			user {
				id,
				firstname,
				lastname,
				email,
				birthdate,
				address { country { isoCode } }
				language {isoCode }
			}
		}
  }
`

export const FB_AUTHENTICATE_MUTATION = gql`
  mutation FbAuthenticate($token: String!, $tokenType: String!) {
		signin(facebook: { token: $token, tokenType: $tokenType }) {
			accessToken,
			refreshToken
			tokenType,
			expiresIn,
			user {
				id
				dateAdd
				firstname
				lastname
				email
				birthdate
				address { country { isoCode } }
				language {isoCode }
			}
		}
  }
`

export const ANONYMOUS_AUTHENTICATE_MUTATION = gql`
  mutation Authenticate {
		signin {
			accessToken,
			refreshToken
		}
	}
`

export const SIGNUP_MUTATION = gql`
	mutation CreateCustomer($firstname: String!, $lastname: String!, $email: String!, $password: String!, $birthdate: String!, $newsletter: Boolean!) {
		signup(user: { firstname: $firstname, lastname: $lastname, email: $email, password: $password, birthdate: $birthdate, newsletter: $newsletter }) {
			accessToken
			refreshToken
			user {
				id
				firstname
				lastname
				email
				birthdate
				address { country { isoCode } }
				language {isoCode }
			}
		}
	}
`

export const UPDATE_PROFILE = gql`
	mutation UpdateProfile($firstname: String!, $lastname: String!, $birthdate: String!) {
		updateUserProfile(user: { firstname: $firstname, lastname: $lastname, birthdate: $birthdate }) {
			id
			firstname
			lastname
			email
			birthdate
			address {
			country { isoCode } }
			language {isoCode }
		}
	}
`

export const UPDATE_EMAIL = gql`
	mutation UpdateEmail($email: String!, $password: String!) {
		updateCredentials(email: { value: $email, validator: { password: $password }}) {
			email
		}
	}
`

export const UPDATE_PASSWORD = gql`
	mutation UpdatePassword($oldPassword: String!, $password: String!) {
		updateCredentials(password: { value: $password, validator: { password: $oldPassword }}) {
			password
		}
	}
`

export const REFRESH_TOKEN = gql`
	mutation RefreshToken($refreshToken: String!) {
		signin(refreshToken: $refreshToken) {
			accessToken
			refreshToken
		}
	}
`

export const CREATE_OR_UPDATE_CONTENT = gql`
	mutation CreateOrUpdateContent($route: String!, $key: String!, $content: String!) {
		content(route: $route, key: $key, content: $content) {
			route,
			key,
			content
		}
	}
`

export const ADD_TO_CART = gql`
	mutation AddToCart($product: ID!, $accessMode: ID!) {
		addProductToCart(product: $product, accessMode: $accessMode) {
			${cart}
		}
	}
`

export const ADD_GIFT_TO_CART = gql`
	mutation AddToCart($product: ID!, $accessMode: ID!, $firstname: String!, $lastname: String!, $email: String, $message: String!, $dateSend: Date, $custom: String) {
		addProductToCart(product: $product, accessMode: $accessMode, giftTo: { firstname: $firstname, lastname: $lastname, email: $email, message: $message, dateSend: $dateSend, custom: $custom }) {
			${cart}
		}
	}
`

export const UPDATE_GIFT = gql`
	mutation UpdateGift($id: ID!, $firstname: String!, $lastname: String!, $email: String, $message: String!, $dateSend: Date, $custom: String) {
		updateGift(id: $id, input: { firstname: $firstname, lastname: $lastname, email: $email, message: $message, dateSend: $dateSend, custom: $custom }) {
			${cart}
		}
	}
`

export const REMOVE_FROM_CART = gql`
	mutation RemoveProductFromCart($product: ID!, $accessMode: ID!) {
		removeProductFromCart(product: $product, accessMode: $accessMode) {
			${cart}
		}
	}
`
export const ADD_CART_VOUCHER = gql`
	mutation AddVoucherToCart($code: String!) {
		addCartVoucher(code: $code) {
			${cart}
		}
	}
`

export const CHECKOUT_CART = gql`
	mutation CheckoutCart($intent: CheckoutProcess!, $gateway: String!) {
		checkout(process: $intent, paymentGateway: $gateway) {
			... on PaymentIntent {
				identifier
				metadata
			}
      ... on PaymentUrl {
        type
        url
      }
		}
	}
`

export const CHECKOUT_CART_WITH_PREPAYMENT = gql`
	mutation CheckoutCartWithPrepayment($balanceType: PrepaymentBalanceType!) {
		checkoutWithPrepayment(balanceType: $balanceType) {
			success
		}
	}
`

export const RESET_CART = gql`
	mutation ResetCart {
		resetCart {
			${cart}
		}
	}
`

export const FREE_CHECKOUT_CART = gql`
	mutation FreeCheckoutCart {
		checkout(process: FREE) {
			... on CheckoutSuccessResponse {
				success
			}
		}
	}
`

export const AUTHORIZE_CART = gql`
	mutation AuthorizeCart($intent: CheckoutProcess!, $token: String!) {
		checkout(process: $intent, paymentGateway: "STRIPE", paymentAuthorization: { paymentMethodIdentifier: $token }) {
			... on CheckoutSuccessResponse {
				success
			}
		}
	}
`

export const UPDATE_PAYMENT_METHOD = gql`
	mutation UpdatePaymentMethod($gateway: String!, $arguments: String!) {
		updatePaymentMethod(gateway: $gateway, arguments: $arguments)
	}
`

export const FORGOT_PASSWORD_MUTATION = gql`
	mutation RetrievePassword($email: String!) {
		retrievePassword(email: $email)
	}
`

export const RESET_PASSWORD_MUTATION = gql`
	mutation UpdatePasswordWithToken($password: String!, $token: String!) {
		updateCredentials(password: { value: $password, validator: { token: $token }}) {
			password
		}
	}
`

export const UPDATE_LANGUAGE_MUTATION = gql`
  mutation UpdateCustomerLanguage($langISO: String!) {
    updateUserProfile(user: { langISO: $langISO }) {
			id
		}
	}
`

export const CREATE_DEVICE = gql`
	mutation CreateDevice($os: String!, $application: String!, $type: String!, $fingerprint: String!) {
		addDevice(device: { os: $os, type: $type, application: $application, fingerprint: $fingerprint}) {
			id
			fingerprint
			type
			os
			application
		}
	}
`

export const REMOVE_DEVICE = gql`
	mutation RemoveDevice($id: ID!) {
		removeDevice(id: $id) {
			id
			fingerprint
			type
			os
			application
		}
	}
`

export const CONSUME_GIFT = gql`
	mutation ConsumeGift($id: ID!, $token: String!) {
		consumeGift(gift: $id, token: $token) {
			message
			content {
				product {
					type
					id
					name
				}
			}
		}
	}
`

export const REMOVE_ACCOUNT_MUTATION = gql`
	mutation RemoveAccount($password: String!) {
		deleteUser(password: $password) {
			success
		}
	}
`

export const TOGGLE_ACCESS_MUTATION = gql`
	mutation ToggleAccessMutation($id: ID!) {
		toggleAccessRenewal(access: $id)
	}
`
