import * as Sentry from "@sentry/node"
import { Component, useEffect, createRef, useState } from "react"

import { useTranslation } from "lib/i18n/translation"
import {
	FETCH_VIDEO_PLAYER,
	FETCH_ACCESS_VIDEO
} from "lib/graphql"
import { useAuth } from "lib/auth"
import { useAccesses } from "lib/accesses"

import { Query } from "./"

const debug = require("debug")("lacinetek:video-player")

export default ({product, anonymous = false}) => {
	const { user, showLogin } = useAuth()
	const { hasAccessToProduct } = useAccesses()
	const [hasAccess, setHasAccess] = useState(false)

	useEffect(() => {
		return () => {
			if (!zE) {
				return
			}

			zE("webWidget", "show")
		}
	}, [])

	useEffect(() => {
		hasAccessToProduct(product)
			.then((hasAccess) => {
				setHasAccess(hasAccess)
			})
			.catch(error => console.error(error))
	}, [])

	const renderLoading = () => {
		return (
			<div className="is-loading" style={{position: "absolute", top: "50%", right: "50%"}} />
		)
	}

	if (!user && !anonymous) {
		showLogin()
		return null
	}

	if (hasAccess || anonymous) {
		return (
			<div className="videoplayer-container">
				<div className="videoplayer">
					<Query
						query={FETCH_ACCESS_VIDEO}
						options={{
							variables: { id: product.id },
							fetchPolicy: "network-only",
						}}
						forceNetwork={true}
						renderLoading={renderLoading}
					>
						{({cms: { products: { items: [{extension: {videos: { items: videos }}}]}}}) => {

							let selected = videos.sort((a, b) => a.name.includes("VO") ? -1 : 1).filter(v => v.accessInfo.streaming)[0]

							debug("Video selected", selected)

							if (!selected) {
								Sentry.captureException(new Error("[VideoPlayer] No accessible videos"), {
									tags: {
										section: "video-player",
									},
									user: user.profile,
									extra: {
										product: { id: product.id, name: product.name },
										videos
									}
								})
								return <NoAccess />
							}

							return (
								<Query
									query={FETCH_VIDEO_PLAYER}
									options={{
										variables: { id: selected.id },
									}}
									forceNetwork={true}
									renderLoading={renderLoading}
								>
									{({cms: { videos: { items: [{player}] } } }) => {
											if (zE) {
												zE("webWidget", "hide")
											}

											return (
												<Player video={selected} player={player} renderLoading={renderLoading} />
											)
									} }
								</Query>
							)
						}}
					</Query>
				</div>
			</div>
		)
	}

	Sentry.captureException(new Error("[VideoPlayer] User must have access"), {
		tags: {
			section: "video-player",
		},
		user: user.profile,
		extra: {
			product: { id: product.id, name: product.name }
		}
	})

	return <NoAccess />
}

const NoAccess = ({}) => {
	const { c } = useTranslation("common")
	return (
		<div className="description">{c("no-video-access")}</div>
	)
}

class Player extends Component {
	constructor(props) {
		super(props)

		this.onMessage = this.onMessage.bind(this)
		this.ref = createRef()
		this.state = {
			loading: true,
			waitingTracksList: true
		}
	}

	componentDidMount() {
    window.addEventListener("message", this.onMessage)

		this.ref.current.addEventListener("load", () => {
			this.load()
		})
	}

	load() {
		debug("LOAD")
		setTimeout(() => {
			if (this.ref.current && this.ref.current.contentWindow) {
				this.setState({waitingTracksList: true}, () => {
					this.getAudioTracksList()
				})
			} else {
				this.load()
			}
		}, 500)
	}

	componentWillUnmount() {
    window.removeEventListener("message", this.onMessage)
	}

	render() {
		const { player } = this.props

		return (
			<>
				<iframe
					ref={this.ref}
					src={player ? player.url : "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4"}
					frameBorder={0}
					allowFullScreen={true}
					webkitallowfullscreen={"true"}
					mozallowfullscreen={"true"}
					allow={"autoplay ; fullscreen ; encrypted-media"}
					style={this.state.loading ? { visibility: "hidden" } : {}}
				/>
				{ this.state.loading
					? this.props.renderLoading()
					: null
				}
			</>
		)
	}

	getAudioTracksList() {
		if (this.ref.current) {
			const contentWindow = this.ref.current.contentWindow
			contentWindow.postMessage({type: "audiotrackslist"}, "*")
			setTimeout(() => {
				if (this.state.waitingTracksList) {
					this.getAudioTracksList()
				}
			}, 500)
		}
	}

	play() {
		if (this.ref.current) {
			const contentWindow = this.ref.current.contentWindow
			contentWindow.postMessage({
				type: "play"
			}, "*")
		}
	}

	setTrack(id) {
		if (this.ref.current) {
			const contentWindow = this.ref.current.contentWindow
			contentWindow.postMessage({
				type: "audiotrackchange",
				payload: id
			}, "*")
		}
	}

	onMessage(event) {
		switch(event.data.type) {
			case "audiotrackslist":
				this.setState({waitingTracksList: false})
				const voTrack = event.data.payload.find(track => !track.lang.includes("fr"))
				if (voTrack && event.data.payload.map(track => track.id).indexOf(voTrack.id) > 0) {
					this.setTrack(voTrack.id)
				} else {
					this.setState({loading: false})
					this.play()
				}
				break
			case "audiotrackchanged":
				this.setState({loading: false})
				this.play()
				break
		}
	}
}
