import { useQuery, useMutation } from "@apollo/react-hooks"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"

import { useModal } from "lib/modal"
import { useToast } from "lib/toast"
import { useTranslation } from "lib/i18n/translation"
import { get, replace } from "lib/redirects"
import { FETCH_MY_PLAYLISTS_NAMES } from "lib/graphql/queries/admin-queries"
import { PLAYLIST_TOGGLE_PRODUCT, CREATE_PLAYLIST } from "lib/graphql/mutations/admin-mutations"
import playlistSlug from "lib/playlists/slug"
import { useLanguages } from "lib/languages"
import { canHaveMorePlaylists } from "lib/playlists"

export default ({ productId }) => {
	const { c } = useTranslation("common")
	const { current: lang } = useLanguages()
	const [display, setDisplay] = useState(false)
	const [updatePlaylist, setUpdatePlaylist] = useState(false)
	const [createPlaylist, setCreatePlaylist] = useState(false)
	const { data, loading } = useQuery(FETCH_MY_PLAYLISTS_NAMES, {
		fetchPolicy: "cache-and-network",
		context: {
			clientName: "adminKinow"
		}
	})
	const [createDisabled, setCreateDisabled] = useState(true)

	useEffect(() => {
		if (loading) {
			return
		}

		if (!canHaveMorePlaylists(data)) {
			return
		}

		setCreateDisabled(false)
	}, [loading])

	const handleRadioSelection = (event) => {
		setDisplay(true)
		setUpdatePlaylist(event.target.value === "playlist existante")
		setCreatePlaylist(event.target.value === "nouvelle playlist")
	}

	return (
		<>
			<h2 className="overlay-title icon-playlist">{c("playlist-add-strong")}</h2>
			<ul className="radios inline" role="radiogroup" onChange={handleRadioSelection}>
				<li>
					<input id="existing-playlist" type="radio" name="playlist-for" value="playlist existante" aria-label={c("add-to-existing-playlist")} data-collapsible="#add-playlist-options" />
					<label for="existing-playlist" className="form-label">{c("add-to-existing-playlist")}</label>
				</li>
				<li>
					<input
						id="new-playlist"
						type="radio"
						name="playlist-for"
						value="nouvelle playlist"
						aria-label={c("add-to-new-playlist")}
						data-collapsible="#create-playlist-options"
						className={createDisabled ? "disabled" : ""}
						disabled={createDisabled}
					/>
					<label for="new-playlist" className={`form-label ${createDisabled ? "disabled" : ""}`}>{c("add-to-new-playlist")}</label>
				</li>
			</ul>
			<div id="add-playlist-options" className={`collapsible ${display ? "active" : ""}`}>
				{
					updatePlaylist
					&& <ExistingPlaylist c={c} productId={productId} data={data} loading={loading} />
				}
				{
					createPlaylist
					&& <CreatePlaylist c={c} productId={productId} lang={lang} />
				}
			</div>
			{createDisabled && !loading && !updatePlaylist && (
				<p>
					{c("max-playlists-number")}
				</p>
			)}
		</>
	)
}

const feedbackStatus = (c, show, error = false) => {
	const key = error ? "playlist-product-added-nok" : "playlist-add-product-modal"
	show({
		message: c(key)
	})
}

const ExistingPlaylist = ({ c, productId, loading, data }) => {
	const selectRef = React.createRef()
	const [playlists, setPlaylists] = useState([])
	const [noPlaylistAvailable, setNoPlayistAvailable] = useState(true)
	const { hide } = useModal()
	const { show } = useToast()
	const [toggleProductMutation, { error: ToggleError, loading: toggleLoading, data: toggleData }] = useMutation(PLAYLIST_TOGGLE_PRODUCT, {
		context: {
			clientName: "adminKinow"
		}
	})

	const handleSubmit = (event) => {
		event.preventDefault()
		const playlistId = selectRef.current.value
		toggleProductMutation({
			variables: {
				playlistId,
				productId
			}
		})
	}

	const hasProduct = (playlist, productId) => {
		const productIds = playlist.products.items.map(product => product.id)

		return productIds.includes(productId)
	}

	useEffect(() => {
		if (data) {
			const availablePlaylists = data.playlists.items.filter(playlist => !hasProduct(playlist, productId))
			setNoPlayistAvailable(availablePlaylists.length === 0)
			setPlaylists(availablePlaylists)
		}
	}, [data])

	useEffect(() => {
		if (toggleData) {
			const { togglePlaylistProduct, error } = toggleData
			if (togglePlaylistProduct) {
				hide()
				feedbackStatus(c, show)
			}
			if (error) {
				feedbackStatus(c, show, true)
			}
		}
	}, [toggleData])

	useEffect(() => {
		if (ToggleError) {
			feedbackStatus(c, show, true)
		}
	}, [ToggleError])

	return (
		<>
			<h3 className="title-alt">{c("add-to-existing-playlist")}</h3>
			<div className="inline">
				<span className="select-container">
					<span className="form-label hidden">{c("playlist-select")}</span>
					<select aria-label={c("playlist-select")} ref={selectRef}>
						{
							noPlaylistAvailable
								? <option className="is-loading" value="" disabled="" hidden="">{c("playlist-no-available")}</option>
								: playlists.map(playlist => <option key={`playlist-${playlist.id}`} value={playlist.id}>{playlist.name}</option>)
						}
					</select>
				</span>
				{loading && (<span className="loader" />)}
				<input onClick={handleSubmit} type="submit" className={`${toggleLoading ? "is-loading" : ""}`} value="ok" data-overlay="#overlay-message-playlist" />
			</div>
		</>

	)
}

const CreatePlaylist = ({ c, productId, active, lang }) => {
	const [createPlaylistMutation, { data, loading, error }] = useMutation(CREATE_PLAYLIST, {
		context: {
			clientName: "adminKinow"
		}
	})
	const { hide } = useModal()
	const { show } = useToast()
	const router = useRouter()
	const { current } = useLanguages()
	const [name, setName] = useState("")
	const [isSubmitable, setIsSubmitable] = useState(false)

	const handleInputChange = (event) => {
		setName(event.target.value)
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		if (isSubmitable) {
			createPlaylistMutation({
				variables: {
					playlist: {
						name: name,
						productId: parseInt(productId)
					}
				}
			})
		}
	}

	useEffect(() => {
		if (data) {
			const { createPlaylist, error } = data
			if (createPlaylist) {
				hide()
				const realTarget = `/[lang]${get("/profile/playlists/[slug]")}`
				router.push(realTarget, replace(realTarget, current, playlistSlug(createPlaylist)))
				feedbackStatus(c, show)
			}
			if (error) {
				feedbackStatus(c, show, true)
			}
		}
	}, [data])

	useEffect(() => {
		setIsSubmitable(name.length > 0)
	}, [name])

	useEffect(() => {
		if (error) {
			feedbackStatus(c, show, true)
		}
	}, [error])

	return (
		<>
			<h3 className="title-alt">{c("add-to-new-playlist")}</h3>
			<span className="input-container icon-pen">
				<input type="text" placeholder={c("playlist-name")} onChange={handleInputChange} maxLength={100} />
			</span>
			{
				name.length === 0
				&& <p className="form-error white-text" >{c("playlist-1-char")}</p>
			}
			<a
				href="#"
				rel="nofollow noopener"
				onClick={handleSubmit}
				title={c("playlist-submit")}
				className={`button icon-playlist-add full centered ${loading ? "is-loading" : ""} ${isSubmitable ? "" : "disabled"}`}>
				{c("add-to-new-playlist")}
			</a>
		</>
	)
}


