let bundles = { fr: [], en: [], de: [] }
import isBrowser from "lib/tools/is-browser"

export const fetchAllBundles = () => { return bundles }

let bundlePromises = {};

export const getBundles = (ids, lang, country) => {
	if (isBrowser) {
		const missing = missingBundles(ids, lang)
		const keys = `${JSON.stringify(ids)}-${lang}-${country}`

		if (missing.length > 0 && !bundlePromises[keys]) {
			bundlePromises[keys] = fetch(`/fetch-bundles/${JSON.stringify(missing)}`, { headers: { country } })
				.then(response => {
					return response.json()
						.then(json => {
							json.forEach(jFilm => {
								let index = bundles[lang].map(f => f.id).indexOf(jFilm.id)
								if (index == -1) {
									bundles[lang].push(jFilm)
								} else {
									bundles[lang][index] = jFilm
								}
							})
							return getBundlesSync(ids, lang)
						})
				})
		} else {
			return (new Promise((r, rj) => r(getBundlesSync(ids, lang))))
		}

		return bundlePromises[keys]
	} else {
		const result = ids.map(id => bundles[lang].find(f => f !== null && (isNaN(id) ? f.linkRewrite == id : f.id == id)))

		return (new Promise((r, rj) => r(result)))
	}
}

export const getBundlesSync = (ids, lang) => {
	return ids.map(id => bundles[lang].find(f => f !== null && (isNaN(id) ? f.linkRewrite == id : f.id == id)))
}

export const missingBundles = (ids, lang) => {
	if (!bundles[lang]) {
		bundles[lang] = []
	}

	let existingIds = bundles[lang] ? bundles[lang].filter(f => f !== null && (ids.includes(f.linkRewrite) || ids.includes(f.id))).map(f => f.id) : []
	let missingBundles = ids.filter(id => !existingIds.includes(id))

	return missingBundles
}
