import { useEffect, useState } from "react"
import moment from "moment"
import classNames from "classnames"
import { useApolloClient } from "@apollo/react-hooks"

import { useCart, SD_TYPE, HD_TYPE, RENT, BUY } from "lib/cart"
import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"
import { FETCH_PRODUCT_PRICES } from "lib/graphql"

import { GiftForm } from "components/forms/gift"
import GiftCountries from "components/forms/gift-countries"

import { FilmNotes } from "./film-notes"

const ME = "me"
const GIFT = "gift"

const defaultAccessModes = {
	RENT: {
		SD: undefined,
		HD: undefined
	},
	BUY: {
		SD: undefined,
		HD: undefined
	}
}

export const AddTvodToCart = ({ product, gift, updating }) => {
	const { c } = useTranslation("common")
	const client = useApolloClient()
	const { countries, country } = useCountry()
	const { addProductToCart, adding } = useCart()

	const qualities = [SD_TYPE, HD_TYPE]
	const types = [RENT, BUY]

	const [typesQualities, setTypesQualities] = useState([
		{ type: RENT, quality: null },
		{ type: BUY, quality: null }
	])
	const [chosen, setChosen] = useState(null)
	const [error, setError] = useState(false)
	const [accessModes, setAccessModes] = useState(defaultAccessModes)
	const [currentCountry, setCurrentCountry] = useState(!product
		? []
		: (
			(!product.countriesAvailability || product.countriesAvailability[country.isoCode])
			&& (!country.svod_from || moment(country.svod_from) <= moment())
		)
			? country
			: countries.find(c =>
				product.countriesAvailability[c.isoCode]
				&& c.svod
				&& (!c.svod_from || moment(c.svod_from) <= moment())
			)
	)

	useEffect(() => {
		loadAccessModes()
	}, [currentCountry])

	const filterAccessModesBasedOnVideos = (filteredAccessModes, type) => {
		let furtherFilteredAccessModes = filteredAccessModes.filter(a => a.type === type)

		const mappedAccessModes = {
			SD: undefined,
			HD: undefined
		}

		furtherFilteredAccessModes.map(a => {
			if (a.quality === HD_TYPE
				|| !mappedAccessModes.HD
				|| (mappedAccessModes.HD.onlyVideos && mappedAccessModes.HD.onlyVideos.length === 0)
			) {
				mappedAccessModes.HD = a
			}

			if (a.quality === SD_TYPE
				|| !mappedAccessModes.SD
				|| (mappedAccessModes.SD.onlyVideos && mappedAccessModes.SD.onlyVideos.length === 0)
			) {
				mappedAccessModes.SD = a
			}
		})

		// Take care of edge cases where the accessMode is badly formed
		if (mappedAccessModes.HD && mappedAccessModes.HD.quality === SD_TYPE) {
			mappedAccessModes.HD = undefined
		}

		if (mappedAccessModes.SD && mappedAccessModes.SD.quality === HD_TYPE) {
			mappedAccessModes.SD = undefined
		}

		return mappedAccessModes
	}

	const loadAccessModes = async () => {
		let context = {}
		if (gift) {
			if (!currentCountry) return

			context = {
				headers: {
					'x-forwarded-for': currentCountry.ip
				}
			}
		}

		const { data: { cms: { products: { items: [{ extension: { accessModes } }] } } } } = await client.query({
			query: FETCH_PRODUCT_PRICES,
			variables: { id: product.id },
			context,
			fetchPolicy: "network-only"
		})

		// filter the freebox specific videos
		const filteredAccessModes = accessModes.filter(a =>
			a !== undefined
			&& !a.onlyVideos
			|| (
				a.onlyVideos.length > 0
				&& a.onlyVideos.find(o => !o.name.includes("Freebox"))
			)
		)

		const mappedAccessModes = {
			RENT: filterAccessModesBasedOnVideos(filteredAccessModes, RENT),
			BUY: filterAccessModesBasedOnVideos(filteredAccessModes, BUY)
		}

		setTypesQualities(types.map(type => {
			const quality = mappedAccessModes[type].HD
				? HD_TYPE
				: SD_TYPE

			return { type, quality }
		}))

		setAccessModes(mappedAccessModes)
	}

	const setQuality = (type, quality) => {
		const tqs = [...typesQualities]
		const tq = tqs.find(tq => tq.type == type)
		tq.quality = quality

		setTypesQualities(tqs)
	}

	const chooseProduct = (product, { type, quality }) => {
		setChosen({ type, quality })

		if (gift) {
			return
		}

		const filledProduct = Object.assign(
			{},
			product,
			{
				accessModes: accessModes[type][quality]
					? [accessModes[type][quality]]
					: product.accessModes
			}
		)

		addProductToCart(
			filledProduct,
			{
				type,
				quality
			}
		)
	}

	const onGiftSubmitted = (gift) => {
		if (!chosen) {
			setError(c("gift-please-choose-product"))
		} else if (gift.dateSend && moment(gift.dateSend) < moment()) {
			setError(c("gift-date-send-to-low"))
		} else {
			setError(false)

			const filledProduct = Object.assign(
				{},
				product,
				{
					accessModes: accessModes[chosen.type][chosen.quality]
						? [accessModes[chosen.type][chosen.quality]]
						: product.accessModes
				}
			)

			addProductToCart(
				filledProduct,
				{ ...chosen,
					gift,
					ip: currentCountry.ip
				}
			)
		}
	}

	const handleCountryChanged = (country) => {
		setCurrentCountry(country)
	}

	return (
		<>
			<h2 className="overlay-title icon-reel">{gift ? c("offer-film") : c("watch-the-movie")}</h2>
			{accessModes !== defaultAccessModes
				? <form aria-label={gift ? c("offer-film") : c("rent-or-buy-film")}>
					<fieldset>
						<legend className="hidden">{gift ? c("to-offer-film") : c("to-buy-or-rent-film")}</legend>
						<ul className="offers columns has-gutter centered">
							{types.map(type => {
								const currentQuality = typesQualities.find(tq => (tq.type == type))
									? typesQualities.find(tq => (tq.type == type)).quality
									: null

								return (
									<li className="item" key={`type-${type}`}>
										<div className="infos">
											<h3>{c(type)}</h3>
											<nav
												className="switch"
												aria-label={type == RENT
													? c("rent-sd-or-hd")
													: c("buy-sd-or-hd")
												}
											>
												{qualities.map((quality, index) => (
													<a
														key={index}
														href="#"
														rel="nofollow noopener"
														title={type == RENT
															? `${c("get-type-rent")} ${quality}`
															: `${c("get-type-buy")} ${quality}`
														}
														onClick={e => {
															e.preventDefault();
															setQuality(type, quality)
														}}
														className={classNames(
															{ "active": currentQuality == quality },
															{ "disabled": !accessModes[type][quality] }
														)}
													>
														{quality}
													</a>
												)
												)}
											</nav>
										</div>

										{gift
											? <a
												href="#"
												onClick={e => {
													e.preventDefault();
													chooseProduct(product, { type, quality: currentQuality })
												}}
												rel="nofollow noopener"
												title={c("add-to-my-cart")}
												className={classNames(
													"button",
													"has-price",
													{ "icon-check": chosen && chosen.type == type && gift },
													{ "is-loading": chosen && chosen.type == type && gift && adding },
													{ "disabled": !accessModes[type][currentQuality] }
												)}
											>
												{currentQuality}
												<span className="price">
													{accessModes[type][currentQuality]
														? `${accessModes[type][currentQuality].price} €`
														: '-'
													}
												</span>
											</a>
											: <a
												type="submit"
												onClick={e => {
													e.preventDefault();
													chooseProduct(product, { type, quality: currentQuality })
												}}
												rel="nofollow noopener"
												title={c("add-to-my-cart")}
												aria-label={c("add-to-my-cart")}
												className={classNames(
													"button",
													"has-price",
													{ "is-loading": chosen && chosen.type == type && adding },
													{ "disabled": !accessModes[type][currentQuality] }
												)}
											>
												{currentQuality}
												<span className="price">
													{accessModes[type][currentQuality]
														? `${accessModes[type][currentQuality].price} €`
														: '-'
													}
												</span>
											</a>
										}
									</li>
								)
							})}
						</ul>
						<FilmNotes product={product} />
						<hr className="blank" />

						{gift
							? <>
								<GiftCountries product={product} onCountryChanged={handleCountryChanged} />
								<GiftForm
									onSubmit={onGiftSubmitted}
									error={error}
									adding={adding}
								/>
							</>
							: null
						}
					</fieldset>
				</form>
				: <div className="loader centered" />
			}
		</>
	)
}
