import { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import { useCountry } from "lib/country"
import { useAdminQuery } from "lib/graphql"
import { useEffect } from "react"
import { i18n } from "lib/i18n"
import { useTranslation } from "lib/i18n/translation"

export default (props) => {
	const name = props.query.definitions[0].name
		? props.query.definitions[0].name.value
		: "generic"

	const key = `${name}-${i18n.language}${(props.options && props.options.variables)
		? `-${(props.options.variables.slug
			? props.options.variables.slug
			: props.options.variables.id)}`
		: ""}
	`

	const [currentLang, setCurrentLang] = useState(i18n.language)
	const [forceUpdate, setForceUpdate] = useState(false)

	useEffect(() => {
		if (i18n.language === currentLang) {
			return
		}

		setForceUpdate(true)
		setCurrentLang(i18n.language)
		setTimeout(() => {
			setForceUpdate(false)
		}, 1000)
	})

	return (
		<I18nQuery
			key={key}
			{...props}
			forceNetwork={props.forceNetwork || forceUpdate}
		>
			{(data, fetchMore) => props.children(data, fetchMore)}
		</I18nQuery>
	)
}

const I18nQuery = (props) => {
	const { query, renderLoading, renderError, children, admin, forceNetwork } = props
	const { userCountry } = useCountry()
	let { options } = props

	if (!options) options = {}

	if (admin) {
		if (!options.context) {
			options.context = {}
		}

		if (!options.context.headers) {
			options.context.headers = {}
		}

		options.context.headers["country"] = userCountry.isoCode
	}

	if (options.variables) {
		if (options.variables.slug) {
			options.variables.query = `linkRewrite.value:"${options.variables.slug}"`
			delete options.variables.slug
		}

		if (options.variables.type) {
			options.variables.query = `${options.variables.query}&type=TVOD`
		}
	}

	if (forceNetwork) {
		options.fetchPolicy = 'network-only'
	}

	const { loading, data, error, fetchMore } = admin
		? useAdminQuery(query, options || {})
		: useQuery(query, options)

	const { c } = useTranslation("common")

	if (loading) {
		return renderLoading
			? renderLoading()
			: <div className="is-loading" />
	} else if (!data) {
		if (props.onError && error) {
			return props.onError(error)
		}

		return (
			<I18nQuery {...props}>
				{data => children({ data, fetchMore })}
			</I18nQuery>
		)
	}

	if (error) {
		return renderError
			? renderError(error)
			: <div>
				<h2>{c("error-thrown")}</h2>
				<pre>{error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}</pre>
			</div>
	}

	return children(data, fetchMore)
}
