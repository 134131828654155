import { useTranslation } from "lib/i18n/translation"
import openPlayer from "lib/tools/player"

const PlayerLauncher = () => {
	const { c } = useTranslation("common")

	return (
		<>
			<h2
				className="section-title icon-play"
				dangerouslySetInnerHTML={{ __html: c("player-install-strong") }}
			/>
			<p dangerouslySetInnerHTML={{ __html: c("player-last-step").replace(/(\n)/gm, "<br>") }} />
			<p>
				<a
					href="#"
					onClick={e => {
						e.preventDefault()
						openPlayer()
					}}
					rel="nofollow noopener"
					title={c("player-download")}
					className="button icon-download"
				>
					{c("player-download")}
				</a>
			</p>
		</>
	)
}

export default PlayerLauncher
