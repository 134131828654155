import { useTranslation } from "lib/i18n/translation"
import { useAuth } from "lib/auth"
import { Form, Input } from "@rocketseat/unform"
import * as Yup from "yup"
import { useModal } from "lib/modal"

const schema = (c) => {
	return Yup.object().shape({
		password: Yup.string()
			.required(c("password_required")),
	})
}

export default ({ title, description, values, callback, resolve }) => {
	const { c } = useTranslation("common")
	const { hide } = useModal()

	const handleSubmit = (v) => {
		callback(values, v.password, resolve)
		hide()
	}

	return (
		<>
			<Form schema={schema(c)} onSubmit={handleSubmit} aria-label={c("ph_password")}>
				<fieldset>
					<legend className="hidden">{c("ph_password")}</legend>
					<ul className="columns has-gutter">
						<li>
							<h3 className="nowrap">{c("confirm-password-desc")}</h3>
							<span className="form-label hidden">{c("ph_password")}</span>
							<span className="input-container icon-lock-on">
								<Input name="password" type="password" placeholder={c("ph_password")} className="login-pw" />
							</span>
						</li>
					</ul>
					{ description
						? <p>{c(description)}</p>
						: null
					}
					<p>
						<button type="submit" className="button" aria-label={c(title)}>{c(title)}</button>
					</p>
				</fieldset>
			</Form>
		</>
	)
}
