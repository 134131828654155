import { useEffect, useRef, useState } from "react"

import YouTube from "react-youtube"
import { Query } from "./"
import {
	FETCH_EXTRACT_PLAYER,
	FETCH_PRODUCT_EXTRACT
} from "lib/graphql"
import { bonusDataLayer } from "lib/gtm/interactions"

const opts = {
	playerVars: {
		autoplay: 0,
	},
}

export default ({product, bonus}) => {
	if (bonus) {
		return <Player extract={bonus} bonus={true}/>
	} else if (product.extension) {
		const extract = getExtracts(product)[0]
		return <Player extract={extract} />
	} else {
		const renderLoading = () => {
			return (
				<div className="is-loading" style={{position: "absolute", top: "50%", right: "50%"}} />
			)
		}

		return <Query
			query={FETCH_PRODUCT_EXTRACT}
			options={{variables: { id: product.id }}}
			forceNetwork={true}
			renderLoading={renderLoading}
		>
			{({cms: { products: { items: [{ extension: { extracts: { items: [ extract ] } } }] }}}) => {
				return <Player extract={extract} />
			}}
		</Query>
	}
}

const Player = ({extract, bonus=false}) => {

	const ref = useRef(null)
	const [bonusPlayed, setBonusPlayed] = useState(false)

	const playerEvent = (evt) => {
		if (bonus && evt.data.type === "play" && !bonusPlayed) {
			bonusDataLayer(extract)
			setBonusPlayed(true)
		}
	}

	const onPlay = (event) => {
		bonusDataLayer(extract)
		setBonusPlayed(true)
	}

	useEffect(() => {
		if (ref.current) {
			const contentWindow = ref.current.contentWindow
			contentWindow.postMessage({
				type: "play"
			}, "*")
		}
		window.addEventListener("message", playerEvent)
		return () => { window.removeEventListener("message", playerEvent) }
	}, [ref.current])

	const renderLoading = () => {
		return (
			<div className="is-loading" style={{position: "absolute", top: "50%", right: "50%"}} />
		)
	}

	return (
		<div className="videoplayer-container">
			<div className="videoplayer">
				<Query
					query={FETCH_EXTRACT_PLAYER}
					options={{variables: { id: extract.id }}}
					forceNetwork={true}
					renderLoading={renderLoading}
				>
					{({cms: { extracts: { items: [{ player, mediaSource }] } }}) => {
						if (mediaSource.id === process.env.YOUTUBE_PLAYER_ID) {
							return <YouTube videoId={player.url.split('embed/')[1]} opts={opts} onPlay={onPlay} />
						}
						return (
							<iframe
								ref={ref}
								src={player ? player.url : "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"}
								frameBorder="0"
								allowFullScreen={true}
								webkitallowfullscreen="true"
								mozallowfullscreen="true"
								allow="autoplay ; fullscreen ; encrypted-media"
							/>
						)
					}}
				</Query>
			</div>
		</div>
	)
}
