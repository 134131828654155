const baseDirector = {
	name: "",
	description: "Lorem ipsum dolor sit amet.",
	image: "/static/images/default_director.png",
	metadata: [
		{ name: "", value: ""}
	],
	products: {
		items: [{name: "Lorem"}, {name: "Lorem"}, {name: "Lorem"}, {name: "Lorem"}]
	}
}

export default {
	id: 9999999,
	name: "Lorem ipsum dolor",
	description: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
	cover: "/static/images/default_movie.png",
	origin: "test",
	directors: { items: [] },
	actors: { items: [] },
	categories: { items: [] }, actors: { items: [] }, 
	extracts: { items: [{}, {}, {}, {}] },
	videos: { items: [{}, {}, {}, {}] },
	images: [{source: "/static/images/default_movie.png"}, {source: "/static/images/default_movie.png"}, {source: "/static/images/default_movie.png"}, {source: "/static/images/default_movie.png"}],
	metadata: [ {name: "", value: ""} ],
	countriesAvailability: {},

	// base data
	recommenders: ["Lorem", "Ipsum"],
	recommendersIds: []
}

