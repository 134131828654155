import React from 'react'

import { useAccesses } from "lib/accesses"
import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"

import ConfirmTerminate from "components/modals/confirm-terminate"
import UpdatePaymentMethod from "components/modals/update-payment-method"

const SubscriptionActions = () => {
	const { show } = useModal()
	const { subscription, isSubscriptionFromStripe } = useAccesses()
	const { c } = useTranslation("common")

	const updatePaymentMethod = (subscription) => {
		show({
			id: "update-payment-method",
			title: c("update-payment-method"),
			children: <UpdatePaymentMethod access={subscription} />,
			className: "modal short"
		})
	}

	const confirmTerminate = (subscription, renew = false) => {
		show({
			id: "confirm-terminate",
			children: <ConfirmTerminate access={subscription} renew={renew} />,
			className: "modal short"
		})
	}

	if (!subscription.product.extension.autoRenewal) {
		return <span className="centered">-</span>
	}

	if (!subscription.recurring) {
		if (subscription.order
			&& !isSubscriptionFromStripe()
		) {
			return <span>{c("canceled")}</span>
		}

		return <a
			href="#"
			onClick={e => {
				e.preventDefault()
				confirmTerminate(subscription, true)
			}}
			rel="nofollow noopener"
			title={c("terminate-renew")}
		>
			{c("terminate-renew")}
		</a>
	}

	return (
		<>
			{isSubscriptionFromStripe() && (<>
				<a
					href="#"
					onClick={e => {
						e.preventDefault()
						updatePaymentMethod(subscription)
					}}
					rel="nofollow noopener"
					title={c("update-payment-method")}
				>
					{c("update-payment-method")}
				</a>
				<br />
			</>)}
			<a
				href="#"
				onClick={e => {
					e.preventDefault()
					confirmTerminate(subscription)
				}}
				rel="nofollow noopener"
				title={c("terminate")}
			>
				{c("terminate")}
			</a>
		</>
	)
}

export default SubscriptionActions
