import { useState } from "react"
import { Form, Input } from "@rocketseat/unform"
import * as Yup from "yup"

import { useAuth } from "lib/auth"
import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"

const schema = (c) => {
	return Yup.object().shape({
		email: Yup.string()
			.email(c("email_invalid"))
			.required(c("email_required"))
	})
}

const handleForgotPassword = async ({ values, auth, resetForm }) => {
	return await auth.forgotPassword(values)
}

const ForgotPasswordForm = ({email}) => {

	const auth = useAuth()
	const { c, ec } = useTranslation("common")

	const [error, setError] = useState(false)
	const { hide } = useModal()
	const [resetting, setResetting] = useState(false)
	const [success, setSuccess] = useState(false)

	const handleSubmit = async (values, { resetForm }) => {
		setError(false)
		setResetting(true)
		handleForgotPassword({values, auth, resetForm}).then(result => {
			if (result == false) setError(true)
			else setSuccess(true)
			setResetting(false)
		})
	}

	if (success) {
		return (
			<>
				<h2 className="overlay-title">{c("forgot-password-email-sent")}</h2>
				<div className="description">{c("forgot-password-email-sent-desc")}</div>
				<a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" className="button">{c("close")}</a>
			</>
		)
	} else {
		return (
			<>
				<h2 className="overlay-title">{c("login-form-title")}</h2>
				<Form schema={schema(c)} onSubmit={handleSubmit} aria-label={c("forgot_password")}>
					<fieldset>
						<legend className="hidden">{c("to-reset-password")}</legend>
						<ul className="columns has-gutter">
							<li>
								<h3 className="nowrap" dangerouslySetInnerHTML={{ __html: c(email ? "reset-password-bold" : "i-forgot-my-password")}} />
								<span className="form-label hidden">{c("ph_email")}</span>
								<span className="input-container icon-user">
									<Input name="email" type="text" placeholder={c("ph_email")} className="login-id" value={email} />
								</span>
							</li>
						</ul>
						<button type="submit" title={c("reset-password")} aria-label={c("reset-password")} className={`icon-check full ${resetting ? "loader" : ""}`}>{c("reset-password")}</button>
						{ error
							? <span>{c("unable_to_connect")}</span>
							: null
						}
					</fieldset>
				</Form>
			</>
		)
	}
}

export default ForgotPasswordForm
