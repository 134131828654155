import { useContext, createContext } from "react"
import classNames from "classnames"
import Bonuses from "components/common/bonuses"
import { useModal } from "lib/modal"
import { getBonuses, BONUS_ARCHIVES, BONUS_EXCLUSIFS } from "lib/tools/product"

const bonusContext = createContext()

export const BonusConsumer = bonusContext.Consumer

export const ProvideBonus = ({children}) => {
  const bonus = useProvideBonus()

  return (
		<bonusContext.Provider value={bonus}>
			{children}
		</bonusContext.Provider>
	)
}

export const useBonus = () => {
  return useContext(bonusContext)
}

const useProvideBonus = () => {
	const { show } = useModal()

	const showBonuses = (product, type, title, selected = 0) => {

		show({
			id: `bonus-${type}-modal-for-product-${product.id}`,
			children: (
				<Bonuses
					productId={product.id}
					type={type}
					bonuses={product.extension ? getBonuses(product, type) : null}
					overlayed={true}
					selected={selected}
				/>
			),
			className: classNames(
				"overlay",
				"modal",
				{ "bonus-archives-color": type == BONUS_ARCHIVES },
				{ "red": type == BONUS_EXCLUSIFS }
			)
		})
	}

	return {
		showBonuses
	}
}
