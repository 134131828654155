
import { useTranslation } from "lib/i18n/translation"
import { Profile } from "components/forms"
import { useAnchorNav } from "lib/anchor-nav"
import { useEffect } from "react"
import slugify from "slugify"

const Coords = ({title}) => {

	const { c } = useTranslation("common")

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	useEffect(
		() => {
			setSectionLoaded( title )
		},
		[isSectionsSetted]
	)

	return (
		<section id={ slugify( c( title ) ).toLowerCase() } className="section-anchor">
			<h2 className="section-title icon-parameters">{c("my-coords-strong")}</h2>
			<Profile />
		</section>
	)
}

export default Coords
