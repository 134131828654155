
import { useTranslation } from "lib/i18n/translation"
import { useState } from "react"

const Note = ({ value, mutation }) => {
	const { c } = useTranslation("common")
	const [saving, setSaving] = useState(false)
	const [data, setData] = useState("")
	const [messageChars, setMessageChars] = useState(value ? value.length : 0)


	const handleNoteChange = (event) => {
		setData(event.target.value)
		setSaving(true)
		setMessageChars(event.target.value.length)
	}

	const handleFocusLoses = (event) => {
		if (saving) {
			mutation(data)
			setSaving(false)
		}
	}


	return (
		<>
		<textarea
					onChange={handleNoteChange}
					onBlur={handleFocusLoses}
					placeholder={c("playlist-note")}
					className="playlist-notes full"
					aria-label={c("playlist-note")}
					maxLength="2000"
					defaultValue={value}
					>
		</textarea>
			<p className="form-error text-black">
				<span>{messageChars}</span>
				<span>/2000</span>
			</p>
		</>
	)
}

export default Note
