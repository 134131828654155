import { createContext, useContext } from "react"
import { Cookies } from "react-cookie"
import { isMobile } from "mobile-device-detect"
import { useApolloClient } from "@apollo/react-hooks"

import { useAuth } from "lib/auth"
import { CREATE_DEVICE } from "lib/graphql/mutations"
import decrypt from "lib/tools/decrypt"
import { useModal } from "lib/modal"

import VideoPlayer from "components/common/video-player"
import ExtractPlayer from "components/common/extract-player"
import UnavailableDevice from "components/modals/unavailable-device"
import { CategoryVideoPlayer } from "components/common/category-video-player"

const videoPlayerContext = createContext({
	component: null,
	props: {},
	showVideoPlayer: () => { },
	hideVideoPlayer: () => { }
})

const LACINETEK_FP = "lacinetek_fp"

export const VideoPlayerConsumer = videoPlayerContext.Consumer

export const ProvideVideoPlayer = ({ children }) => {
	const videoPlayer = useProvideVideoPlayer()

	return (
		<videoPlayerContext.Provider value={videoPlayer}>
			{children}
		</videoPlayerContext.Provider>
	)
}

export const useVideoPlayer = () => {
	return useContext(videoPlayerContext)
}

const useProvideVideoPlayer = () => {

	const { show } = useModal()
	const { user, updateDevices } = useAuth()
	const client = useApolloClient()

	const seeExtract = (product) => {
		show({
			id: `extract-player-for-${product.id}`,
			children: <ExtractPlayer product={product} />,
			className: isMobile ? "mobile-video" : "modal"
		})
	}

	const seeCategoryTeaser = (category) => {
		show({
			id: `teaser-player-for-${category.id}`,
			children: <CategoryVideoPlayer category={category} youtube />,
			className: isMobile ? "mobile-video" : "modal"
		})
	}

	const seeBonus = (bonus, modal = false) => {
		if (modal) {
			show({
				id: `bonus-player-for-${bonus.id}`,
				children: <ExtractPlayer bonus={bonus} />,
				className: isMobile ? "mobile-video" : "modal"
			})
		} else {
			return <ExtractPlayer bonus={bonus} />
		}
	}

	const showFilm = (product, anonymous) => {
		show({
			id: `video-player-for-${product.id}`,
			children: <VideoPlayer product={product} anonymous={anonymous} />,
			className: isMobile ? "mobile-video" : "modal"
		})
	}

	const seeFilm = (product, anonymous = false, userDevices) => {
		if (anonymous) {
			return showFilm(product, anonymous)
		}

		const cookies = new Cookies()
		const fp = JSON.parse(decrypt(cookies.get(LACINETEK_FP)))
		let fingerprint = `${fp.hash}`.split("-")

		fingerprint.splice(-2, 1)
		fingerprint = fingerprint.join("-")

		fingerprint = `${fingerprint}-${user.profile.id}`
		let current = user.devices.find(d => d.fingerprint == fingerprint)

		//FIXME use user devices list from param instead of user context
		if (user.devices.length === 0 && userDevices) {
			current = userDevices.find(d => d.fingerprint == fingerprint)
		}

		if (current || (!current && user.devices.length < 5)) {
			if (!current) {
				client.mutate({
					mutation: CREATE_DEVICE, variables: {
						fingerprint,
						os: fp.os,
						type: "browser",
						application: fp.browser
					}
				})
					.then(({ data: { addDevice: devices } }) => { updateDevices(devices) })
			}

			return showFilm(product, anonymous)
		}

		show({
			id: "unavailable-device",
			children: <UnavailableDevice />,
			className: isMobile ? "mobile-video" : "modal"
		})
	}

	return {
		seeFilm,
		seeExtract,
		seeBonus,
		seeCategoryTeaser
	}
}
