import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import moment from "moment"

import { useAuth } from "lib/auth"
import { useCountry } from "lib/country"
import { useCart } from "lib/cart"
import { useTranslation } from "lib/i18n/translation"
import isBrowser from "lib/tools/is-browser"
import { getFrom } from "lib/redirects"
import { useAnchorNav } from "lib/anchor-nav"
import entries from "config/profile-menus"

const ProfilePage = ({ actionIfNotConnected, id, extraData = null }) => {
	const auth = useAuth()
	const router = useRouter()
	const { setSections } = useAnchorNav()
	const { userCountry } = useCountry()
	const { c, ec } = useTranslation("common")
	const { getPrepayments } = useCart()
	const [menu, setMenu] = useState(isBrowser ? buildMenu(entries, router) : { children: [] })

	useEffect(() => {
		setMenu(buildMenu(entries, router))
		getPrepayments()
	}, [])

	useEffect(() => {
		if (!auth.user && !auth.connecting) {
			if (isBrowser) {
				if (actionIfNotConnected) {
					actionIfNotConnected()
				} else {
					router.push("/")
				}
			}
		}
	}, [auth.connecting])

	useEffect(() => {
		setSections(menu.children)
	}, [menu.children.length])

	if (!auth.user) {
		if (auth.connecting) {
			return <div className="loader is-connecting"></div>
		}

		if (!isBrowser && !actionIfNotConnected) {
			throw new Error("auth_exception")
		}
	}

	if (!auth.user) return null

	const splittedTitle = c(menu.title).split(' ')

	if (!userCountry.svod || (userCountry.svod_from && moment(userCountry.svod_from) > moment())) {
		menu.children = menu.children.filter(m => m.title !== "my-subscription")
	}

	return (
		<>
			{
				!id && !menu.description
				&& <section>
					{
						!id
						&& <h1 className="main-title">
							{splittedTitle[0]} <strong>{splittedTitle.slice(1, splittedTitle.length).join(' ')}</strong>
						</h1>
					}
					{menu.description
						? <p>{ec(menu.description)}</p>
						: null
					}
				</section>
			}
			{menu.children.map((item, index) => {
				// FIXME Don't know why item.component can be undefined :O
				if (!item.component) {
					return null
				}

				return <item.component
					key={`profile-children-${index}`}
					{...item}
					id={id}
					{...extraData}
					menu={[menu, setMenu]}
				/>
			})}
			{!!menu.component && (
				<menu.component {...menu.componentProps} />
			)}
		</>
	)
}

function buildMenu(entries, router) {
	const route = router ? router.route.replace("/[lang]", "") : "/profile"
	let entry = entries
		.reverse()
		.find(e => getFrom(route).includes(e.target))
	entries.reverse()

	if (!entry) {
		entry = entries[0]
	}

	return entry
}

export default ProfilePage
