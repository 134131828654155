import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { useState } from "react"

export default ({ startMutation, item }) => {
	const { c } = useTranslation("common")
	const { hide } = useModal()
	const [loading, setLoading] = useState(false)

	const handleConfirmClick = (event) => {
		event.preventDefault()
		setLoading(true)
		startMutation(item)
	}

	return (
		<>
			<h2 className="overlay-title icon-playlist">{c("my-playlists-strong")}</h2>
			<p> {c("playlist-confirm-delete-product-desc")} </p>
			<nav className="buttons centered" aria-label="navigation suppression playlist">
				<a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" title="Non" className="icon-blank close">{c("no")}</a>
				<a href="#" onClick={handleConfirmClick} title="Oui" className={`icon-check with-icon-after ${loading ? "is-loading" : ""}`} data-overlay="#overlay-message-playlist">{c("yes")}</a>
			</nav>
		</>
	)
}
