import moment from "moment"
import { useEffect } from "react"
import slugify from "slugify"

import { metadata, FEATURE_DATE_FROM, FEATURE_DATE_TO } from "lacinetek-common/src/metadata"

import { useAnchorNav } from "lib/anchor-nav"
import { useAccesses } from "lib/accesses"
import { useCountry } from "lib/country"
import { overlayDataLayer } from "lib/gtm/interactions"
import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { isSubscriptionTrialOver } from "lib/subscription/is-subscription-trial-over"

import { CGV } from "components/modals"

import SubscriptionActions from "./subscription-actions"

const MySubscription = ({ title }) => {
	const { c } = useTranslation("common")
	const { show } = useModal()
	const { subscription, hasSubscribed } = useAccesses()
	const { userCountry: country } = useCountry()

	const validity = (subscription) => {
		let validity

		const sub = country.subscriptions.find(s => s.id == subscription.product.id)

		if (sub && sub.festival) {
			const cat = subscription.product.extension.categories.items[0]

			validity = moment.parseZone(metadata(cat, FEATURE_DATE_TO), 'DD/MM/YYYY')
				.diff(moment.parseZone(metadata(cat, FEATURE_DATE_FROM), 'DD/MM/YYYY'), 'days', true)

			validity = `${validity} ${c("days")}`
		} else {
			validity = Math.round(moment(subscription.dateRenewal)
				.diff(moment(subscription.dateAdd), 'months', true))

			if (!isSubscriptionTrialOver(subscription)) {
				validity = c("trial-period")
			} else {
				validity = `${validity} ${c("months")}`
			}
		}

		return !validity.includes("NaN") && validity
	}

	const showOverlay = (id, title, Component, className) => {
		show({
			id,
			title: c(title),
			fixedHeader: true,
			children: <Component />,
			className
		})
	}

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	useEffect(
		() => setSectionLoaded(title)
		, [isSectionsSetted]
	)

	return (
		<section id={slugify(c(title)).toLowerCase()} className="section-anchor">
			<h2 className="section-title icon-selection">{c("my-subscription-strong")}</h2>
			<p className="excerpt">
				{subscription
					? c("have-subscribed-to")
					: c("have-not-subscribed-to")
				}
			</p>

			{subscription && (
				<table className="table">
					<thead className="thead">
						<tr className="tr">
							<td className="td"><span>{c("name")}</span></td>
							<td className="td"><span>{c("starts")}</span></td>
							<td className="td"><span>{c("expires-on")}</span></td>
							<td className="td"><span>{c("term-of-validity")}</span></td>
							<td className="td"><span>{c("actions")}</span></td>
						</tr>
					</thead>
					<tbody className="tbody">
						<tr className="tr" key={`subscription-${subscription.id}`}>
							<td className="td">
								<span>{subscription.product.name}</span>
							</td>
							<td className="td">
								<span>
									{subscription.dateExp && moment(subscription.dateAdd).format("DD/MM/YYYY")}
									{!subscription.dateExp
										&& subscription.product
										&& subscription.product.extension
										&& subscription.product.extension.categories
										&& subscription.product.extension.categories.items.length == 1
										&& metadata(subscription.product.extension.categories.items[0], FEATURE_DATE_FROM)
										&& moment.parseZone(metadata(subscription.product.extension.categories.items[0], FEATURE_DATE_FROM), 'DD/MM/YYYY').format('DD/MM/YYYY')
									}
								</span>
							</td>
							<td className="td">
								<span>
									{subscription.dateExp && (
										moment(subscription.dateExp).format("DD/MM/YYYY")
									)}
									{!subscription.dateExp
										&& subscription.product
										&& subscription.product.extension
										&& subscription.product.extension.categories
										&& subscription.product.extension.categories.items.length == 1
										&& metadata(subscription.product.extension.categories.items[0], FEATURE_DATE_TO)
										&& moment.parseZone(metadata(subscription.product.extension.categories.items[0], FEATURE_DATE_TO), 'DD/MM/YYYY').format('DD/MM/YYYY')
									}
								</span>
							</td>
							<td className="td">
								<span>{validity(subscription)}</span>
							</td>
							<td className="td">
								<p>
									<SubscriptionActions />
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			)}

			{hasSubscribed && (
				<p className="description">
					{c("your-subscription-desc", { country: country.name })}&nbsp;
					<a
						href="#"
						data-overlay="#overlay-cgvu"
						onClick={e => {
							e.preventDefault(); overlayDataLayer("CGVU");
							showOverlay("cgv", "complete-cgvu", CGV)
						}}
						rel="nofollow noopener"
						title={c("cgvu-single")}
					>
						{c("cgvu")}
					</a>
				</p>
			)}
		</section>
	)
}

export default MySubscription
