import { Query } from "components/common"

import {
	FETCH_ADMIN_CATEGORY,
	FETCH_CATEGORY_ID,
} from "lib/graphql/queries/admin-queries"

import {
	FETCH_CATEGORY,
	FETCH_CATEGORY_LIGHT,
	FETCH_COMPLETE_CATEGORY,
	FETCH_COMPLETE_CATEGORY_BY_SLUG
} from "lib/graphql/queries"

export default ({category, light, category_id, withCustom, adminDetails, slug, withProducts, children, renderLoading}) => {
	if (category) {
		category_id = process.env.CATEGORIES.find(c => c.key == category).id
	}

	if (!adminDetails && (category_id || slug)) {
		return (
			<CategoryQuery
				id={category_id}
				slug={slug}
				withProducts={withProducts}
				renderLoading={renderLoading}
				light={light}
			>
				{ category => children(category) }
			</CategoryQuery>
		)
	}

	return (
		<Query
			query={FETCH_CATEGORY_ID}
			admin={true}
			options={{variables: { key: category || category_id.toString(), adminDetails }}}
			renderLoading={renderLoading}
		>
			{({ category: cat }) => {
				if (cat) {
					return (
						<CategoryQuery
							id={cat.foreignkey || category_id}
							custom={cat.custom}
							withProducts={withProducts}
							renderLoading={renderLoading}
							light={light}
						>
							{children}
						</CategoryQuery>
					)
				}

				return <div>Catalog {category} not found</div>
			}}
		</Query>
	)
}

const CategoryQuery = ({id, slug, custom, withProducts, children, page, subCategories, renderLoading, light}) => {

	let query
	if (light) {
		query = FETCH_CATEGORY_LIGHT
	}	else if (withProducts) {
		if (slug)
			query = FETCH_COMPLETE_CATEGORY_BY_SLUG
		else
			query = FETCH_COMPLETE_CATEGORY
	} else {
		query = FETCH_CATEGORY
	}

	return (
		<Query query={query} options={{variables: { id, slug, page: page || 1 }}} renderLoading={renderLoading}>
			{({cms: {categories: { items } } }) => {

				const item = items.find(i => (slug == i.linkRewrite) || i.id == id)

				if (items.length == 0 || !item) {
					return (<div>Catalog {id || slug} not found</div>)
				}

				if (!light) {
					if (item.children && item.children.pagination.page !== item.children.pagination.lastPage) {
						subCategories = subCategories ? subCategories.concat(item.children.items) : item.children.items
						return (
							<CategoryQuery id={id} slug={slug} withProducts={withProducts} children={children} page={page ? page+1 : 2} subCategories={subCategories} renderLoading={renderLoading} light={light}>
								{children}
							</CategoryQuery>
						)
					}

					if (subCategories && item.children.items.length !== item.children.pagination.total) {
						subCategories = subCategories.concat(item.children.items)
						return children({ ...item, children: { items: subCategories }}, custom ? JSON.parse(custom) : null)
					}
				}

				return children(item, custom ? JSON.parse(custom) : null)
			}}
		</Query>
	)
}
