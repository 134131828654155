import { useState, useEffect } from "react"
import slugify from "slugify"
import { useQuery } from "@apollo/react-hooks"

import { useTranslation } from "lib/i18n/translation"
import { useAnchorNav } from "lib/anchor-nav"
import { useModal } from "lib/modal"
import { useAuth } from "lib/auth"
import { FETCH_MY_PLAYLISTS } from "lib/graphql/queries/admin-queries"

import { Link } from "components/common"
import CreatePlaylistModal from "components/modals/create-playlist"
import image, { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"
import Image from "components/common/image"
import playlistSlug from "lib/playlists/slug"
import { canHaveMorePlaylists, buildMenuFromPlaylistsData } from "lib/playlists"

export default ({ title, menu: [menu, setMenu] }) => {
	const { c } = useTranslation("common")
	const { user: { profile: { firstname } } } = useAuth()
	const { show, hide } = useModal()
	const [playlistsToDisplay, setPlaylistsToDisplay] = useState(0)
	const [currentPlaylists, setCurrentPlaylists] = useState({
		total: 0,
		playlists: []
	})
	const { isSectionsSetted, setSectionLoaded } = useAnchorNav()
	const { data, loading, refetch, networkStatus } = useQuery(FETCH_MY_PLAYLISTS, {
		fetchPolicy: "network-only",
		context: {
			clientName: "adminKinow"
		},
		variables: {
			perPage: 7
		},
		notifyOnNetworkStatusChange: true
	})

	useEffect(() => {
		if (loading) {
			return
		}

		setMenu({ ...buildMenuFromPlaylistsData(menu, data) })
	}, [loading])

	useEffect(
		() => {
			setSectionLoaded(title)
		},
		[isSectionsSetted]
	)

	const createPlaylist = (event) => {
		event.preventDefault()

		hide(() => {
			show({
				id: "playlist-add",
				className: "modal short",
				children: <CreatePlaylistModal />
			})
		})
	}

	const handleShowMore = (event) => {
		event.preventDefault()
		if (currentPlaylists.playlists.length <= playlistsToDisplay) {
			refetch({
				perPage: 16,
				page: parseInt((playlistsToDisplay - 7 + 16) / 16),
				start: 7
			})

			return
		}

		setPlaylistsToDisplay(state => state + 16)
	}

	const handleShowReboot = (event) => {
		event.preventDefault()
		setPlaylistsToDisplay(7)
		window.scroll({ top: 0, behavior: "smooth" })
	}

	useEffect(() => {
		if (!data || networkStatus !== 7) {
			return
		}
		setCurrentPlaylists(state => {
			return {
				total: data.playlists.pagination.total,
				playlists: state.playlists.concat(data.playlists.items)
			}
		})
		setPlaylistsToDisplay(state => state + data.playlists.items.length)
	}, [data])

	if (!data) {
		return (
			<section id={slugify(c(title)).toLowerCase()} className="section-anchor">
				<h2 className="section-title icon-playlist">{c("loading")}</h2>
			</section>
		)
	}

	return (
		<section id={slugify(c(title)).toLowerCase()} className="section-anchor">
			<h2 className="section-title icon-playlist">{c("my-playlists-strong")}</h2>
			{
				currentPlaylists.length === 0
				&& <p>{c("no-playlist")} <br></br>{c("info-no-playlist", { firstname })}</p>
			}
			<ul className="grid playlists films">
				{canHaveMorePlaylists(data) ? (
					<li className="item link">
						<div className="item-content">
							<a href="#" onClick={createPlaylist} title={c("create-playlist")} data-overlay="#overlay-playlist-add" className="more-link">
								<span className="button icon-playlist-add">{c("create-playlist")}</span>
							</a>
						</div>
					</li>
				) : (
					<li className="item link no-link">
						<div className="item-content">
							{c("max-playlists-number")}
						</div>
					</li>
				)}
				{
					currentPlaylists.playlists.slice(0, playlistsToDisplay).map(item =>
						<li key={`playlist-item-${item.id}`} className="item">
							<div className="item-content">
								<Link
									target="/profile/playlists/[slug]"
									as={`/profile/playlists/${playlistSlug(item)}`}
									className="more-link" >
								</Link>
								<div className="infos">
									<h3>{item.name}</h3>
								</div>
								<figure className="cover">
									<picture>
										<Image
											src={image(item.products.items[0], PRODUCT_PHOTOGRAMME)}
											alt={item.name}
										/>
									</picture>
								</figure>
							</div>
						</li>
					)
				}
			</ul>
			{currentPlaylists.total > playlistsToDisplay && (
				<ShowMore onClick={handleShowMore} loading={loading} />
			)}
			{currentPlaylists.total <= playlistsToDisplay && currentPlaylists.total > 7 && (
				<Reboot onClick={handleShowReboot} />
			)}
		</section>
	)
}

const ShowMore = ({ onClick, loading = false }) => {
	const { c } = useTranslation("common")

	return (
		<p className="section-link centered">
			<a
				href="#"
				onClick={onClick}
				rel="nofollow noopener"
				title={c("show-more")}
				className={`button icon-more ${loading ? "is-loading" : ""}`}
			>
				{c("show-more")}
			</a>
		</p>
	)
}

const Reboot = ({ onClick }) => {
	const { c } = useTranslation("common")

	return (
		<p className="section-link centered">
			<a href="#" onClick={onClick} rel="nofollow noopener" title={c("show-less")} className="button icon-less">{c("show-less")}</a>
		</p>
	)
}
