import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"

import GooglePlayButtonFr from "static/images/footer/google-play-badge-fr.svg"
import AppleStoreButtonFr from "static/images/footer/apple-store-badge-fr.svg"
import GooglePlayButtonDe from "static/images/footer/google-play-badge-de.svg"
import AppleStoreButtonDe from "static/images/footer/apple-store-badge-de.svg"

const DownloadAppButton = ({ platform }) => {

	if (!platform || !["google","apple"].includes(platform)) {
		return null
	}

	const { c } = useTranslation("common")
	const { country } = useCountry()

	const buildAppLink = (platform, country) => {
		if (platform === "apple") {
			if (["de","at","be","lu"].includes(country)) {
				return `https://apps.apple.com/${country}/app/lacinetek/id1542001659`
			}
			return "https://apps.apple.com/fr/app/lacinetek/id1542001659"
		}
		return "https://play.google.com/store/apps/details?id=com.lacinetek.lacinetek"
	}

	const AppLink = buildAppLink(platform, country.isoCode)
	const ButtonTitle = platform === "google"
		? c("footer-download-google-app")
		: c("footer-download-apple-app")

	return (
		<a
			href={AppLink}
			rel="nofollow noopener"
			title={ButtonTitle}
		>
			<span className="label">
				{ ButtonTitle }
			</span>
			<figure className="default">
				{ platform === "google" &&
					<>
						{
							["de","at"].includes(country.isoCode)
							? <GooglePlayButtonDe />
							: <GooglePlayButtonFr />
						}
					</>
				}
				{ platform === "apple" &&
					<>
						{
							["de","at"].includes(country.isoCode)
							? <AppleStoreButtonDe />
							: <AppleStoreButtonFr />
						}
					</>
				}
			</figure>
		</a>
	)
}

export default DownloadAppButton
