export const canHaveMorePlaylists = (data) => {
	if (!data?.playlists?.pagination?.total) {
		return true
	}

	return data.playlists.pagination.total < 100
}

export const buildMenuFromPlaylistsData = (menu, data) => {
	if (!canHaveMorePlaylists(data)) {
		menu.children = menu.children.map(submenu => {
			if (submenu.title !== "create-playlist") {
				return submenu
			}

			if (submenu.icon && submenu.icon.includes("disabled")) {
				return submenu
			}

			submenu.icon = `${submenu.icon} disabled`

			return submenu
		})
	} else {
		menu.children = menu.children.map(submenu => {
			if (submenu.title !== "create-playlist") {
				return submenu
			}

			if (submenu.icon && !submenu.icon.includes("disabled")) {
				return submenu
			}

			submenu.icon = submenu.icon.replace(/ *disabled */, "")

			return submenu
		})
	}

	return menu
}
