import { useEffect, useState } from "react"
import times from "lodash.times"
import { useTranslation } from "lib/i18n/translation"
import { Form, Input } from "@rocketseat/unform"
import { useApolloClient } from "@apollo/react-hooks"
import { SPONSOR_MUTATION } from "lib/graphql/mutations"
import { getToken } from "lib/graphql/apollo/token"
import * as Yup from "yup"
import { useAnchorNav } from "lib/anchor-nav"
import slugify from "slugify"

const schema = (c) => {
	return Yup.object().shape({
		emails: Yup.array()
			.of(Yup.string().email()),
		message: Yup.string()
	})
}

const MySponsors = ({title}) => {

	const { c } = useTranslation("common")
	const client = useApolloClient()
	const [sending, setSending] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState(false)

	const onSubmit = (values, { resetForm }) => {
		const emails = values.emails
		const message = values.message
		setSending(true)

		client.mutate({mutation: SPONSOR_MUTATION, variables: {
			data: {
				token: getToken(),
				emails: emails.filter(e => e && e !== ""),
				message
			}
		}, context: { clientName: "admin"}})
			.then(({data: { sendSponsors: { result } } }) => {
				setSending(false)
				setError(result !== "ok")
				setSuccess(result == "ok")
				if (result == "ok") {
					resetForm()
				}
			})
	}

	const emailsCount = 10

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	useEffect(
		() => {
			setSectionLoaded( title )
		},
		[isSectionsSetted]
	)

	return (
		<section id={ slugify( c( title ) ).toLowerCase() } className="section-anchor">
			<Form schema={schema(c)} className="full" onSubmit={onSubmit} aria-label={c("sponsors-your-friends")}>
				<ol className="friends-list columnizer">
					{ times( emailsCount, (i) => (
						<li key={ `friends-item-${ i }` }>
							<span className="form-label hidden">{`${c("email-friend")} ${i+1}`}</span>
							<span className="input-container icon-guest">
								<Input type="email" placeholder={c("friend-email")} name={`emails[${i}]`} />
							</span>
						</li>
					) ) }
				</ol>
				<Input multiline name="message" placeholder={c("your-message-optional")} />
				{ error
					? <p>{c("sponsors-not-successful")}</p>
					: null
				}
				{ success
					? <p>{c("sponsors-successful")}</p>
					: null
				}
				<button type="submit" aria-label={c("send-invitations")} className={sending ? "is-loading" : ""}>{c("send-invitations")}</button>
			</Form>
		</section>
	)
}

export default MySponsors
