import image, { CATEGORY_BANNER_BIG, MOBILE_HEADER_LARGE, SVOD_LARGE } from "lacinetek-common/src/images"

export const isSubscriptionOffer = (offer) => {
	return offer.name.match(/abonnement/gi)
}

export const getOfferImage = (offer) => {
	if (offer.images.some(img => img.type === MOBILE_HEADER_LARGE)) {
		return image(offer, MOBILE_HEADER_LARGE)
	}

	if (offer.images.some(img => img.type === SVOD_LARGE)) {
		return image(offer, SVOD_LARGE)
	}

	return image(offer, CATEGORY_BANNER_BIG)
}
