import { useState, useEffect, useContext, createContext } from "react"
import { useApolloClient } from "@apollo/react-hooks"
import slugify from "slugify"

import { useCountry } from "lib/country"
import { useLanguages } from "lib/languages"
import DataFetcher from "lib/data-fetcher"
import {
	FETCH_DIRECTOR
} from "lib/graphql"

import { inListOf, inListsOf, inLifeList, isInMonthSelection, inTreasures, inMovements } from "./categories-identifier"

import { metadata, FEATURE_DIRECTOR_ID } from "lacinetek-common/src/metadata"
export * from "./categories-list"


const mainCategoriesContext = createContext()

export const MainCategoriesConsumer = mainCategoriesContext.Consumer

export const ProvideMainCategories = ({children, lists, lang}) => {
  const mainCategories = useProvideMainCategories(lists, lang)

  return (
		<mainCategoriesContext.Provider value={mainCategories}>
			{children}
		</mainCategoriesContext.Provider>
	)
}

export const useMainCategories = () => {
  return useContext(mainCategoriesContext)
}

const useProvideMainCategories = (initialLists, lang) => {
	const client = useApolloClient()
	const { country } = useCountry()
	const { current } = useLanguages()
	DataFetcher.checkLists(initialLists)
	const [lists, setLists] = useState(initialLists)
	const [currentLists, setCurrentLists] = useState(initialLists[lang])

	let ids = {}
	process.env.CATEGORIES.forEach(c => ids[c.key] = c.id)

	useEffect(() => {
		if (!lists || !lists[current]) {
			fetchLists(current)
		} else {
			setCurrentLists(lists[current])
		}
	}, [current])

	const fetchLists = (language) => {
		fetch("/fetch-lists", {
			method: "GET",
			headers: {
    		"Accept": "application/json, text/plain, */*",
				"Content-Type": "application/json",
				country: country.isoCode,
				"language": language
			}
		})
			.then(response => {
				response.json()
					.then(body => {
						setLists(old => {
							old[language] = body
							return old
						})
						setCurrentLists(body)
					})
			})
	}

	const _inListsOf = (product, director) => {
		return inListsOf(product, director, ids)
	}
	const _inListOf = product => {
		return inListOf(product, ids)
	}
	const _inLifeList = category => {
		return inLifeList(category, ids)
	}
	const _isInMonthSelection = product => {
		return isInMonthSelection(product, ids)
	}

	const directorOfList = async list => {
		if (!list) return null
		return client.query({query: FETCH_DIRECTOR, variables: {id: metadata(list, FEATURE_DIRECTOR_ID) || 559}})
			.then(({data: { cms: { directors: { items: [director] } } } }) => director)
	}

	const _inTreasures = product => {
		return inTreasures(product, ids)
	}

	const _inMovements = product => {
		return inMovements(product, ids)
	}

	return {
		inListsOf: _inListsOf,
		inListOf: _inListOf,
		inLifeList: _inLifeList,
		isInMonthSelection: _isInMonthSelection,
		inTreasures: _inTreasures,
		inMovements: _inMovements,
		directorOfList,
		lists: currentLists.lists.filter(l => !!l),
		lifeLists: currentLists.lifeLists.filter(l => !!l),
		allLists: currentLists.lists.concat(currentLists.lifeLists).filter(l => !!l)
	}
}

export const sortLists = lists => {
	return lists.filter(l => !!l).sort((a, b) => {
		if (slugify(a.lastname) == slugify(b.lastname)) return 0
		if (slugify(a.lastname) > slugify(b.lastname)) return 1
		else return -1
	})
}

export const listNames = name => {
	const altered = name.replace(" et ", ".et.").replace(" & ", ".&.").replace(", ", ".,.")
	const splitted = altered.split(' ')
	return splitted.map(s => s.replace(".et.", " et ").replace(".&.", " & ").replace(".,.", ", "))
}

export const listComponent = (category, discardNewLine=false) => {
	const names = listNames(category.name)
	return <>{names.slice(0, names.length - 1).join(" ")}{discardNewLine ? " " : <br />}<strong>{names[names.length - 1]}</strong></>
}
