import { useEffect } from "react"

import { useAuth } from "lib/auth"
import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"

const ToggleNotif = ({ mode, product }) => {

	const { toggleFilmNotification } = useAuth()
	const { hide } = useModal()
	const { c } = useTranslation("common")

	useEffect(() => {
		(async () => await toggleFilmNotification(product.id))()
	}, [])

	return (
		<>
			{mode == "off"
				? <p className="excerpt">{c("notification-removed")}</p>
				: <p className="excerpt">{c("notification-created")}</p>
			}
			<p className="centered">
				<a
					href="#"
					className="button"
					rel="nofollow noopener"
					onClick={e => {
						e.preventDefault()
						hide()
					}}
				>
					{c("close")}
				</a>
			</p>
		</>
	)
}

export default ToggleNotif
