import Query from "components/common/gql-query"
import { FETCH_BONUSES } from "lib/graphql/queries"

export default ({productId, productIds, director, type, festivalId, renderLoading, children}) => {

	let query

	if (director) {
		query = `(metadata.name.value:(id realisateur) AND metadata.value.value:(${director}))`
	} else if (productId) {
		query = `product: ${productId}`
	} else {
		query = `product: (${productIds.join(" OR ")})`
	}

	if (festivalId) {
		query += ` AND (metadata.name.value:(presentation festival) AND metadata.value.value:(${festivalId}))`
	} else if (type) {
		query += ` AND (metadata.name.value: "bonus type" AND metadata.value.value:(${type}))`
	}

	query += ` AND mediaSource: (1 OR 4)`

	return (
		<Query
			query={FETCH_BONUSES}
			options={{variables: { query }}}
			renderLoading={renderLoading}
		>
			{({cms: { extracts: { items: bonuses }}}) => children(bonuses)}
		</Query>
	)
}
