import { useQuery } from "@apollo/react-hooks" 

export default (query, options) => {
	if (!options) {
		options = {}
	}
	if (!options["context"]) {
		options["context"] = {}
	}
	options["context"]["clientName"] = "admin"
	return useQuery(query, options)
}
