export const TREASURES = "treasures"
export const HOME_ACQUISITIONS = "home_acquisitions"
export const ACQUISITIONS = "acquisitions"
export const LISTS = "lists"
export const LIFE_LISTS = "life-lists"
export const COIL = "coil"
export const SELECTION = "selection"
export const SELECTIONS = "selections"
export const TYPE = "type"
export const GENRE = "genre"
export const MOVEMENT = "movement"
export const CINECLUB = "cineclub"
export const FIGARO = "figaro"
export const PARTNERS = "partners"
