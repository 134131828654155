import { useCart } from "lib/cart"
import { useModal } from "lib/modal"
import SkeletonLink from "components/common/skeleton-link"
import { useTranslation } from "lib/i18n/translation"

export default ({}) => {

	const { cart } = useCart()
	const { hide } = useModal()
	const { c } = useTranslation("common")

	return (
		<nav className="buttons centered">
			<a title={c("cart_close")} href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" className="icon-left close">
				{cart.content.items.length > 0 ? c("cart_close") : c("close")}
			</a>
			{ cart.content.items.length > 0
				? <SkeletonLink href="/cart">
						<a title={c("checkout_cart")} onClick={(e) => { hide() }} className="icon-right with-icon-after">{c("checkout_cart")}</a>
					</SkeletonLink>
				: null
			}
		</nav>
	)
}
