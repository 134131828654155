import { useEffect, useState } from "react"
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks"
import slugify from "slugify"
import { useRouter } from "next/router"

import { useTranslation } from "lib/i18n/translation"
import { useAnchorNav } from "lib/anchor-nav"
import { useModal } from "lib/modal"
import { useToast } from "lib/toast"
import image, { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"
import { FETCH_MY_PLAYLISTS } from "lib/graphql/queries/admin-queries"
import { PLAYLIST_TOGGLE_PRODUCT, PLAYLIST_UPDATE, PLAYLIST_DELETE, PLAYLIST_MOVE_PRODUCT } from "lib/graphql/mutations/admin-mutations"
import { useLanguages } from "lib/languages"

import Image from "components/common/image"
import { Note } from "components/profile/my-playlist"
import ConfirmRemoveProduct from "components/modals/confirm-remove-product"
import ConfirmRenamePlaylist from "components/modals/confirm-rename-playlist"
import ConfirmDeletePlaylist from "components/modals/confirm-delete-playlist"
import SkeletonLink from "components/common/skeleton-link"
import handleShare from "lib/playlists/handle-share"
import { get } from "lib/redirects"
import playlistSlug from "lib/playlists/slug"
import { buildMenuFromPlaylistsData } from "lib/playlists"
import Searchbar from "./searchbar"

const Playlist = ({ id, title, playlist, products, menu: [menu, setMenu] }) => {
	const { c } = useTranslation("common")
	const { current } = useLanguages()
	const router = useRouter()
	const [currentProducts, setCurrentProducts] = useState([])
	const [IsRemovingProduct, setIsRemovingProduct] = useState(false)
	const [updatingItem, setUpdatingItem] = useState({})
	const [isRenaming, setIsRenaming] = useState(false)
	const [currentPlaylist, setCurrentPlaylist] = useState(playlist)
	const { show, hide } = useModal()
	const { show: showToast } = useToast()
	const { isSectionsSetted, setSectionLoaded } = useAnchorNav()
	const [fetchPlaylist, { data, loading }] = useLazyQuery(FETCH_MY_PLAYLISTS, {
		fetchPolicy: "cache-and-network",
		context: {
			clientName: "adminKinow"
		},
		variables: {
			includeIds: [id]
		}
	})
	const { data: playlistsData, loading: playlistsLoading, refetch: refetchPlaylists } = useQuery(FETCH_MY_PLAYLISTS, {
		fetchPolicy: "network-only",
		context: {
			clientName: "adminKinow"
		},
		variables: {
			perPage: 0
		}
	})
	const [toggleProductMutation, { error: toggleError, loading: toggleLoading, data: toggleData }] = useMutation(PLAYLIST_TOGGLE_PRODUCT, {
		context: {
			clientName: "adminKinow"
		}
	})
	const [updatePlaylistMutation, { error: playlistError, data: playlistData }] = useMutation(PLAYLIST_UPDATE, {
		context: {
			clientName: "adminKinow"
		}
	})
	const [deletePlaylistMutation, { error: deletePlaylistError, data: deletePlaylistData }] = useMutation(PLAYLIST_DELETE, {
		context: {
			clientName: "adminKinow"
		}
	})

	const [moveProductMutation, { error: moveProductError, data: moveProductData, loading: moveProductLoading }] = useMutation(PLAYLIST_MOVE_PRODUCT, {
		context: {
			clientName: "adminKinow"
		}
	})

	useEffect(() => {
		setSectionLoaded(title)
	}, [isSectionsSetted])

	useEffect(() => {
		setCurrentProducts(products)
	}, [products])

	useEffect(() => {
		if (data) {
			const ids = data.playlists.items[0].products.items.map(product => product.id)
			setCurrentPlaylist(data.playlists.items[0])
			fetch(`/fetch-films/${JSON.stringify(ids)}`)
				.then(response => response.json())
				.then(results => {
					setCurrentProducts(results)
				})
		}
	}, [data])

	useEffect(() => {
		if (!playlistsData) {
			return
		}

		setMenu({ ...buildMenuFromPlaylistsData(menu, playlistsData) })
	}, [playlistsData])

	const startSaveNoteTransaction = (note) => {
		updatePlaylistMutation({ variables: { playlist: { id, note } } })
	}

	const startDeletePlaylistTransaction = () => {
		deletePlaylistMutation({ variables: { id } })
	}

	const handleDeletePlaylist = (event) => {
		event.preventDefault()
		show({
			id: "playlist-remove",
			children: <ConfirmDeletePlaylist
				startMutation={startDeletePlaylistTransaction}
			/>,
			className: "modal short",
		})
	}

	const handleSelection = (item) => {
		setUpdatingItem(item)
		toggleProductMutation({
			variables: {
				playlistId: id,
				productId: item.id
			}
		})
	}

	const startRenamePlaylistTransaction = (name) => {
		setIsRenaming(true)
		updatePlaylistMutation({ variables: { playlist: { id, name } } })
	}

	const handleChangeTitle = (event) => {
		event.preventDefault()
		show({
			id: "playlist-film-remove",
			children: <ConfirmRenamePlaylist
				name={currentPlaylist.name}
				startMutation={startRenamePlaylistTransaction}
			/>,
			className: "modal short",
		})
	}

	const handleProductMove = (event, item, order) => {
		event.preventDefault()
		const position = currentProducts.indexOf(item)
		const newPosition = order === "up" ? position - 1 : position + 1
		if (newPosition < 0 || newPosition === currentProducts.length) {
			return
		}
		setUpdatingItem(item)
		moveProductMutation({
			variables: {
				playlistId: id,
				productId: item.id,
				position: newPosition
			}
		})
	}

	const startRemoveTransaction = (item) => {
		toggleProductMutation({ variables: { playlistId: id, productId: item.id } })
		setIsRemovingProduct(true)
	}

	const handleRemoveProduct = (event, item) => {
		event.preventDefault()
		setUpdatingItem(item)
		show({
			id: "playlist-film-remove",
			children: <ConfirmRemoveProduct
				item={item}
				startMutation={startRemoveTransaction}
			/>,
			className: "modal short",
		})
	}

	useEffect(() => {
		if (toggleData) {
			const key = IsRemovingProduct ? "playlist-product-removed-ok" : "playlist-product-added-ok"

			showToast({
				message: c(key, { name: updatingItem.name })
			})

			if (IsRemovingProduct) {
				setIsRemovingProduct(false)
				hide()
			}

			setUpdatingItem({})
			fetchPlaylist()
		}
	}, [toggleData])

	useEffect(() => {
		if (!playlistData?.mutatePlaylist) {
			return
		}

		const key = isRenaming ? "playlist-rename-ok" : "playlist-save-note-ok"

		showToast({
			message: c(key)
		})

		hide()

		const newPlaylist = { ...currentPlaylist, ...playlistData.mutatePlaylist }

		setCurrentPlaylist(newPlaylist)

		if (isRenaming) {
			const url = `/[lang]${get("/profile/playlists/[slug]")}`
			router.push(url, url.replace("[lang]", current).replace("[slug]", playlistSlug(newPlaylist)))
		}
	}, [playlistData])

	useEffect(() => {
		if (deletePlaylistData?.deletePlaylist) {
			const url = `/[lang]${get("/profile/playlists")}`
			router.push(url, url.replace("[lang]", current))
		}
	}, [deletePlaylistData])

	useEffect(() => {
		if (moveProductData?.movePlaylistProduct) {
			setUpdatingItem({})
			fetchPlaylist()
		}
	}, [moveProductData])

	useEffect(() => {
		if (toggleError) {
			const key = IsRemovingProduct ? "playlist-product-removed-nok" : "playlist-product-added-nok"
			showToast({
				message: c(key)
			})
			if (IsRemovingProduct) {
				setIsRemovingProduct(false)
				hide()
			}
			setUpdatingItem({})
			fetchPlaylist()
		}

		if (playlistError) {
			const key = isRenaming ? "playlist-rename-nok" : "playlist-save-note-nok"

			showToast({
				message: c(key)
			})
			hide()
		}

		if (deletePlaylistError) {
			showToast({
				message: c("playlist-unable-to-delete")
			})
			hide()
		}

		if (moveProductError) {
			showToast({
				message: c("playlist-product-move-nok")
			})
		}

	}, [toggleError, playlistError, deletePlaylistError, moveProductError])

	useEffect(() => {
		fetchPlaylist()
		refetchPlaylists()
	}, [router.query.slug])

	if (currentProducts.length === 0) {
		return (
			<section id={slugify(c(title)).toLowerCase()} className="section-anchor">
				<h2 className="section-title icon-playlist">{c("loading")}</h2>
			</section>
		)
	}

	return (
		<>
			<section className="focus has-splitted is-light no-fx">
				<div className="splitted large invert">
					<figure className="avatar playlist">
						<span className="icon-user"></span>
					</figure>
					<div className="content">
						<h1 className="title-alt">
							<a
								onClick={handleChangeTitle}
								rel="nofollow noopener"
								title={c("change-title")}
								data-overlay="#overlay-playlist-rename">{currentPlaylist.name} <small className="icon-pen"></small></a>
							<br />{c("by")} <strong><span dangerouslySetInnerHTML={{ __html: currentPlaylist.username }}></span></strong>
						</h1>
						<nav className="focus-nav alt" aria-label="Actions playlist">
							<a
								onClick={handleShare(c, showToast, currentPlaylist)}
								rel="nofollow noopener"
								title={c("playlist-share")}
								className="icon-share"
								data-overlay="#overlay-playlist-share">
								{c("share")}
							</a>
							<a
								onClick={handleDeletePlaylist}
								rel="nofollow noopener"
								title={c("playlist-remove")}
								className="alt icon-trash"
								data-overlay="#overlay-playlist-remove">
								{c("delete")}
							</a>
						</nav>
					</div>
				</div>
				<figure className="background"></figure>
			</section>
			<section>
				<Note c={c} value={currentPlaylist.note} mutation={startSaveNoteTransaction} />
				{currentProducts.length < 150 ? (
					<Searchbar
						handleSelected={handleSelection}
						removeSelectedProduct={(event) => { }}
						rejectedProducts={currentProducts}
					/>
				) : (
					<p>{c("max-products-by-playlist")}</p>
				)}
				<ul className="list films playlist has-comments is-draggable">
					{
						currentProducts.map((product, index) => {

							return (
								<Film
									key={`movie-${product.linkRewrite}`}
									product={product}
									c={c}
									first={index === 0}
									last={index === currentProducts.length - 1}
									handleRemoveProduct={handleRemoveProduct}
									handleProductMove={handleProductMove}
									loading={(moveProductLoading || loading) && updatingItem.id === product.id} />
							)
						})
					}
				</ul>
			</section>
		</>
	)
}

const Film = ({ product, c, first, last, handleRemoveProduct, handleProductMove, loading }) => {

	return (
		<li className="item in-selection">
			<div className="item-content">
				<figure className="cover">
					<picture>
						<Image src={image(product, PRODUCT_PHOTOGRAMME)} alt={product.name} className="cover" />
					</picture>
				</figure>
				<div className="infos">
					<SkeletonLink
						href={"/film/[id]"}
						as={`/film/${product.linkRewrite}`}
						className="film-link">
						<a title={c("see-single-film")} className="film-link">
							<h3 className="title">
								{product.name}
								{
									product.original_title
									&& <span className="original">({product.original_title})</span>
								}
							</h3>
						</a>
					</SkeletonLink>
					<div className="film-infos">
						<span className="director">{product.director}</span>
						<span className="year">{product.year}</span>
						<span className="country">{product.origin}</span>
						<span className="duration">{product.duration}</span>
						{
							loading
							&& <span className="loader"></span>
						}
					</div>
				</div>
			</div>
			<nav className="film-actions" aria-label="actions playlist">
				<a
					href="#"
					onClick={event => handleRemoveProduct(event, product)}
					rel="nofollow noopener"
					title={c("playlist-delete-this-movie")}
					data-overlay="#overlay-playlist-film-remove"
					className="icon-trash remove-from-playlist">
					{c("playlist-delete-this-movie")}
				</a>
			</nav>
			<nav className="list-actions" aria-label="actions liste">
				{
					!first
					&& <a
						href="#"
						onClick={event => handleProductMove(event, product, "up")}
						title={c("playlist-move-up")}
						className="icon-up">
						<span className="label">{c("move")}</span>
					</a>
				}
				{
					!last
					&& <a
						href="#"
						onClick={event => handleProductMove(event, product, "down")}
						title={c("playlist-move-down")}
						className="icon-down">
						<span className="label">{c("move")}</span>
					</a>
				}
			</nav>
		</li>
	)
}

export default Playlist
