import Query from "components/common/gql-query"
import { Tooltip } from "components/tooltip"
import { FETCH_PRODUCT_METADATA_AND_DIRECTORS } from "lib/graphql"
import { useTranslation } from "lib/i18n/translation"
import { getFilmNote, manageFilmVersions } from "lib/tools/product"

export const FilmNotes = ({ product }) => {

	return (
		<Query
			query={FETCH_PRODUCT_METADATA_AND_DIRECTORS}
			options={{ variables: { id: product.id } }}
			renderLoading={() => <FilmNotesContent product={product} loading={true} />}
		>
			{({ cms: { products: { items: [details] } } }) => <FilmNotesContent product={product} details={details} />}
		</Query>
	)
}

const FilmNotesContent = ({ details }) => {
	const { c } = useTranslation("common")

	const { asNote, note } = getFilmNote(details)
	const filmVersions = manageFilmVersions(details, c)
	const hasFilmVersions = !!filmVersions.find(v => v.value !== c("none"))

	if (!hasFilmVersions && !asNote) {
		return null
	}

	return (
		<div className="film-notes">
			{hasFilmVersions && (
				<ul className="film-versions columns">
					{filmVersions.map(filmVersion => (
						filmVersion.tooltipValue
							? (
								<li id={filmVersion.name} key={filmVersion.name}>
									<span className={filmVersion.name}>{c(filmVersion.name)} </span>
									<a
										href="#"
										rel="nofollow noopener"
										title={filmVersion.tooltipValue}
										onClick={e => e.preventDefault()}
										className="tooltip-toggle"
										aria-describedby={`tooltip-${filmVersion.name}`}
									>
										{c(filmVersion.value)}
									</a>
									<Tooltip
										id={`tooltip-${filmVersion.name}`}
										style={{
											width: 160,
											zIndex: 10001 // over modal
										}}
									>
										<span>
											<strong>{c(filmVersion.name)}</strong><br />{filmVersion.tooltipValue}
										</span>
									</Tooltip>
								</li>
							)
							: filmVersion.value === c("none")
								? (
									<li
										id={filmVersion.name}
										key={filmVersion.name}
										className="unavailable"
									>
										<span className={filmVersion.name}>{c(filmVersion.name)} </span>
										{c("none")}
									</li>
								)
								: (
									<li
										id={filmVersion.name}
										key={filmVersion.name}
									>
										<span className={filmVersion.name}>{c(filmVersion.name)} </span>
										{filmVersion.value}
									</li>
								)
					))}
				</ul>
			)}

			{asNote && (
				<>
					<hr />
					<p dangerouslySetInnerHTML={{ __html: note }} />
				</>
			)}
		</div>
	)
}
