import { useTranslation } from "lib/i18n/translation"
import Gift from "components/cart/gift"

export default ({pass, tiny, accessMode, remove, removing, isGift}) => {

	const { c, ec } = useTranslation("common")

	return (
		<div className="tr">
			<div className="td large">
				<div className="infos">
					<span className="title">{c("cinepass-big-infos")} {accessMode.price}€</span>
				</div>
			</div>
			<div className={`td ${tiny ? "" : "large"}`}>
				{ isGift
					? tiny
						? <div className="infos">
								<span className="icon-gift" />
							</div>
						: <Gift gift={isGift} />
					: null
				}
			</div>
			<div className="td">
				<span className="price">{accessMode.price}&nbsp;€</span>
			</div>
			<div className="td actions">
				{ remove
					? <a href="#" onClick={e => { e.preventDefault(); remove(pass, { accessMode, isGift }) }} rel="nofollow noopener" title={c("remove")} className={removing == pass.id ? "is-loading" : "remove"}>{c("remove")}</a>
					: null
				}
			</div>
		</div>
	)
}
