import { useState } from "react"

import { useTranslation } from "lib/i18n/translation"
import { CART_RULE_TYPES_SVOD, CART_TYPES_SVOD, CART_TYPES_TVOD, useCart } from "lib/cart"
import { useVideoPlayer } from "lib/video-player"
import { useAccesses } from "lib/accesses"
import { useAuth } from "lib/auth"

const PromoCode = ({ showTitle = true }) => {

	const { c } = useTranslation("common")
	const { seeFilm } = useVideoPlayer()
	const { user } = useAuth()
	const { consumeGift, consumingGift, setConsumingGift, consumePromo, consumingPromo, setConsumingPromo } = useCart()
	const { refetchSubscription, deleteAccessToProduct } = useAccesses()

	const [promoCode, setPromoCode] = useState(null)
	const [target, setTarget] = useState(null)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState(false)

	const promoCodeChange = (e) => {
		setPromoCode(e.target.value)
	}

	const apply = () => {
		if (!promoCode || promoCode == "") {
			setError(c("please-put-a-code"))
		}
		setError(null)
		setSuccess(null)
		setTarget(null)
		consumeGift(promoCode)
			.then(gift => {
				setTarget(gift)
				const [{ product }] = gift.content

				if (!product) {
					throw new Error("No product attached to gift")
				}

				console.log("GIFT", product)
				if (product.type === CART_TYPES_SVOD) {
					return refetchSubscription(user)
				}

				if (product.type === CART_TYPES_TVOD) {
					return deleteAccessToProduct(product)
				}
			})
			.then(() => {
				setSuccess(true)
				setConsumingGift(false)
			})
			.catch(e => {
				setConsumingGift(false)
				setConsumingPromo(true)
				consumePromo(promoCode)
					.then(result => {
						if (!result.success) {
							throw new Error("Promo code is invalid")
						}

						setTarget(result)

						if (result.type === CART_RULE_TYPES_SVOD) {
							return refetchSubscription(user)
						}

						return deleteAccessToProduct({ id: result.id })
					})
					.then(() => {
						setSuccess(true)
						setConsumingPromo(false)
					})
					.catch(e => {
						setError("no-gift-no-promo")
						setConsumingPromo(false)
					})
			})

	}

	return (
		<>
			{ !! showTitle && (
				<h2 className="section-title icon-favorite">{c("apply-promo-code-strong")}</h2>
			) }
			<span className="input-promo input-container icon-favorite">
				<input type="text" placeholder={c("promo-code-placeholder")} onChange={promoCodeChange} aria-label={c("enter-your-promo-code")} />
				<button onClick={apply} className={consumingGift || consumingPromo ? "disabled" : ""} aria-label={c("apply-promo-code")}>{c("apply")}</button>
			</span>
			{ error
				? <p>{c(error)}</p>
				: null
			}
			{ consumingGift || consumingPromo
				? <p className="centered"><div className="is-loading" /></p>
				: null
			}
			{ success
				? target.content && target.content.find(c => c.product.type == "TVOD")
					? <>
							<p className="excerpt">
								{c("your_account_have_been_credited", { target: "" })}
							</p>
							<ul>
								{ target.content.map(cont => (
										<li>
											{cont.product.name}
											{ cont.product.type == "TVOD"
												? <a
													href="#"
													onClick={async (e) => {
														e.preventDefault();
														waitAccessToProduct(cont.product)
															.then(() => seeFilm(cont.product))
													}}
													rel="nofollow noopener"
												>
													{c("watch-the-movie")}
												</a>
												: null
											}
										</li>
								))}
							</ul>
						</>
					: <p className="excerpt">
							{c("your_account_have_been_credited_with", { target: target.content ? target.content.map(c => c.product.name).join(", ") : c(target.name) })}
						</p>
				: null
			}
			{ success && target.message
				? <div className="quote large">
						<blockquote>{target.message}</blockquote>
					</div>
				: null
			}
		</>
	)
}

export default PromoCode
