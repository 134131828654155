import { useTranslation } from "lib/i18n/translation"
import moment from "moment"
import { useModal } from "lib/modal"
import { useAuth } from "lib/auth"
import RemoveScreen from "components/modals/remove-screen"
import { useEffect } from "react"
import { useAnchorNav } from "lib/anchor-nav"
import slugify from "slugify"

const MyScreens = ({title}) => {

	const { c } = useTranslation("common")
	const { show } = useModal()
	const { user: { devices } } = useAuth()

	const remove = (device) => {
		show({
			id: "remove-screen",
			children: <RemoveScreen device={device} />,
			className: "modal"
		})
	}

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	useEffect(
		() => {
			if( devices ) {
				setSectionLoaded( title )
			}
		},
		[isSectionsSetted, devices]
	)

	return (
		<section id={ slugify( c( title ) ).toLowerCase() } className="section-anchor">
			<h2 className="section-title icon-screen">{c("my-screens-strong")}</h2>
			{ !devices
				? <div className="is-loading" />
				: devices.length == 0
					? <div className="description">
							{c("dont-have-device-here-yet")}
						</div>
					: <div className="table">
							<div className="thead">
								<div className="tr">
									<div className="td"><span>{c("name")}</span></div>
									<div className="td"><span>{c("type")}</span></div>
									<div className="td"><span>{c("last-used")}</span></div>
									<div className="td"><span>{c("actions")}</span></div>
								</div>
							</div>
							<div className="tbody">
								{ devices.map((device, index) => (
									<div key={ `device-item-${ index }` } className="tr">
										<div className="td"><span>{device.application} / {device.os}</span></div>
										<div className="td"><span>{device.type}</span></div>
										<div className="td"><span>{moment(device.dateUpd).format("DD/MM/YYYY hh:mm:ss")}</span></div>
										<div className="td actions">
											<a href="#" onClick={e => { e.preventDefault(); remove(device) }} rel="nofollow noopener" title={c("remove")} className="remove">{c("remove")}</a>
										</div>
									</div>
								))}
							</div>
							<div className="tfoot">
							</div>
						</div>
			}
		</section>
	)
}

export default MyScreens
