import { useState, useEffect } from "react"
import { useMutation } from "@apollo/react-hooks"
import { useRouter } from "next/router"

import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { CREATE_PLAYLIST } from "lib/graphql/mutations/admin-mutations"
import { get, replace } from "lib/redirects"
import playlistSlug from "lib/playlists/slug"
import { useLanguages } from "lib/languages"

import { SearchBar } from "components/profile/my-playlist"

const CreatePlaylistModal = () => {
	const { c } = useTranslation("common")
	const { hide } = useModal()
	const { current } = useLanguages()
	const router = useRouter()
	const [playlist, setPlaylist] = useState({
		name: "",
		selectedItem: null
	})
	const [isSubmitable, setIsSubmitable] = useState(false)
	const [createPlaylistMutation, { error, loading, data }] = useMutation(CREATE_PLAYLIST, {
		context: {
			clientName: "adminKinow"
		}
	})

	const handleName = (event) => {
		setPlaylist({
			name: event.target.value,
			selectedItem: playlist.selectedItem
		})
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		if (playlist.selectedItem && playlist.name) {
			createPlaylistMutation({
				variables: {
					playlist: {
						name: playlist.name,
						productId: parseInt(playlist.selectedItem.id)
					}
				}
			})
		}
	}

	useEffect(() => {
		if (!data) {
			return
		}

		const { createPlaylist, error } = data

		if (error) {
			throw error
		}

		if (createPlaylist) {
			const realTarget = `/[lang]${get("/profile/playlists/[slug]")}`
			router.push(realTarget, replace(realTarget, current, playlistSlug(data.createPlaylist)))
			setIsSubmitable(false)
		}
	}, [data])

	useEffect(() => {
		if (error) {
			console.log(error)
		}
	}, [error])

	const handleSelection = (item) => {
		setPlaylist({
			name: playlist.name,
			selectedItem: item
		})
	}

	const removeSelectedProduct = (event = null) => {
		if (event) {
			event.preventDefault()
		}
		setPlaylist({
			name: playlist.name,
			selectedItem: null
		})
	}

	useEffect(() => {
		setIsSubmitable(playlist.selectedItem && playlist.name.length > 0)
	}, [playlist])

	return (
		<form onSubmit={handleSubmit} role="search">
			<h2 className="overlay-title icon-playlist">{c("my-playlists")}</h2>
			<h3 className="title-alt">{c("create-new-playlist")}</h3>
			<span className="input-container icon-pen">
				<input name="name" type="text" placeholder={c("playlist-name")} value={playlist.name} onChange={handleName} maxLength={100} />
			</span>
			{
				playlist.name.length === 0
				&& <p className="form-error white-text" >{c("playlist-1-char")}</p>
			}
			<SearchBar
				className="only-desktop"
				autoCompleteInForm={true}
				handleSelected={handleSelection}
				selected={playlist.selectedItem}
				removeSelectedProduct={removeSelectedProduct}
			/>
			{
				!playlist.selectedItem
				&& <p className="form-error white-text" >{c("playlist-1-movie")}</p>
			}
			<nav className="buttons centered" aria-label="navigation création playlist">
				<a href="#" onClick={(event) => hide()} rel="nofollow noopener" title={c("cancel")} className="icon-blank close">{c("cancel")}</a>
				<button
					rel="nofollow noopener"
					title={c("create-the-playlist")}
					type={"submit"}
					onClick={handleSubmit}
					className={`icon-playlist-add ${!isSubmitable ? "disabled" : ""} ${loading ? "is-loading" : ""}`}
					disabled={!isSubmitable}>
					{c("create-the-playlist")}
				</button>
			</nav>
		</form>
	)
}

export default CreatePlaylistModal
