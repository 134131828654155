import { ProductImage } from "components/common"
import { useTranslation } from "lib/i18n/translation"
import Gift from "components/cart/gift"
import { useCountry } from "lib/country"
import { useCart } from "lib/cart"

const Subscription = ({subscription, accessMode, remove, removing, isGift, tiny}) => {

	const { c } = useTranslation("common")
	const { countries } = useCountry()
	const { subscriptions } = useCart()
	const subDescription = subscriptions.find(sub => sub.id === subscription.id)?.descriptionShort

	let isFestivalSubscription = false

	countries.forEach(country => {
		let sub = country.subscriptions.find(sub => sub.id === subscription.id)
		if (sub) {
			isFestivalSubscription = sub.festival
		}
	})

	return (
		<>
			<div className="tr table-section">
				<div className="td">
					{ isFestivalSubscription
						? <h3 className="section-title icon-ticket">{c("buy-festival-pass")}</h3>
						: <h3 className="section-title icon-selection">{c("month-selection")}</h3>
					}
				</div>
			</div>
			<div className="tr">
				<div className="td large">
					<div className="infos">
						<h3 className="title">{subscription.name}</h3>
						{
							subDescription
							&& <span className="director">{subDescription}</span>
						}
					</div>
				</div>
				<div className={`td ${tiny ? "" : "large"}`}>
					{ isGift
						? tiny
							? <div className="infos">
									<span className="icon-gift" />
								</div>
							: <Gift gift={isGift} />
						: null
					}
				</div>
				<div className="td">
					<span className="price">{accessMode.price}&nbsp;€</span>
				</div>
				<div className="td actions">
					{ remove
						? <a
								href="#"
								onClick={e => { e.preventDefault(); remove(subscription, { accessMode, isGift }) }}
								rel="nofollow noopener"
								title={c("remove")}
								className={removing == subscription.id ? "is-loading" : "remove"}
							>
								{c("remove")}
							</a>
						: null
					}
				</div>
			</div>
		</>
	)
}

export default Subscription
