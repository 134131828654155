export default {
	id: 1,
	name: "Lorem ipsum",
	products: {
		items: [
			{ cover: "" },
			{ cover: "" },
			{ cover: "" },
			{ cover: "" }
		]
	},
	description: "Lorem ipsum dolor sit amet",
	descriptionShort: "Lorem ipsum dolor sit amet",
	banner: "/static/images/default_treasure.png"
}
