import SkeletonLink from "components/common/skeleton-link"
import Products from "components/common/products"
import moment from "moment"
import { useTranslation } from "lib/i18n/translation"

import classification from "lib/tools/classification"

const isRented = (access) => {
	return access.accessMode.type === "RENT"
}

const Film = ({ film, expData }) => {
	const { c } = useTranslation("common")

	return (
		<li className={`item in-selection ${isRented(expData) ? "is-rented" : "is-bougth"}`}>
			<div className="item-content">
				<SkeletonLink href="/film/[id]" as={`/film/${film.linkRewrite}`}>
					<a title={c("see-single-film")} className="film-link">
						<div className="infos">
							<span className="title">
								{film.name}
								{classification(film)}
								{isRented(expData) && (
									<span className="user-status">
										<span className="deadline">
											{moment(expData.dateExp).isAfter(moment()) && `${moment(expData.dateExp).diff(moment(), "days", true).toFixed(0)} j`}
										</span>
									</span>
								)}
							</span>
							<span className="director">{c("by")} {film.director}</span>
							<span className="year">{film.year}</span>
							<span className="country">{film.origin}</span>
						</div>
					</a>
				</SkeletonLink>
			</div>
		</li>
	)
}

const History = ({ accesses, hasMoreToLoad }) => {
	const { c } = useTranslation("common")

	if (accesses.length == 0) {
		return (
			<div className="description">
				{c("dont-have-movie-here-yet")}
			</div>
		)
	}

	return (
		<>
			<ul className="grid list films archive">
				<Products products={accesses.map(a => a.product)} returnBaseData={false}>
					{(products, loading) => products.map((access, index) => {
						if (access.id === 9999999) return null

						return <Film
							film={products.find(p => p.id === access.id)}
							expData={accesses.find(a => a.product.id == access.id)}
							loading={loading}
							key={`film-history-${index}`}
						/>
					})}
				</Products>
			</ul>

			{hasMoreToLoad && (
				<div className="centered loader">
				</div>
			)}
		</>
	)
}

export default History
