import { getRefreshToken } from "lib/graphql/apollo/token"

export default async function openPlayer(videoId) {
	const refreshToken = getRefreshToken()

	await loadScript("https://app.noop.fr/kinow/kinow-player-web.js")

	window.kinowPlayer = window.kinowPlayer.buildVariant("lacinetek", "prod")

	try {
		const result = await window.kinowPlayer.openPlayer(refreshToken, videoId)

		if (!result) {
			window.kinowPlayer.downloadPlayer()
		}
	} catch (error) {
		console.error(error)
	}
}

const loadScript = function (url) {
	return new Promise(function (resolve, reject) {
		const script = document.createElement('script')
		script.src = url

		script.addEventListener('load', function () {
			resolve(true)
		})

		document.head.appendChild(script)
	})
}
