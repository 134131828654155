import push from "./data-layer"

const actions = {
	externalLinks: {
		clicExternalLinks(link) {
			push({
				"event": "interaction",
				"category": "externalLinks",
				"action": "clicExternalLinks",
				"label": link
			})
		}
	},

	aide: {
		clicPopInAide(page) {
			push({
				"event": "interaction",
				"category": "aide",
				"action": "clicPopInAide",
				"label": page
			})
		}
	},

	account: {
		createAccount(userId) {
			push({
				"event": "interaction",
				"category": "account",
				"action": "createAccount",
				"label": userId
			})
		}
	},

	filmFilter: {
		clicFilmFilter(oldFilters, newFilters) {
			// Do nothing if we remove filter
			if (oldFilters.length > newFilters.length) {
				return
			}

			if (!newFilters.filter || !oldFilters.filter) {
				return
			}

			const filters = newFilters.filter(newFilter => {
				return !oldFilters.find(oldFilter => oldFilter.id === newFilter.id)
			})

			const filter = filters[0] ? filters[0].name : "non défini"

			push({
				"event": "interaction",
				"category": "filmFilter",
				"action": "clicFilmFilter",
				"label": filter
			})
		}
	},

	bonusFilter: {
		clicBonusFilter(filter) {
			push({
				"event": "interaction",
				"category": "bonusFilter",
				"action": "clicBonusFilter",
				"label": filter
			})
		}
	},

	playList: {
		playListCreation(list) {
			push({
				"event": "interaction",
				"category": "playList",
				"action": "playListCreation",
				"label": list
			})
		},

		playlistsFilmAdded(film) {
			push({
				"event": "interaction",
				"category": "playList",
				"action": "playlistsFilmAdded",
				"label": film
			})
		},

		playListShared(list) {
			push({
				"event": "interaction",
				"category": "playList",
				"action": "playListShared",
				"label": list
			})
		}
	},

	bonus: {
		bonusPlayed(bonus) {
			let product = "Produit non défini"
			if (bonus?.product?.name) {
				product = bonus.product.name
			}

			let name = "Bonus non défini"
			if (bonus?.name) {
				name = bonus.name
			}

			push({
				"event": "interaction",
				"category": "bonus",
				"action": "bonusPlayed",
				"label": `${product} - ${name}`
			})
		}
	},

	teaser: {
		teaserPlayed(product) {
			push({
				"event": "interaction",
				"category": "teaser",
				"action": "teaserPlayed",
				"label": product.name
			})
		}
	},

	conversion: {
		newsletterSubscription() {
			push({
				"event": "interaction",
				"category": "conversion",
				"action": "newsletterSubscription",
				"label": "newsletterSubscription"
			})
		}
	},

	offlinePlayer: {
		installation() {
			push({
				"event": "interaction",
				"category": "offlinePlayer",
				"action": "installation",
				"label": "offlinePlayerInstallation"
			})
		}
	},

	overlay: {
		overlayPage(overlayPageName) {
			push({
				"event": "interaction",
				"category": "overlay",
				"action": "overlayPage",
				"label": overlayPageName
			})
		},
		readMore(id) {
			push({
				"event": "interaction",
				"category": "overlay",
				"action": "Clic - Edito - Lire la suite",
				"label": id
			})
		}
	},

	monthSelection: {
		clic() {
			push({
				"event": "interaction",
				"category": "Sélection du mois - Lire la suite",
				"action": "Clic"
			})
		}
	},

	subscription: {
		subscribeClic() {
			push({
				"event": "interaction",
				"category": "Page nouveautés",
				"action": "Clic - S'abonner"
			})
		},
		includedInSubscribeClic(item) {
			push({
				"event": "interaction",
				"category": "Film",
				"action": "Clic - Inclus dans l'abonnement",
				"label": item
			})
		},
		enjoyClic() {
			push({
				"event": "interaction",
				"category": "Page abonnement",
				"action": "Clic - J'en profite"
			})
		},
		overlay(product) {
			push({
				"event": "interaction",
				"category" : "Overlay abonnement",
				"action": "Affichage",
				"label": "Pour voir ce film vous devez être abonné",
				"page": {
					"filmName": product.name,
					"directorName": product.director,
					"filmId": product.id,
					"filmCountry": product.origin,
					"filmYear": product.year,
					"filmLength": product.duration,
					"filmGenre": null,
					"filmLanguage": null
				}
			})
		},
		overlayClic(product) {
			push({
				"event": "interaction",
				"category": "Overlay abonnement",
				"action": "Clic - Je m'abonne",
				"label": product.name,
				"page": {
					"filmName": product.name,
					"directorName": product.director,
					"filmId": product.id,
					"filmCountry": product.origin,
					"filmYear": product.year,
					"filmLength": product.duration,
					"filmGenre": null,
					"filmLanguage": null
				}
			})
		},
		carouselClic(item) {
			push({
				"event": "interaction",
				"category": "Page abonnement",
				"action": "Clic - " + item
			})
		},
		navigationClic(item) {
			push({
				"event": "interaction",
				"category": "Navigation",
				"action": "Clic - " + item
			})
		}
	},

	ecommerce: {
		cartProductAdded({ cart, product, options }) {
			push({
				"event": "ecommerce",
				"eventCommand": "cartProductAdded",
				"currencyCode": "EUR",
				"productList": [ productMap(
					product,
					Object.assign(
						{},
						{ accessMode: options },
						{ isGift: !!cart.content.items.find(c => c.product.id === product.id).gift }
					)
				) ]
			})
		},

		cartProductRemoved({ product, options }) {
			push({
				"event": "ecommerce",
				"eventCommand": "cartProductRemoved",
				"currencyCode": "EUR",
				"productList": [ productMap(product, options) ]
			})
		},

		categoryViewed({ offer, options }) {
			push({
				"event": "ecommerce",
				"eventCommand": "categoryView",
				"currencyCode": "EUR",
				"productList": [ offerMap(offer, options) ]
			})
		}
	}
}

function offerMap(offer, options) {
	const { isGift, price } = options
	const mapedOffer = {
		productName: offer.name,
		productId: offer.id,
		productCategory1: "Subscriptions",
		productIsGift: isGift ? "Yes" : "No",
		productUnitPrice: price,
		productQuantity: 1
	}

	return mapedOffer
}

function productMap(product, options) {
	const { isGift } = options
	const mapedProduct = {
		productName: product.name,
		productId: product.id,
		productQuantity: 1,
	}

	if (product.director) {
		Object.assign(mapedProduct, {
			filmName: product.name,
			directorName: product.director
		})
	}

	if (options) {
		let productUnitPrice = 0
		const accessModes = product.accessModes || (product.extension ? product.extension.accessModes : []) || []
		let accessMode = accessModes[0]

		if (!accessMode) {
			accessMode = accessModes.find(accessMode => accessMode.id === options.accessMode.id || accessMode.id === options.accessMode.accessMode)
		}

		if (accessMode) {
			productUnitPrice = accessMode.price
		}
		if (options.accessMode?.price) {
			productUnitPrice = options.accessMode.price
		}
		if (options.accessMode?.accessMode) {
			productUnitPrice = accessModes.find(accessMode => accessMode.id === options.accessMode.accessMode).price
		}

		let productCategory1 = ""

		if (options.accessMode.type) {
			productCategory1 = `${options.accessMode.type} ${options.accessMode.quality}`
		}
		if (product.extension?.__typename) {
			productCategory1 = product.extension.__typename
		}

		Object.assign(mapedProduct, {
			productCategory1,
			productUnitPrice
		})
	}

	mapedProduct.productIsGift = isGift ? "Yes" : "No"

	return mapedProduct
}

export function signupDataLayer(signup) {
	actions.account.createAccount(signup.user.id)
}

export function filmFilterDataLayer(oldFilters, newFilters) {
	actions.filmFilter.clicFilmFilter(oldFilters, newFilters)
}

export function cartProductAddedDataLayer({ cart, product, options }) {
	actions.ecommerce.cartProductAdded({ cart, product, options })
}

export function cartProductRemovedDataLayer({ product, options }) {
	actions.ecommerce.cartProductRemoved({ product, options })
}

export function categoryViewedDataLayer({ offer, options }) {
	actions.ecommerce.categoryViewed({ offer, options })
}

export function monthSelectionDataLayer() {
	actions.monthSelection.clic()
}

export function subscribeClicSubscriptionDataLayer() {
	actions.subscription.subscribeClic()
}

export function includedInClicSubscriptionDataLayer(item) {
	actions.subscription.includedInSubscribeClic(item)
}

export function enjoyClicSubscriptionDataLayer() {
	actions.subscription.enjoyClic()
}

export function overlaySubscriptionDataLayer(product) {
	actions.subscription.overlay(product)
}

export function overlayClicSubscriptionDataLayer(product) {
	actions.subscription.overlayClic(product)
}

export function carouselClicSubscriptionDataLayer(item) {
	actions.subscription.carouselClic(item)
}

export function navigationClicSubscriptionDataLayer(item) {
	actions.subscription.navigationClic(item)
}

export function newsletterSubscriptionDataLayer() {
	actions.conversion.newsletterSubscription()
}

export function bonusDataLayer(bonus) {
	actions.bonus.bonusPlayed(bonus)
}

export function teaserDataLayer(product) {
	actions.teaser.teaserPlayed(product)
}

export function overlayDataLayer(overlayPageName) {
	actions.overlay.overlayPage(overlayPageName)
}

export function readMore(id) {
	actions.overlay.readMore(id)
}
