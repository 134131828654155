import React, { useState, useEffect, useContext, createContext } from "react"
import { useApolloClient } from "@apollo/react-hooks"
import { i18n } from "lib/i18n"
import { useAuth }from "lib/auth"
import Router from "next/router"
import { useMutation }from "@apollo/react-hooks"
import { getWithLanguage } from "lib/redirects"
import { replace } from "lib/redirects"
import { useCountry } from "lib/country"

const languagesContext = createContext()

import {
	FETCH_LANGUAGES
} from "lib/graphql"
import {UPDATE_LANGUAGE_MUTATION }from "lib/graphql/mutations"

export const LanguageConsumer = languagesContext.Consumer

// Provider component that wraps your app and makes language object ...
// ... available to any child component that calls useLanguage().
export const ProvideLanguages = ({ children, current }) => {
	const languages = useProvideLanguages(current)

	return (
		<languagesContext.Provider value={languages}>
			{children}
		</languagesContext.Provider>
	)
}

// Hook for child components to get the language object ...
// ... and re-render when it changes.
export const useLanguages = () => {
	return useContext(languagesContext)
}

// Provider hook that creates language object and handles state
function useProvideLanguages(baseLang) {
	const client = useApolloClient()
	const {user, onSignedIn, removeOnSignedIn } = useAuth()
	const { country } = useCountry()
	let langs = []
	try {
		const { platform: { languages: { items}}} = client.readQuery({ query: FETCH_LANGUAGES })
		langs = items.filter(l => ["FR", "EN", "DE"].includes(l.isoCode))
	} catch (e) {
	}
	langs.sort((a, b) => a.isoCode == "FR" ? -1 : 1)

	const [languages, setLanguages] = useState(langs)
	const [current, setCurrent] = useState(baseLang)
	const [changing, setChanging] = useState(false)

	const [updateLanguageMutation] = useMutation(UPDATE_LANGUAGE_MUTATION)

	const changeLanguage = (lang, updateUser=true) => {
		setChanging(true)
		if (user && updateUser) {
			updateLanguageMutation({variables: { langISO: lang.toUpperCase()}})
				.then(() => {
					i18n.changeLanguage(lang)
					setTimeout(() => {
						setCurrent(lang)
						onLanguageChange(lang)
						setChanging(false)
					}, 1000)
				})
		} else {
			i18n.changeLanguage(lang)
			setTimeout(() => {
				setCurrent(lang)
				onLanguageChange(lang)
				setChanging(false)
			}, 1000)
		}
	}

	const onLanguageChange = (language) => {
		const links = getLinks(Router.pathname, Router.asPath, language)
    Router.push(links.pathname, links.asPath, { shallow: true })
	}

	const getLinks = (pName, aPath, language = current) => {
		const matches = pName.match(/\[[^\]]+\]/g)
		let pathname = pName
		let asPath = aPath
		if (matches) {
			const variables = []
			const splittedPath = pName.split("/")
			const splittedAsPath = aPath.split("/")
			matches.forEach((match, index) => {
				if (match == "[lang]") {
					variables.push(country.default_language == language ? country.isoCode : `${country.isoCode}-${language}`)
				}else {
					variables.push(splittedAsPath[splittedPath.indexOf(match)])
				}
			})
			pathname = `/[lang]${getWithLanguage(pName, language)}`
			asPath = replace(pathname, ...variables)
		}

		return { pathname, asPath }
	}

	const manageUserLanguage = (user) => {
		if (current !== user.profile.language.isoCode) {
			changeLanguage(user.profile.language.isoCode.toLowerCase(), true)
		}
	}

	useEffect(() => {
		onSignedIn("language", manageUserLanguage)
		return () => {
			removeOnSignedIn("language")
		}
	}, [])

	return {
		languages,
		regex: new RegExp(`^/(${languages.map(l => l.isoCode.toLowerCase()).join('|')})`),

		changeLanguage,
		changing,

		current,
		setCurrent,

		getLinks
	}
}

export const LocalizedInput = ({Component, name, label, multiline, placeholder, message}) => {
	const { languages, current }= useLanguages()
	return (
		<div className="input-container">
			<div className={`localized-input`}>
				<Localizer mini={true} />
				{ languages.map(language => (
						<div className={`localized-component ${language.id == current ? 'active' : ''}`}>
							<Component name={`${name}_loc_${language.id}`} label={label} multiline={multiline || false} placeholder={placeholder} />
						</div>
				))}
				{ message
					? <div className="admin-form-message">{message}</div>
					: null
				}
			</div>
		</div>
	)
}

export const LocalizeValue = (name, values) => {
	const { languages }= useLanguages()
	const result = {}
	let current
	languages.forEach(language => {
		current = values[name].find(v => v.lang == language.id || (v.langCode && v.langCode.toLowerCase() == language.isoCode.toLowerCase()))
		result[`${name}_loc_${language.id}`] = current ? current.value : ""
	})
	return result
}

export const Localizer = ({mini}) => {
	const { languages, setCurrent, current } = useLanguages()
	return (
		<div className={`admin-languages ${mini ? 'minified' : ''}`}>
			{ languages.map(language => (
					<a href="#" onClick={e => { e.preventDefault(); setCurrent(language.id) }} rel="nofollow noopener" className={current == language.id ? "active" : ""}>{language.isoCode}</a>
			))}
		</div>
	)
}

export const MergeLocales = (values, languages) => {
	let result = {}, name, lang
	Object.keys(values).forEach(key => {
		if (key.includes('_loc_')) {
			[name, lang] = key.split('_loc_')
			if (!result[name]) result[name] = []
			result[name].push({lang: parseInt(lang), langCode: languages.find(l => l.id == lang).isoCode.toLowerCase(), value: values[key]})
		} else result[key] = values[key]
	})
	return result
}
