import { useState } from "react"
import { Form, Input, Check } from "@rocketseat/unform"
import * as Yup from "yup"
import { useModal } from "lib/modal"
import { useAuth } from "lib/auth"

import { useTranslation } from "lib/i18n/translation"
import { CGV } from "components/modals"
import { signupDataLayer } from "lib/gtm/interactions"

const schema = (c) => {
	return Yup.object().shape({
		email: Yup.string()
			.email(c("email_invalid"))
			.required(c("email_required")),
		password: Yup.string()
			.min(5, c("password_5_chars"))
			.required(c("password_required")),
		passwordConfirmation: Yup.string()
     .oneOf([Yup.ref('password'), null], c("passwords_must_match")),
		lastname: Yup.string()
			.min(2, c("lastname_2_chars"))
			.required(c("lastname_required")),
		firstname: Yup.string()
			.min(2, c("firstname_2_chars"))
			.required(c("firstname_required")),
		birthdate: Yup.string()
			.min(4, c("year_of_birth_4_digits"))
			.max(4, c("year_of_birth_4_digits"))
			.matches(/^[0-9]+$/, c("year_of_birth_4_digits"))
			.required(c("year_of_birth_required")),
		cgvu: Yup.boolean()
			.oneOf([true], c("check_terms")),
		newsletter: Yup.boolean(),
	})
}

const handleSignup = async ({ values, auth }) => {
	return await auth.signup(values)
}

const SignupForm = ({}) => {

	const auth = useAuth()

	const { c } = useTranslation("common")
	const { show, hide } = useModal()

	const [birthdateChars, setBirthdateChars] = useState("")
	const [error, setError] = useState(false)

	const handleSubmit = async (values) => {
		handleSignup({values, auth}).then(signup => {
			if (signup == false) {
				setError(true)
				return
			}

			signupDataLayer(signup)
			hide()
		})
	}

	const showOverlay = (id, title, Component, className) => {
		show({
			id,
			title: c(title),
			fixedHeader: true,
			children: <Component />,
			className
		})
	}

	const onChangeBirthdate = (e) => {
		const regex = /^[0-9\b]+$/;

		if (e.target.value === '' || regex.test(e.target.value)) {
			setBirthdateChars(e.target.value)
		}
	}

	return (
		<Form schema={schema(c)} onSubmit={handleSubmit} aria-label={c("signup")}>
			<fieldset>
				<legend className="hidden">{c("to-create-account")}</legend>
				<ul className="columns">
					<li>
						<span className="form-label hidden">{c("first-name")}</span>
						<span className="input-container icon-user">
							<Input name="firstname" type="text" placeholder={c("ph_firstname")} />
						</span>
						<span className="form-label hidden">{c("name")}</span>
						<span className="input-container icon-user">
							<Input name="lastname" type="text" placeholder={c("ph_lastname")} />
						</span>
						<span className="form-label hidden">{c("year_of_birth")}</span>
						<span className="input-container icon-calendar">
							<Input name="birthdate" type="text" placeholder={c("ph_year_of_birth")} value={birthdateChars} maxLength="4" onChange={onChangeBirthdate} />
						</span>
					</li>
					<li>
						<span className="form-label hidden">{c("email")}</span>
						<span className="input-container icon-mail">
							<Input name="email" type="text" placeholder={c("ph_email")} />
						</span>
						<span className="form-label hidden">{c("password")}</span>
						<span className="input-container icon-lock-on">
							<Input name="password" type="password" placeholder={c("ph_password")} />
						</span>
						<span className="form-label hidden">{c("password-confirm")}</span>
						<span className="input-container icon-lock-on">
							<Input name="passwordConfirmation" type="password" placeholder={c("ph_password_confirm")} />
						</span>
					</li>
				</ul>
				<ul className="checkboxes list">
					<li>
						<span className="form-label hidden">{c("accept-cgvu")}</span>
						<Check name="cgvu" />
						<label className="control-label" htmlFor="cgvu">{c("accept-cgvu")}&nbsp;</label>
						<a href="#" onClick={e => { e.preventDefault(); showOverlay("cgv", "complete-cgvu", CGV) }} rel="nofollow noopener" title={c("read-cgvu")} data-overlay="#overlay-cgvu">{c("read-cgvu")}</a>
					</li>
					<li>
						<span className="form-label hidden">{c("accept-newsletter")}</span>
						<Check name="newsletter" />
						<label className="control-label" htmlFor="newsletter">{c("accept-newsletter")}</label>
					</li>
				</ul>
				<p><button type="submit" aria-label={c("create-account")} className={`icon-check ${auth.connecting ? "loader" : ""}`}>{c("create-account")}</button></p>
				{ error
					? <span>{c("signup-unable")}</span>
					: null
				}
			</fieldset>
		</Form>
	)
}

export default SignupForm
