import { useContext, createContext, useEffect, useState } from "react"
import { useLazyQuery, useApolloClient } from "@apollo/react-hooks"

import {
	FETCH_USER_BALANCE,
	FETCH_LAST_ORDER
} from "lib/graphql"
import { wait } from "lib/wait"
import { useAuth } from "lib/auth"

export const PREPAYMENT_CURRENCY = "CURRENCY"
export const PREPAYMENT_CREDIT = "CREDIT"

const billingContext = createContext()

export const ProvideBilling = ({ children }) => {
	const billing = useProvideBilling()

	return <billingContext.Provider value={billing}>{children}</billingContext.Provider>
}

export const useBilling = () => {
	return useContext(billingContext)
}

function useProvideBilling() {
	const client = useApolloClient()
	const [fetchUserBalance, { data }] = useLazyQuery(FETCH_USER_BALANCE, {
		fetchPolicy: "network-only"
	})
	const { onSignedOut, removeOnSignedOut } = useAuth()

	const [currencyBalance, setCurrencyBalance] = useState(0)
	const [creditBalance, setCreditBalance] = useState(0)

	useEffect(() => {
		onSignedOut("reset-billing", reset)

		return () => {
			removeOnSignedOut("reset-billing")
		}
	}, [])

	const reset = () => {
		setCurrencyBalance(0)
		setCreditBalance(0)
	}

	useEffect(() => {
		if (!data?.billing?.prepayment) {
			return
		}

		const currencyBalanceData = data.billing.prepayment.balance.find(({ type }) => type === PREPAYMENT_CURRENCY)

		if (currencyBalanceData) {
			setCurrencyBalance(currencyBalanceData.amount)
		}

		const creditBalanceData = data.billing.prepayment.balance.find(({ type }) => type === PREPAYMENT_CREDIT)

		if (creditBalanceData) {
			setCreditBalance(creditBalanceData.amount)
		}
	}, [data])

	const fetchLastOrder = async (cartId) => {
		const delay = 1000
		const max = 30
		let order

		for (let index = max; index > 0; index--) {
			try {
				const { data: { billing: { orders } } } = await client.query({ query: FETCH_LAST_ORDER, fetchPolicy: "network-only" })

				if (!orders || orders.items.length === 0) {
					await wait(delay)
					continue
				}

				const lastOrder = orders.items[0]

				if (cartId && lastOrder.cart.id !== cartId) {
					await wait(delay)
					continue
				}

				order = lastOrder
				break
			} catch(error) {
				throw error
			}
		}

		if (!order) {
			throw new Error("Order not found")
		}

		return order
	}

	return {
		fetchUserBalance,
		fetchLastOrder,
		currencyBalance,
		creditBalance
	}
}
