import baseProduct from "./base-product"

export default {
	name: "",
	description: "Lorem ipsum dolor sit amet.",
	image: "/static/images/default_director.png",
	metadata: [
		{ name: "", value: ""}
	],
	products: {
		items: [baseProduct, baseProduct, baseProduct, baseProduct]
	}
}
