import { useEffect } from "react"
import moment from "moment"
import slugify from "slugify"

import { useTranslation } from "lib/i18n/translation"
import { useAnchorNav } from "lib/anchor-nav"
import { useAccesses } from "lib/accesses"
import { useBilling } from "lib/billing"

import PassTickets from "static/assets/icons/pass-ticket.svg"

const MyFilms = ({title}) => {
	const { c } = useTranslation("common")
	const { subscription, isSubscriptionAnnualPlus } = useAccesses()
	const { creditBalance } = useBilling()
	const { isSectionsSetted, setSectionLoaded } = useAnchorNav()

	useEffect(
		() => {
			setSectionLoaded(title)
		},
		[isSectionsSetted]
	)

	return (
		<section id={ slugify( c( title ) ).toLowerCase() } className="section-anchor">
			<h2 className="section-title icon-ticket">{c("pack-movies-annual-subscription-strong")}</h2>
			<div className="splitted large invert">
				<figure className="object"><PassTickets title={c("pack-icon-title")} /></figure>
				<div>
					<>
						{subscription && isSubscriptionAnnualPlus(subscription) && (
							<p>{c("pack-movies-text-location")}<br />{c("starts-on")} {moment(subscription.dateAdd).format("DD/MM/YYYY")} / {c("expires-on")} {moment(subscription.dateExp).format("DD/MM/YYYY")}</p>
						)}
						<p className="offer-credit alert">{c("you-still-have")} {creditBalance || "0"} {c("film")}{creditBalance > 1 ? "s" : ""} {c("in-your-pack")}</p>
					</>
				</div>
			</div>
		</section>
	)
}

export default MyFilms
