
import isBrowser from "./is-browser"

export const getAdminApiUrl = () => {
	if (process.env.ADMIN_API_URL.includes("localhost")) {
		return !isBrowser
			? process.env.ADMIN_API_URL.replace("localhost", "api")
			: process.env.ADMIN_API_URL
	}

	return process.env.ADMIN_API_URL
}
