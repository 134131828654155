import isBrowser from "lib/tools/is-browser"

import { FETCH_PREHOME } from "lib/graphql"

import { start, stop } from "lib/tools/timer"

let products = [], filmsSections, lists, categories, randomProducts
let currentCountry

export default {
	async fetch(res, apolloClient, lang, country, cookies) {
		let result
		if (!isBrowser && res) {

			let baseData = res.baseData

			// on fait attention ici, baseData.products peut être vide :
			// c'est le cas quand on appelle cette fonction après un 1er passage dans _app@getInitialProps.
			// Car dans getInitialProps, res.baseData.products est supprimé, (visiblement?) pour des raisons de performances.
			products = baseData["products"] ? baseData["products"][lang] : null;

			result = {
				lists: {},
				catalog: res.catalog
			}

			result["lists"][lang] = {
				lists: sanitizeLists(baseData.lists[lang].lists),
				lifeLists: sanitizeLists(baseData.lists[lang].lifeLists)
			}

			start("prehome")

			const prehome = await apolloClient.query({ query: FETCH_PREHOME, context: {
				clientName: "admin",
				headers: {
					"X-Prehome": Number(cookies.prehome || 0),
					"X-Prehome-Event": Number(cookies.prehomeEvent || 0),
					"X-Prehome-Event-Id": Number(cookies.prehomeEventId || 0),
					"X-Prehome-Session": Number(cookies.prehomeSession || 0),
					"X-Country": country ? country.isoCode : "fr",
					"Accept-Language": lang
				}
			} })
			result["prehome"] = prehome.data.prehome

			stop("prehome")
		} else {
			result = {
				lists: {}
			}

			result["lists"] = lists
			result["randomProducts"] = randomProducts
		}

		currentCountry = country

		return result
	},

	checkProducts: (data) => {
		if (isBrowser && !products) products = data
	},

	checkFilmsSections: (data) => {
		if (isBrowser && !filmsSections) filmsSections = data
	},

	checkLists: (data) => {
		if (isBrowser && !lists) lists = data
	},
}

const sanitizeLists = (lists) => {
	return lists.map(list => {
		return {
			id: list.id,
			director: {
				id: list.director.id,
				images: list.director.images,
				name: list.director.name
			},
			name: list.name,
			lastname: list.lastname,
			firstname: list.firstname,
			linkRewrite: list.linkRewrite,
			isLifeList: process.env.CATEGORIES.find(c => c.key == "life-lists").id === list.parent.id,
			female: list.female
		}
	})
}
