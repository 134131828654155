import moment from "moment"
import { metadata } from "lacinetek-common/src/metadata"

import { useTranslation } from "lib/i18n/translation"
import { getMainTag } from "lib/tools/blogs"
import { styledContent } from "lib/tools/contents"
import { useCountry } from "lib/country"

import BlogCategory from "components/common/blog-category"
import { baseBlogCategory } from "components/common/skeleton"
import SkeletonLink from "components/common/skeleton-link"
import { Slider } from "components/common"
import Image from "components/common/image"

const Articles = ({ categoryId, icon, hasExtras, showAllButton }) => {
	const { country } = useCountry()
	const { c } = useTranslation("common")

	return (
		<BlogCategory
			categoryId={categoryId}
			renderLoading={() => (
				<Content category={baseBlogCategory} icon={icon} c={c} loading={true} hasExtras={hasExtras} country={country} />
			)}
		>
			{category => {
				if (category.posts.items.length == 0) {
					return null
				}

				return (
					<Content
						key={`articles-${category.id}`}
						icon={icon}
						category={category}
						country={country}
						c={c}
						showAllButton={showAllButton}
						hasExtras={hasExtras}
					/>
				)
			}}
		</BlogCategory>
	)
}

const Content = ({
	category,
	country,
	icon,
	c,
	loading,
	showAllButton,
	hasExtras
}) => {
	const filterPostByCountry = (posts) => {

		return {
			items: posts.filter(post => metadata(post, `Dispo ${country.name}`) === "Oui" || metadata(post, `Dispo ${country.name}`) === undefined)
		}
	}

	const posts = filterPostByCountry(category.posts.items)

	if (posts.items.length > 0) {

		return (
			<section>
				<h2 className={`section-title icon-${icon}`}>{styledContent(category.name)}</h2>
				<ArticlesSlider category={category} posts={posts} hasExtras={hasExtras} loading={loading} />
				{showAllButton
					? <p className={`centered ${loading ? "skeleton" : ""}`}>
						<SkeletonLink href="/blog/[id]" as={`/blog/${category.linkRewrite}`}>
							<a title={c("see-all-articles")} className="icon-file button">{c("see-all-articles")}</a>
						</SkeletonLink>
					</p>
					: null
				}
			</section>
		)
	}

	return null
}

export const ArticlesSlider = ({ category, posts, hasExtras, loading }) => (
	<Slider
		slides={posts.items}
		slideComponent={Article}
		slidePropKey="article"
		slideRelatedProps={{
			category,
			hasExtras
		}}
		slideClassName="has-extras"
		className="grid posts"
		loading={loading}
	/>
)

const Article = ({ article, category, hasExtras, loading }) => {
	const { c } = useTranslation("common")

	const tag = getMainTag(article)

	return (
		<SkeletonLink
			href="/blog/[category]/[id]"
			as={`/blog/${category.linkRewrite}/${article.linkRewrite}`}
		>
			<a title={c("read-article")} className="item-content">
				<figure className={loading ? "skeleton" : ""}>
					<picture>
						<Image src={article.cover} alt={article.title} />
					</picture>
				</figure>
				<div className={`infos${loading ? " skeleton-container" : ""}`}>
					{hasExtras && (
						<div className="post-infos">
							{tag && (
								<ul className="tags">
									<li className={loading ? "skeleton" : ""}>{tag}</li>
								</ul>
							)}
							<span className={`date${loading ? " skeleton" : ""}`}>
								{moment(article.dateIssue).format("D MMM YYYY", "fr")}
							</span>
						</div>
					)}
					<h3 className={loading ? "skeleton" : ""}>{article.title}</h3>
					{hasExtras
						? (
							<div className={`extras${loading ? " skeleton" : ""}`}>
								<p>{article.descriptionShort}</p>
							</div>
						)
						: <p>{article.descriptionShort}</p>
					}
				</div>
			</a>
		</SkeletonLink>
	)
}

export default Articles
