import { baseProduct, baseDirector, baseBonus } from "./"

export default {
	id: 999999,
	name: "2020-05_BLABLA",
	banner: "/static/images/default_selection.jpg",
	descriptionShort: "Lorem ipsum dolor sit amet",
	description: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
	products: {
		items: [
			baseProduct,
			baseProduct,
			baseProduct,
			baseProduct
		]
	},
	videos: { items: [] },
	parent: {},
	children: {
		items: [ 
			{products: { items: [baseProduct] }}, 
			{products: { items: [baseProduct] }}
		]
	},
	focus: {
		products: {
			items: [baseProduct]
		}
	},
	director: baseDirector
}
