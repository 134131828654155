import React, { useState, useEffect, useContext, createContext } from "react"
import { useModal } from "lib/modal"
import { useApolloClient } from "@apollo/react-hooks"
import { useAuth } from "lib/auth"
import { useTranslation } from "lib/i18n/translation"
import { i18n } from "lib/i18n"

import {
	FETCH_SUBSCRIPTIONS,
	FETCH_COUNTRY,
	FETCH_PLATFORM_ACCESS
} from "lib/graphql/queries"
import moment from "moment"

import FromAnotherCountry from "components/modals/from-another-country"

const countryContext = createContext()

export const CountryConsumer = countryContext.Consumer

// Provider component that wraps your app and makes country object ...
// ... available to any child component that calls useCountry().
export const ProvideCountry = ({ children, currentIsoCode, country: initialCountry, userCountry: initialUserCountry, countries, availableCountry, access, originalAccess, lang, asPath, env, subscriptions }) => {
	const country = useProvideCountry(currentIsoCode, initialCountry, initialUserCountry, countries, availableCountry, access, originalAccess, lang, asPath, env, subscriptions)

	return (
		<countryContext.Provider value={country}>
			{children}
		</countryContext.Provider>
	)
}

// Hook for child components to get the country object ...
// ... and re-render when it changes.
export const useCountry = () => {
	return useContext(countryContext)
}

const euroCountries = ["DE", "AT", "BE", "BG", "CY", "HR", "DK", "ES", "EE", "FI", "FR", "GR", "HU", "IE", "IT", "LV", "LT", "MT", "LU", "NL", "PL", "PT", "CZ", "RO", "SK", "SI", "SE", "RE", "AD", "GP", "GF", "MQ", "YT", "MC", "NC", "PF", "BL", "MF", "PM", "WF"]

// Provider hook that creates country object and handles state
function useProvideCountry(initialCurrentIsoCode, initialCountry, initialUserCountry, initialCountries, availableCountry, initialAccess, initialOriginalAccess, lang, asPath, originalEnv, readSubscriptions) {
	const client = useApolloClient()
	const { show } = useModal()
	const { c } = useTranslation("common")
	const { user, onSignedIn, onSignedOut } = useAuth()

	try {
		// Set renewable prop on subscriptions
		initialCountries.forEach(country => {
			country.subscriptions.forEach(subscription => {
				const baseSubscription = readSubscriptions.find(s => s.id == subscription.id)

				subscription.renewable = false

				if (baseSubscription) {
					subscription.renewable = baseSubscription.extension.autoRenewal
				}
			})
		})
	} catch (e) {
		console.log(e)
	}

	const [currentIsoCode, setCurrentIsoCode] = useState(initialCurrentIsoCode)
	const [countries, setCountries] = useState(initialCountries)
	const [country, setCountry] = useState(initialCountry)
	const [available, setAvailable] = useState(availableCountry)
	const [userCountry, setUserCountry] = useState(initialUserCountry)
	const [svod, setSvod] = useState(initialCountry.svod)
	const [access, setAccess] = useState(initialAccess)
	const [originalAccess, setOriginalAccess] = useState(initialOriginalAccess)
	const [env, setEnv] = useState(originalEnv)

	const currentLang = i18n.language || lang
	const [countrySlug, setCountrySlug] = useState(country.default_language == currentLang ? country.isoCode : `${country.isoCode}-${currentLang}`)

	const updateCountry = (user) => {
		if (user) {
			return fetchCountry(user.profile.address.country.isoCode.toLowerCase(), true)
		} else {
			return client.query({
				query: FETCH_PLATFORM_ACCESS,
				fetchPolicy: "network-only"
			})
				.then(({ data: { platform: { access: { country }, i18n: { country: { isoCode } } } } }) => {
					return fetchCountry(isoCode.toLowerCase(), true)
				})
		}
	}

	const fetchCountry = (isoCode, forUser = false) => {
		if (["re", "ad", "gp", "gf", "mq", "yt", "mc", "nc", "pf", "bl", "mf", "pm", "wf"].includes(isoCode.toLowerCase())) {
			isoCode = "fr"
		}
		return client.query({
			query: FETCH_COUNTRY,
			variables: { isoCode, prod: process.env.LACINETEK_ENV == "production" },
			context: { clientName: "admin" },
			fetchPolicy: "network-only"
		})
			.then(({ data: { country } }) => {
				if (forUser) {
					setUserCountry(country)
				} else {
					if (!country) {
						country = countries.find(c => c.isoCode == "fr")
					}
					const uCountry = countries.find(c => c.isoCode == userIsoCode)
					if (!uCountry) {
						uCountry = {
							...(countries.find(c => c.isoCode == "fr")),
							isoCode: userIsoCode,
							name: "Unknown"
						}
					}
					setUserCountry(uCountry)
					setCountry(country)
					setSvod(country.svod)
				}
				return true
			})
	}

	useEffect(() => {
		onSignedIn("country", updateCountry)
		onSignedOut("country", updateCountry)
	}, [])

	useEffect(() => {
		setCountrySlug(country.default_language == i18n.language ? country.isoCode : `${country.isoCode}-${i18n.language}`)
	}, [i18n.language])

	const showAnotherCountry = () => {
		show({
			id: "from-another-country",
			title: c("you-cannot-buy-from-another-country"),
			children: <FromAnotherCountry />,
			className: "modal"
		})
	}

	const isMonthlySubscription = (subscription) => {
		const countrySubscription = country.subscriptions.find(countrySubscription => subscription.id == countrySubscription.id)

		if (!countrySubscription) {
			return false
		}

		return countrySubscription.key.includes("monthly")
	}

	const isYearlySubscription = (subscription) => {
		const countrySubscription = country.subscriptions.find(countrySubscription => subscription.id == countrySubscription.id)

		if (!countrySubscription) {
			return false
		}

		return countrySubscription.key.includes("annual")
	}

	const originCountry = countries.find(c => c.isoCode == originalAccess.country.toLowerCase())

	const userCountryExists = userCountry && countries.find(c => c.isoCode == userCountry.isoCode)
	const defaultCountry = countries.find(c => c.isoCode == "fr")

	let forceSvod = false
	if (user && country.svod_white_list) {
		forceSvod = country.svod_white_list.split(";").includes(user.profile.email)
	}

	return {
		countries,
		country,
		originCountry,
		userCountryExists,
		userCountry,
		filterCountry: user ? (userCountryExists ? userCountry : defaultCountry) : country,
		inEuro: euroCountries.includes(currentIsoCode),
		svodAvailable: forceSvod || svod,
		svodAvailableSoon: !forceSvod && (svod && country.svod_from && moment(country.svod_from) > moment()),
		svodFrom: forceSvod ? null : country.svod_from,
		originalCountry: userCountry && userCountry.isoCode == country.isoCode && originCountry && originCountry.isoCode == userCountry.isoCode,
		originalAccess: access.country == originalAccess.country,
		accesses: {
			access,
			originalAccess
		},
		purchasable: !!originCountry && userCountry && country && userCountry.isoCode == country.isoCode && (available || access.purchase),
		showAnotherCountry,
		countrySlug,

		regex: new RegExp(`^/(${countries.map(country => country.isoCode.toLowerCase()).join('|')})`),
		asPath,
		env,
		isMonthlySubscription,
		isYearlySubscription
	}
}
