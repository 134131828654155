import { useState } from "react"
import { useAuth } from "lib/auth"
import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"
import moment from "moment"

export default ({device}) => {
	const { hide } = useModal()
	const { removeDevice, removingDevice, setRemovingDevice } = useAuth()
	const { c } = useTranslation("common")
	const [error, setError] = useState(false)

	const remove = () => {
		setError(false)
		removeDevice(device.id)
			.then(() => { hide() })
			.catch(() => {
				setRemovingDevice(false)
				setError(c("no-more-device-deletion"))
			})
	}

	return (
		<>
			<h2 className="overlay-title">{c("remove-a-device")}</h2>
			<p className="excerpt">{c("remove-a-device-desc")}</p>
			<p className="excerpt">{c("device-name")} : {device.application} / {device.type} ({c("device-added-at")} {moment(device.dateUpd).format("DD/MM/YYYY hh:mm:ss")})</p>
			{ error
				? <p className="centered">{error}</p>
				: null
			}
			<p className="centered">
				<a href="#" onClick={e => { e.preventDefault(); remove() }} rel="nofollow noopener" className={`button ${removingDevice ? "is-loading" : ""}`}>{c("remove-my-device")}</a>
			</p>
		</>
	)
}
