import { Query } from "components/common"
import { FETCH_KINOW_CATEGORY_VIDEO, FETCH_YOUTUBE_CATEGORY_VIDEO } from "lib/graphql"
import YouTube from "react-youtube"

export const CategoryVideoPlayer = ({ category, youtube = false }) => {
	const query = youtube ? FETCH_YOUTUBE_CATEGORY_VIDEO : FETCH_KINOW_CATEGORY_VIDEO
	const opts = {
		playerVars: {
			autoplay: 0,
		},
	}
	const renderLoading = () => {
		return (
			<div className="is-loading" style={{ position: "absolute", top: "50%", right: "50%" }} />
		)
	}


	return (
		<div className="videoplayer-container">
			<div className="videoplayer">
				<Query
					query={query}
					options={{ variables: { id: category.id } }}
					forceNetwork={true}
					renderLoading={renderLoading}
				>
					{
						({ cms: { categories: { items: [{ videos: { items } }] } } }) => {
							if (items.length === 0) {
								return null
							}

							const [{ player }] = items

							return (
								<>
									{
										youtube
											? <YouTube videoId={player.url.split('embed/')[1]} opts={opts} />
											: <iframe
												src={player ? player.url : "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4"}
												frameborder="0"
												allowfullscreen="true"
												webkitallowfullscreen="true"
												mozallowfullscreen="true"
												allow="autoplay ; fullscreen ; encrypted-media"
											/>
									}
								</>
							)
						}
					}
				</Query>
			</div>
		</div>
	)
}
