import { isBrowser } from "lib/tools"

const debug = require("debug")("lacinetek:gtm")

export default function push(dataLayer) {
	if (!isBrowser) {
		return
	}

	if (!window.dataLayer) {
		return
	}

	debug("Push dataLayer", dataLayer)
	window.dataLayer.push(dataLayer)
}
