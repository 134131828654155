import { useTranslation } from "lib/i18n/translation"
import DownloadAppButton from "components/layout/download-app-button"

export const FooterOffer = () => {
	const { c } = useTranslation()

	return (
		<div className="offers screens">
			<div>
				<span className="title">{c("display-modes-multi-device")}</span>
				<nav className="inline stores">
					<DownloadAppButton platform="apple" />
					<DownloadAppButton platform="google" />
				</nav>
			</div>
			<div>
				<span className="title">{c("svod-on-big-screen")}</span>
				<ul className="streams inline">
					<li>
						<span className="icon-android"></span>
						<span>Android TV</span>
					</li>
					<li>
						<span className="icon-apple-tv"></span>
						<span>Apple TV</span>
					</li>
					<li>
						<span className="icon-chromecast"></span>
						<span>Chromecast</span>
					</li>
					<li>
						<span className="icon-airplay"></span>
						<span>Airplay</span>
					</li>
				</ul>
			</div>
			<div>
				<span className="title">{c("download")}</span>
				<ul className="streams inline">
					<li>
						<span className="icon-download"></span>
						<span>{c("display-modes-multi-device")}</span>
					</li>
				</ul>
			</div>
		</div>
	)
}
