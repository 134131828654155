import { useEffect } from "react"
import { useTranslation } from "lib/i18n/translation"
import { useAnchorNav } from "lib/anchor-nav"
import slugify from "slugify"

export default ({title}) => {

	const { c } = useTranslation("common")

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	useEffect(
		() => {
			setSectionLoaded( title )
		},
		[isSectionsSetted]
	)

	return (
		<section id={ slugify( c( title ) ).toLowerCase() } className="section-anchor">
			<h2 className="section-title icon-reward">{c("my-rewards-strong")}</h2>
			<div className="table">
		<div className="thead">
			<div className="tr">
				<div className="td"><span>{c("total-rewards")}</span></div>
				<div className="td"><span>{c("already-converted")}</span></div>
				<div className="td"><span>{c("available")}</span></div>
				<div className="td"><span>{c("pending-validation")}</span></div>
			</div>
		</div>
		<div className="tbody">
			<div className="tr">
				<div className="td"><span>0 €</span></div>
				<div className="td"><span>0 €</span></div>
				<div className="td"><span>0 €</span></div>
				<div className="td"><span>0 €</span></div>
			</div>
		</div>
		<div className="tfoot">

		</div>
	</div>	</section>
	)
}
