import { useState, useEffect } from "react"
import { useLazyQuery } from "@apollo/react-hooks"
import * as Sentry from "@sentry/node"
import { metadata, SVOD_END_DATE } from "lacinetek-common/src/metadata"

import { pushFilms, getFilms, getFilmsSync, filmsExists } from "lib/film-fetcher"
import { useProducts } from "lib/products"
import { isBrowser } from "lib/tools"
import { useLanguages } from "lib/languages"
import { useCountry } from "lib/country"
import { FETCH_PRODUCTS_VIDEOS } from "lib/graphql"

import { baseProduct } from "components/common/skeleton"

const addEndDate = (products, productIds) => (products.map(product => {
	if (product) {
		return {
			...product,
			endDate: metadata(productIds.find(p => p.id === product.id), SVOD_END_DATE)
		}
	}

	return null
}).filter(item => !!item))

const Products = ({
	children,
	products: productIds,
	loading: externalLoading,
	returnBaseData = true,
	logNoProducts = false,
	getVideoIds = false,
	getFilmsForced = false
}) => {
	const { current } = useLanguages()
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([])
	const [videos, setVideos] = useState(null)
	const { products: baseProducts, ssrFilmsBase } = useProducts()
	const { country } = useCountry()
	const [videoQuery, { data: videoData, errorGetVideos }] = useLazyQuery(FETCH_PRODUCTS_VIDEOS, {
		variables: {
			ids: productIds.filter(p =>!!p).map(item => item.id)
		}
	})

	const updateStates = (data) => {
		setData(addEndDate(data, productIds))
		setLoading(false)
		if (getVideoIds) {
			videoQuery()
		}
	}

	useEffect(() => {
		if (loading) {
			return
		}

		if (getFilmsForced || (!externalLoading && !filmsExists(productIds.map(p => p && p.id), current))) {
			setLoading(true)
			getFilms(productIds.map(p => p && p.id), current, country.isoCode)
				.then(result => {
					updateStates(result)
				})
				.catch(e => { console.log(e) })
		} else {
			updateStates(getFilmsSync(productIds.map(p => p && p.id), current))
		}
	}, [productIds, current])

	useEffect(() => {
		if (videoData) {
			const videos = videoData.cms.products.items.map(item => {
				return {
					productId: item.id,
					videos: item.extension.videos.items
				}
			})
			setVideos(videos)
		}
	}, [videoData])

	useEffect(() => {
		if (videos) {
			const newData = data.map(item => {
				const videoIds = videos.find(video => video.productId === item.id).videos
				const newVideos = item.videos.map(vid => {
					const id = videoIds.find(target => target.name === vid.name)?.id
					return {
						...vid,
						id
					}
				})
				return {
					...item,
					videos: newVideos
				}
			})
			setData(newData)
		}
	}, [videos])

	useEffect(() => {
		if (errorGetVideos) {
			console.log(errorGetVideos)
		}
	}, [errorGetVideos])

	if (!isBrowser) {
		let notBrowserBaseProducts = baseProducts ?? []
		let products = productIds
			.map(productId => notBrowserBaseProducts.find(baseProduct => baseProduct && productId && baseProduct.id === productId.id))
			.filter(p => p !== undefined && p !== null)

		products = addEndDate(products, productIds)

		if (products.length === 0 && logNoProducts) {
			// Sentry.withScope(function (scope) {
			// 	Sentry.setExtra("product-ids", productIds)
			// 	Sentry.setExtra("base-products-length", baseProducts.length)

			// 	Sentry.captureException(new Error(`No products found on SSR`), {
			// 		tags: {
			// 			context
			// 		}
			// 	})
			// })
		}

		pushFilms(products, current, ssrFilmsBase)

		return children(products, false)
	}

	if (externalLoading) return children(productIds, externalLoading)

	return children(
		loading && returnBaseData
			? [...Array(data.length)].map((i, index) => baseProduct)
			: data,
		loading
	)
}

export default Products
