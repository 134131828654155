import { useEffect, useState } from "react"
import { useApolloClient } from "@apollo/react-hooks"
import moment from "moment"

import { useCart, SD_TYPE, HD_TYPE, RENT, BUY } from "lib/cart"
import { useTranslation } from "lib/i18n/translation"
import { FETCH_BUNDLE_PRICES, FETCH_PRODUCT_METADATA_AND_DIRECTORS } from "lib/graphql"
import { manageBundleVersions, getBundleNote } from "lib/tools/product"

import { GiftForm } from "components/forms/gift"
import GiftCountries from "components/forms/gift-countries"
import { Query } from "components/common"
import { Tooltip } from "components/tooltip"

export const AddBundleToCart = ({ product, gift, updating }) => {
	const { c } = useTranslation("common")
	const client = useApolloClient()

	const qualities = [SD_TYPE, HD_TYPE]
	const types = [RENT, BUY]

	const { addProductToCart, adding } = useCart()
	const [typesQualities, setTypesQualities] = useState([{ type: RENT, quality: null }, { type: BUY, quality: null }])

	const [accessModes, setAccessModes] = useState(null) //product.extension ? product.extension.accessModes : product.accessModes
	const [currentCountry, setCurrentCountry] = useState(null)

	const [chosen, setChosen] = useState(null)
	const [error, setError] = useState(false)

	const products = product.extension.products.items
	const videos = products.reduce((videos, product) => videos.concat(product.extension.videos.items), [])

	useEffect(() => {
		loadAccessModes()
	}, [])

	useEffect(() => {
		loadAccessModes()
	}, [currentCountry])

	const loadAccessModes = () => {
		setAccessModes(null)
		let context = {}
		if (gift) {

			if (!currentCountry) return

			context = {
				headers: {
					'x-forwarded-for': currentCountry.ip
				}
			}
		}
		client.query({ query: FETCH_BUNDLE_PRICES, variables: { id: product.id }, context, fetchPolicy: "network-only" })
			.then(({ data: { cms: { products: { items: [{ extension: { accessModes: am } }] } } } }) => {
				setAccessModes(am)
				let tqs = [], accessMode
				types.forEach(type => {
					accessMode = am.sort((a, b) => (a.quality == "HD" ? -1 : 1))[0]
					tqs.push({ type, quality: accessMode.quality })
				})
				setTypesQualities(tqs)
			})
	}

	const setQuality = (type, quality) => {
		const tqs = [...typesQualities]
		const tq = tqs.find(tq => tq.type == type)
		tq.quality = quality
		setTypesQualities(tqs)
	}

	const chooseProduct = (product, { type, quality }) => {
		setChosen({ type, quality })
		if (!gift) {
			addProductToCart(Object.assign({}, product, { accessModes: accessModes ? accessModes : product.accessModes }), { type, quality })
		}
	}

	const onGiftSubmitted = (gift) => {
		if (!chosen) {
			setError(c("gift-please-choose-product"))
		} else if (gift.dateSend && moment(gift.dateSend) < moment()) {
			setError(c("gift-date-send-to-low"))
		} else {
			setError(false)
			addProductToCart(Object.assign({}, product, { accessModes: accessModes ? accessModes : product.accessModes }), { ...chosen, gift })
		}
	}

	const handleCountryChanged = (country) => {
		setCurrentCountry(country)
	}

	const languages = videos.map(v => v.language ? v.language.isoCode : null).filter(l => l !== null)
	const isFR = (languages.filter(l => l == "FR").length == languages.length)

	// const subtitles = videos.subtitles.filter(s => s !== null)

	return (
		<>
			<h2 className="overlay-title icon-reel">{gift ? c("offer-pack") : c("watch-the-pack")}</h2>
			{gift && (
				<GiftCountries product={product} onCountryChanged={handleCountryChanged} />
			)}
			{accessModes
				? <form aria-label={c("rent-or-buy-bundle")}>
					<fieldset>
						<legend className="hidden">{c("to-buy-or-rent-bundle")}</legend>
						<ul className="offers columns has-gutter centered">
							{types.map((type, index) => {
								const currentQuality = typesQualities.find(tq => (tq.type == type)).quality
								const currentAccessMode = accessModes.find(a => (a.type == type && a.quality == currentQuality)) || { type: "RENT", quality: "SD" }
								const currentAccessModes = accessModes.filter(a => a.type == type && (!a.onlyVideos || a.onlyVideos.filter(o => o.name.includes("Freebox")).length !== a.onlyVideos.length))

								return (
									<li key={index} className="item">
										<div className="infos">
											<h3>{c(type)}</h3>
											<nav className="switch" aria-label={type == RENT ? c("rent-sd-or-hd") : c("buy-sd-or-hd")}>
												{qualities.map((q, qIndex) => {
													const accessMode = currentAccessModes.find(a => a.quality == q)

													return (
														<a
															key={`${index}-${qIndex}`}
															href="#"
															title={type == RENT ? `${c("get-type-rent")} ${q}` : `${c("get-type-buy")} ${q}`}
															onClick={e => { e.preventDefault(); setQuality(type, q) }}
															rel="nofollow noopener"
															className={`${currentQuality == q ? "active" : ""} ${(accessMode) ? "" : "disabled"}`}
														>
															{q}
														</a>
													)
												})}
											</nav>
										</div>
										<button type="submit" onClick={e => { e.preventDefault(); chooseProduct(product, { type, quality: currentQuality }) }} rel="nofollow noopener" title={c("add-to-my-cart")} aria-label={c("add-to-my-cart")} className={`button has-price ${(chosen && chosen.type == type) ? (gift ? "icon-check" : (adding ? "is-loading" : "")) : ""} ${currentAccessModes.length == 0 ? "disabled" : ""}`}>
											{currentQuality}<span className="price">{currentAccessMode.price} €</span>
										</button>
									</li>
								)
							})}
						</ul>
						{/* !hasSubtitle || (subtitles.length && subtitles.filter(s => s.lang == "EN").length == subtitles.length)
								? <p>
										{c("warning")}&nbsp;:&nbsp;
										{ !hasSubtitle
											? c("no-subtitles")
											: subtitles.length && subtitles.filter(s => s.lang == "EN").length == subtitles.length
												? c("there-is-subtitles")
												: null
										}
									</p>
								: null
							*/}
						{gift && (
							<GiftForm
								onSubmit={onGiftSubmitted}
								error={error}
								adding={adding}
							/>
						)}
						<BundleNotes product={product} />
					</fieldset>
				</form>
				: <div className="loader centered" />
			}
		</>
	)
}

const BundleNotes = ({ product }) => {

	return (
		<Query
			query={FETCH_PRODUCT_METADATA_AND_DIRECTORS}
			options={{ variables: { id: product.id } }}
			renderLoading={() => (
				<BundleNotesContent product={product} loading={true} />
			)}
		>
			{({ cms: { products: { items: [details] } } }) => (
				<BundleNotesContent product={product} details={details} />
			)}
		</Query>
	)
}

const BundleNotesContent = ({ product }) => {
	const { c } = useTranslation("common")

	const { asNote, note } = getBundleNote(product)
	const bundleVersions = manageBundleVersions(product, c)
	const hasBundleVersions = bundleVersions.find(v => v.value !== c("none"))

	if (!hasBundleVersions && !asNote) {
		return null
	}

	return (
		<div className="film-notes">
			{bundleVersions.find(v => v.value !== c("none")) && (
				<ul className="film-versions columns">
					{bundleVersions.map(filmVersion => (
						filmVersion.tooltipValue
							? (
								<li id={filmVersion.name} key={filmVersion.name}>
									<span className={filmVersion.name}>{c(filmVersion.name)} </span>
									<a
										href="#"
										rel="nofollow noopener"
										title={filmVersion.tooltipValue}
										onClick={e => e.preventDefault()}
										className="tooltip-toggle"
										aria-describedby={`tooltip-${filmVersion.name}`}
									>
										{c(filmVersion.value)}
									</a>
									<Tooltip
										id={`tooltip-${filmVersion.name}`}
										style={{
											width: 160,
											zIndex: 10001 // over modal
										}}
									>
										<span><strong>{c(filmVersion.name)}</strong><br />{filmVersion.tooltipValue}</span>
									</Tooltip>
								</li>
							)
							: filmVersion.value === c("none")
								? (
									<li id={filmVersion.name} key={filmVersion.name} className="unavailable">
										<span className={filmVersion.name}>{c(filmVersion.name)} </span>
										{c("none")}
									</li>
								)
								: (
									<li id={filmVersion.name} key={filmVersion.name}>
										<span className={filmVersion.name}>{c(filmVersion.name)} </span>
										{filmVersion.value}
									</li>
								)
					))}
				</ul>
			)}
			{asNote && (
				<>
					<hr />
					<p dangerouslySetInnerHTML={{ __html: note }} />
				</>
			)}
		</div>
	)
}
