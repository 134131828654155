import { Query } from "components/common"
import { FETCH_ORDERS } from "lib/graphql/queries"

const Orders = ({ children, renderLoading, onError }) => (
	<Query
		query={FETCH_ORDERS}
		renderLoading={renderLoading}
		options={{
			variables: {
				page: 1
			}
		}}
		onError={onError}
	>
		{(data, fetchMore) => children({
			orders: data.billing.orders.items,
			hasMoreToLoad: data.billing.orders.pagination.lastPage > data.billing.orders.pagination.page,
			fetchMore: () => {
				return fetchMore({
				variables: {
					page: data.billing.orders.pagination.page + 1
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev

					fetchMoreResult.billing.orders.items = [...prev.billing.orders.items, ...fetchMoreResult.billing.orders.items]

					return fetchMoreResult
				}
			})}
		})}
	</Query>
)

export default Orders
