import { useState, useEffect } from "react"
import { useQuery } from "@apollo/react-hooks"
import { useRouter } from "next/router"
import HtmlParser from "react-html-parser"
import slugify from "slugify"

import {
	FETCH_ACCESSES_TVOD
} from "lib/graphql"
import { useTranslation } from "lib/i18n/translation"
import { useAnchorNav } from "lib/anchor-nav"
import { RENT, BUY } from "lib/tools/product-info"

import Category from "components/profile/my-cinetek/films/category"
import History from "components/profile/my-cinetek/films/history"

const HISTORY = "history"

const MENU_ENTRIES = [
	{ key: RENT, component: Category },
	{ key: BUY, component: Category },
	{ key: HISTORY, component: History },
]

const Menu = ({ entries, current, onChange, handleDataMode, dataMode }) => {
	const { c } = useTranslation("common")

	return (
		<>
			<nav className="inline only-desktop">
				<nav data-tabs="#tabs-films-user" className="switch nav-tabs">
					{entries.map((menu, index) => (
						<a
							href="#"
							onClick={e => { e.preventDefault(); onChange(menu) }}
							rel="nofollow noopener"
							title={c(`my-films-menu-${menu.key}`)}
							className={current.key == menu.key ? "active" : ""}
							key={`my-films-menu-${index}`}
						>
							{c(`my-films-menu-${menu.key}`)}
						</a>
					))}
				</nav>
				<nav data-grid="#grid-films-user" className="mode switch is-last">
					<a
						href="#"
						onClick={e => { e.preventDefault(); handleDataMode('') }}
						rel="nofollow noopener"
						title={c("display-grid")}
						data-mode="grid"
						className={dataMode === '' ? 'active' : ''}
					>
						{c("display-grid")}
					</a>
					<a
						href="#"
						onClick={e => { e.preventDefault(); handleDataMode('_min list') }}
						rel="nofollow noopener"
						title={c("display-list")}
						data-mode="list"
						className={dataMode !== '' ? 'active' : ''}
					>
						{c("display-list")}
					</a>
				</nav>
			</nav>
			<nav className="only-mobile" aria-label="Sélectionner l'onglet (mobile)">
				<span className="select-container switch nav-tabs">
					<select
						onChange={e => { e.preventDefault(); onChange(entries[e.target.value]) }}
						aria-label="Sélectionner l'onglet"
					>
						{entries.map((menu, index) => (
							<option
								value={index}
								key={`my-films-menu-${index}`}
							>
								{c(`my-films-menu-${menu.key}`)}
							</option>
						))}
					</select>
				</span>
			</nav >
		</>
	)
}

const MyFilms = ({ title }) => {
	const { c } = useTranslation("common")
	const router = useRouter()
	const { isSectionsSetted, setSectionLoaded } = useAnchorNav()

	const [current, setCurrent] = useState(MENU_ENTRIES[0])
	const [hasMoreToLoad, setHasMoreToLoad] = useState(true)
	const [currentData, setCurrentData] = useState([])
	const [dataMode, setDataMode] = useState('')
	const [page, setPage] = useState(1)

	const { data, error } = useQuery(FETCH_ACCESSES_TVOD, {
		fetchPolicy: "cache-first",
		variables: {
			page,
			perPage: 8,
			query: "type: TVOD"
		}
	})

	useEffect(() => {
		if (!data) {
			return
		}

		const { items, pagination: { page, lastPage } } = data.user.accesses

		setCurrentData([ ...currentData, ...items ])

		if (page < lastPage) {
			setTimeout(() => {
				setPage(page + 1)
			}, 200)
		} else {
			setHasMoreToLoad(false)
		}
	}, [data])

	useEffect(() => {
		if (router.query.accessType === "buy") {
			setCurrent(MENU_ENTRIES[1])
		}
	}, [router])

	useEffect(() => {
		setSectionLoaded(title)
	}, [isSectionsSetted])

	if (error) {
		return <p className="infos" >{c("error-thrown")}</p>
	}

	return (
		<section id={slugify(c(title)).toLowerCase()} className="section-anchor">
			<h2 className="section-title icon-chair">{HtmlParser(c("my-available-films"))}</h2>
			<Menu
				current={current}
				dataMode={dataMode}
				entries={MENU_ENTRIES}
				handleDataMode={(mode) => setDataMode(mode)}
				onChange={(target) => setCurrent(target)}
			/>
			<ul id="tabs-films-user" className="tabs">
				{MENU_ENTRIES.map((menu, index) => (
					<li
						className={`tab ${menu.key === current.key && "active"}`}
						key={`menu_entry-${index}`}
					>
						<menu.component
							accesses={currentData}
							dataMode={dataMode}
							hasMoreToLoad={hasMoreToLoad}
							menuKey={current.key}
						/>
					</li>
				))}
			</ul>
		</section>
	)
}

export default MyFilms
