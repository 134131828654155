import { useState } from "react"
import * as Yup from "yup"
import { Form, Input } from "@rocketseat/unform"
import classNames from "classnames"

import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"

const schema = (c) => {
	return Yup.object().shape({
		firstname: Yup.string()
			.required(c("firstname_required")),
		lastname: Yup.string()
			.required(c("lastname_required")),
		email: Yup.string()
			.required(c("email_required"))
			.email(c("email_invalid")),
		message: Yup.string()
			.required(c("message_required"))
	})
}

export const GiftForm = ({ gift, onSubmit, error, adding }) => {
	const { c } = useTranslation("common")
	const { hide } = useModal()
	const [firstnameChars, setFirstnameChars] = useState(0)
	const [lastnameChars, setLastnameChars] = useState(0)
	const [messageChars, setMessageChars] = useState(0)

	const handleSubmit = async (values, { resetForm }) => {
		onSubmit(values)
	}

	const countChars = (e) => {
		switch (e.target.name) {
			case "firstname":
				setFirstnameChars(e.target.value.length)
				break
			case "lastname":
				setLastnameChars(e.target.value.length)
				break
			case "message":
				setMessageChars(e.target.value.length)
				break
		}
	}

	return (
		<>
			<h3>{c("beneficiary-informations")}</h3>
			<Form
				schema={schema(c)}
				onSubmit={handleSubmit}
				initialData={gift || {}}
				aria-label={c("gift-information")}
			>
				<span className="form-label hidden">
					{c("first-name")}
				</span>
				<span className="input-container icon-user">
					<Input
						name="firstname"
						type="text"
						placeholder={c("first-name")}
						maxLength="32"
						onChange={countChars}
					/>
				</span>
				<p className="form-error">
					<span className="form-counter">
						{firstnameChars}
					</span>
					<span className="form-total">/32</span>
				</p>
				<span className="form-label hidden">
					{c("name")}
				</span>
				<span className="input-container icon-user">
					<Input
						name="lastname"
						type="text"
						placeholder={c("name")}
						maxLength="32"
						onChange={countChars}
					/>
				</span>
				<p className="form-error">
					<span className="form-counter">
						{lastnameChars}
					</span>
					<span className="form-total">/32</span>
				</p>
				<span className="form-label hidden">
					{c("email")}
				</span>
				<span className="input-container icon-mail">
					<Input
						type="email"
						placeholder={c("email")}
						name="email"
					/>
				</span>
				<span className="form-label hidden">
					{c("your-message")}
				</span>
				<Input
					type="text"
					multiline
					name="message"
					placeholder={c("your-message-with-length")}
					maxLength="200"
					onChange={countChars}
				/>
				<p className="form-error">
					<span className="form-counter">
						{messageChars}
					</span>
					<span className="form-total">/200</span>
				</p>
				{error
					? <>
						<hr className="blank"></hr>
						<p>{error}</p>
					</>
					: null
				}
				<nav className="buttons small centered">
					<a
						href="#"
						rel="nofollow noopener"
						onClick={e => { e.preventDefault(); hide() }}
						title={c(`cancel${gift ? "-update" : ""}`)}
						className={classNames(
							"btn small icon-blank close",
							{ "disabled": adding }
						)}
					>
						{c(`cancel${gift ? "-update" : ""}`)}
					</a>
					<button
						type="submit"
						aria-label={gift ? c("save-changes") : c("add-to-cart")}
						className={classNames(
							"btn small close",
							{ "is-loading": adding },
							{ "icon-check": gift },
							{ "icon-cart": !gift }
						)}
					>
						{c(gift ? "save-changes" : "add-to-cart")}
					</button>
				</nav>
			</Form>
		</>
	)
}
