import { useEffect, useState } from "react"
import { useTranslation } from "lib/i18n/translation"
import image, { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"
import Image from "components/common/image"

const MAX_RESULTS = 5

export default ({ handleSelected, selected, removeSelectedProduct, rejectedProducts = [] }) => {

	const [query, setQuery] = useState("")
	const [results, setResults] = useState({ products: [] })
	const [displayed, setDisplayed] = useState(false)
	const { c } = useTranslation("common")
	const [typeTimeout, setTypeTimeout] = useState(null)
	const inputSearch = React.createRef()
	const rejectedProductIds = rejectedProducts.map(product => product.id)

	const handleSearch = (e) => {
		if (typeTimeout) {
			clearTimeout(typeTimeout)
		}
		const q = inputSearch.current.value

		setQuery(q)
		setTypeTimeout(setTimeout(() => {
			if (q !== "") {
				fetch(`/fetch-search/${q}`)
					.then(response => {
						response.json()
							.then(data => {
								setResults({
									products: data.products.filter(product => !rejectedProductIds.includes(product.id))
								})
								setDisplayed(true)
							})
					})
			} else {
				setResults({ products: [] })
				setDisplayed(false)
				removeSelectedProduct()
			}
		}, 500))
	}

	const handleRemoveSelectedProduct = (event) => {
		event.preventDefault()
		setDisplayed(true)
		removeSelectedProduct()
	}

	const handleSelection = (event, item) => {
		event.preventDefault()
		// setResults({ products: [] })
		setDisplayed(false)
		handleSelected(item)
	}

	const handleSearchKeys = event => {
		if ([ 38, 40, 13 ].indexOf(event.keyCode) === -1) {
			return
		}

		if (event.keyCode === 13) {
			const selectedProduct = results.products.find(product => product.selected)

			if (selectedProduct) {
				handleSelection(event, selectedProduct)
				return
			}
		}

		event.preventDefault()

		const items = results.products.slice(0, MAX_RESULTS)

		if (items.length === 0) {
			return
		}

		let direction = "forward"
		if (event.keyCode === 38) {
			direction = "backward"
		}

		const selectedIndex = items.findIndex(item => item.selected)

		// Select first if no result is selected and we going forward
		if (selectedIndex === -1 && direction === "forward") {
			items[0].selected = true
		}

		// Select last if no result is selected and we going backward
		if (selectedIndex === -1 && direction === "backward") {
			items[items.length - 1].selected = true
		}

		// Select backward
		if (selectedIndex !== -1 && direction === "backward") {
			delete(items[selectedIndex].selected)
			if (selectedIndex > 0) {
				items[selectedIndex - 1].selected = true
			}
		}

		// Select forward
		if (selectedIndex !== -1 && direction === "forward") {
			delete(items[selectedIndex].selected)
			if (selectedIndex < items.length - 1) {
				items[selectedIndex + 1].selected = true
			}
		}

		setResults({
			products: results.products
		})
	}

	useEffect(() => {
		if( displayed ) {
			window.addEventListener("keydown", handleSearchKeys)

			return () => {
				window.removeEventListener("keydown", handleSearchKeys)
			}
		} else {
			window.removeEventListener("keydown", handleSearchKeys)
		}
	}, [ results, displayed ])

	return (
		<>
			<span className="input-container icon-search">
					{ selected
						&& <a
								href="#"
								rel="nofollow noopener"
								title={c("playlist-delete-movie")}
								className="input-tag icon-close"
								onClick={handleRemoveSelectedProduct}>
							<span>{selected.name}</span>
						</a>
					}
					<input type="text" name={"playlist-search"} placeholder={c("playlist-add-movie")} aria-label={c("playlist-add-movie")} onChange={handleSearch} autoComplete="off" ref={inputSearch} />
						{ displayed
							&& <Autocompletion
							query={query}
							products={results.products}
							handleSelection={handleSelection}
							visible={displayed}
							c={c}
						/>}

				</span>
		</>
	)
}

const Autocompletion = ({ products, c, handleSelection, visible }) => {

	return (
		<>
			{ !products.length
				? <div className={`autocompletion`}>
						<span className="title">{c("search-no-result")}</span>
					</div>
				: <div className={`autocompletion ${visible ? "visible" : "hidden"} `}>
					<h2 className="hidden">Autocompletion</h2>
					{ products.length
						? <>
								<ul className="grid min films">
									{ products.slice(0, MAX_RESULTS).map((product, index) => (
										<Film product={product} c={c} key={`search-product-${index}`} handleSelection={handleSelection} />
									))}
								</ul>
							</>
						: null
					}
				</div>
			}
		</>
	)
}

export const Film = ({ product, c, handleSelection }) => {

	return (
		<li className={`item in-selection ${product.selected ? "item-selected" : ""}`}>
			<div className="item-content">
				<a href="#" onClick={event => handleSelection(event, product)} title={c("playlist-select-movie")} className="more-link"><span className="label">{c("playlist-select-movie")}</span></a>
				<div className="film-link">
					<figure className="cover">
							<picture>
								<Image src={image(product, PRODUCT_PHOTOGRAMME)} alt={product.name} />
							</picture>
						</figure>
						<div className="infos">
							<span className="title">{product.name}</span>
							<span className="director">{c("by")} {product.director}</span>
						</div>
				</div>
			</div>
		</li>
	)
}
