import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { useCountry } from "lib/country"

export default ({}) => {

	const { c } = useTranslation("common")
	const { hide } = useModal()
	const { country, userCountry } = useCountry()

	return (
		<div>
			<p>
				{c("foreigner-cant-buy", {country: c(country.name), userCountry: c(userCountry.name)})}
			</p>
			<p><a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" title="OK" className="button small close icon-check">OK</a></p>
		</div>
	)
}
