import { ApolloClient, InMemoryCache } from "apollo-boost"
import { ApolloLink } from "apollo-link"
import { createHttpLink } from "apollo-link-http"
import { setContext } from "apollo-link-context"

import { onError } from "apollo-link-error"
import * as Sentry from "@sentry/node"

import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory"
import introspectionQueryResultData from "./fragmentTypes.json"

import { getToken } from "./token"

import fetch from "isomorphic-unfetch"

import { Cookies } from "react-cookie"
import { ADMIN_AUTH_TOKEN, AUTH_TOKEN } from "lib/auth"

import { i18n } from "lib/i18n"

import isBrowser from "lib/tools/is-browser"
import { getAdminApiUrl } from "lib/tools/urls"

let apolloClient = null

function create (initialState, adminToken, lang, clientIP, token) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
	const kinowLink = createHttpLink({
		uri: process.env.KINOW_API_URL,
		credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
		// Use fetch() polyfill on the server
		fetch: !isBrowser && fetch
	})

	const adminLink = createHttpLink({
		uri: getAdminApiUrl(),
		credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
		fetch: !isBrowser && fetch
	})

	const errorLink = onError(({graphQLErrors, networkError, operation}) => {
		// console.log(operation)
		if (graphQLErrors)
			graphQLErrors.forEach(err => {
				const { message, locations, path } = err
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			})
		if (networkError){
			console.log(`[Network error]: ${networkError}`);
			Sentry.captureException(networkError)
		}
		// Sentry.withScope(function(scope) {
		// 	Sentry.setExtra("operation", JSON.stringify(operation))
		// 	if (graphQLErrors) {
		// 		Sentry.setExtra("graphQLErrors", JSON.stringify(graphQLErrors))
		// 	}
		// 	if (networkError) {
		// 		Sentry.setExtra("networkError", JSON.stringify(networkError))
		// 	}

		// 	Sentry.captureException(new Error(`A graphql error occured on error ${operation.operationName}`))
		// })

		/*
		console.log("ERROR")
		console.log(graphQLErrors)
		if (graphQLErrors && graphQLErrors.result) {
			console.log(graphQLErrors.result.errors)
		}
		console.log(networkError)
		if (networkError && networkError.result) {
			console.log(networkError)
			console.log(networkError.result.errors)
		}
		*/
	})

	const adminContext = setContext((_, { headers }) => {
		const t = adminToken || (new Cookies()).get(ADMIN_AUTH_TOKEN)

		return {
			headers: {
				...headers,
				language: lang || i18n.language,
				authorization: t ? `Bearer ${t}` : "",
			}
		}
	})

	const adminKinowContext = setContext((_, { headers }) => {
		const t = isBrowser ? getToken() : token

		return {
			headers: {
				...headers,
				language: lang || i18n.language,
				authorization: t ? `Bearer ${t}` : "",
			}
		}
	})

	const authContext = setContext((_, { headers }) => {
		const t = token || getToken() || null

		headers = {
			...headers,
			authorization: t ? `Bearer ${t}` : "",
			"accept-language": (headers && headers["accept-language"]) || i18n.language || lang,
		}

		if (clientIP && !headers["x-forwarded-for"]) {
			if (process.env.NODE_ENV == "development") {
				clientIP = process.env.X_FORWARDED_FOR
			} else {
				clientIP = clientIP.split(':')
				clientIP = clientIP[clientIP.length - 1]
			}
			//console.log("FORWARD FOR : " + clientIP)
			headers = {
				...headers,
				"x-forwarded-for": clientIP
			}
		}

		return {
			headers
		}
	})

	const anonymContext = setContext((_, { headers}) => {
		headers = {
			...headers,
			"accept-language": (headers && headers["accept-language"]) || i18n.language || lang,
		}

		return {
			headers
		}
	})

	const fragmentMatcher = new IntrospectionFragmentMatcher({
		introspectionQueryResultData
	})

  return new ApolloClient({
    connectToDevTools: false, //isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
		link: ApolloLink.split(
			operation => (operation.getContext().clientName === "anonym"),
			anonymContext.concat((errorLink).concat(kinowLink)),
			ApolloLink.split(
				operation => (operation.getContext().clientName === "admin" ||  operation.getContext().clientName === "adminKinow"),
				ApolloLink.split(
					operation => operation.getContext().clientName === "admin",
					adminContext.concat(errorLink.concat(adminLink)),
					adminKinowContext.concat(errorLink.concat(adminLink))
				),
				authContext.concat(errorLink.concat(kinowLink))
			)),
		//link: authLink.concat(kinowLink),
    cache: new InMemoryCache({fragmentMatcher}).restore(initialState || {})
  })
}

export default function initApollo (initialState, adminToken, lang, clientIP, token, doNotKeep) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
	if (typeof window === 'undefined') {
		return create(initialState, adminToken, lang, clientIP, token)
	}

  // Reuse client on the client-side
	if (doNotKeep) return create(initialState, adminToken, lang, clientIP, token)

  if (!apolloClient) {
	  apolloClient = create(initialState, adminToken, lang, clientIP, token)
  }

  return apolloClient
}
