import { getPeople } from "lacinetek-common/src/models/product"
import {
	metadata,

	FEATURE_BONUS_TYPE,

	FEATURE_STATUS_COPY,
	FEATURE_FORMAT_VIDEO,
	FEATURE_SHOOTING_SUPPORT,
	FEATURE_ORIGINAL_SOUND,
	FEATURE_FORMAT_AUDIO,
	FEATURE_RELEASE_DATE_FRANCE,
	FEATURE_PRODUCTION_YEAR,
	FEATURE_TYPE_FILM,
	FEATURE_COLORS,
	FEATURE_LANG,
	FEATURE_SCENARIO,
	FEATURE_PHOTO,
	FEATURE_MONTAGE,
	FEATURE_BO,
	FEATURE_PRODUCTOR,
	FEATURE_PRODUCTION_FIRM,
	FEATURE_RIGHTS,
	FEATURE_SUBTITLES,
	FEATURE_RESTORED_VERSION,
	FEATURE_NOTE_FILM,
	FEATURE_VERSION_AUDIO,
	FEATURE_VERSION_DUBBING,
	FEATURE_VERSION_SUBTITLES,
	FEATURE_EMBEDDED_SUBTITLES,
	FEATURE_VERSION_SME,
	FEATURE_BUNDLE_SUBTITLES,
	FEATURE_BUNDLE_SUBTITLES_SME,
	FEATURE_BUNDLE_NOTE
} from "lacinetek-common/src/metadata"

export const BONUS_ARCHIVES = "Archive"
export const BONUS_EXCLUSIFS = "Exclusif"
export const BONUS_TRAILER = "Bande-annonce"
export const BONUS_EXTRACT = "Extrait"

export const getTrailers = product => {
	return product.extension.extracts.items.filter(i => {
		return metadata(i, FEATURE_BONUS_TYPE) == BONUS_TRAILER
	})
}
export const getExtracts = product => {
	return product.extension.extracts.items.filter(i => {
		return metadata(i, FEATURE_BONUS_TYPE) == BONUS_EXTRACT
	})
}
export const getBonuses = (product, type) => {
	if (type) {
		return product.extension.extracts.items.filter(i => {
			return metadata(i, FEATURE_BONUS_TYPE) == type
		})
	} else {
		return product.extension.extracts.items.filter(i => {
			return metadata(i, FEATURE_BONUS_TYPE) && [BONUS_ARCHIVES, BONUS_EXCLUSIFS].includes(metadata(i, FEATURE_BONUS_TYPE))
		})
	}
}
export const getVideos = (product) => {
	return product.extension.extracts.items.filter(i => {
		return metadata(i, FEATURE_BONUS_TYPE) && ![BONUS_ARCHIVES, BONUS_EXCLUSIFS].includes(metadata(i, FEATURE_BONUS_TYPE))
	})
}

export const hasBonus = (product, type) => {
	return getBonuses(product, type).length > 0
}
export const hasTrailer = product => {
	return getTrailers(product).length > 0
}
export const hasExtract = product => {
	return getExtracts(product).length > 0
}
export const hasFilm = product => {
	return getVideos(product).length > 0
}

export const getDirector = (film) => {
	return film.extension.directors.items.map(director => `${director.director.name} `)
}

const mapTechnicals = (product) => ({
	support: metadata(product, FEATURE_SHOOTING_SUPPORT),
	dateFrance: metadata(product, FEATURE_RELEASE_DATE_FRANCE),
	productionYear: metadata(product, FEATURE_PRODUCTION_YEAR),
	filmType: metadata(product, FEATURE_TYPE_FILM),
	colors: metadata(product, FEATURE_COLORS),
	lang: metadata(product, FEATURE_LANG),
	scenario: metadata(product, FEATURE_SCENARIO),
	photo: metadata(product, FEATURE_PHOTO),
	montage: metadata(product, FEATURE_MONTAGE),
	bo: metadata(product, FEATURE_BO),
	productor: metadata(product, FEATURE_PRODUCTOR),
	firm: metadata(product, FEATURE_PRODUCTION_FIRM),
	right: metadata(product, FEATURE_RIGHTS),
	subtitles: metadata(product, FEATURE_SUBTITLES)
})

const videoFormats = {
	"copy": FEATURE_STATUS_COPY,
	"videoFormat": FEATURE_FORMAT_VIDEO,
	"sound": FEATURE_FORMAT_AUDIO
}

export const mapPeople = (product) => {

	let people = [], pr
	const productPeople = getPeople(product)
	productPeople.forEach(({director, roles}) => {
		roles.forEach(role => {
			pr = people.find(pe => pe.role == role)
			if (!pr) {
				pr = { role, people: [] }
				people.push(pr)
			}
			pr.people.push(director.name)
		})
	})
	people = people.sort(sortPeople)
	people.forEach((pe) => {
		pe.role = getRoleTranslation(pe.role)
	})
	return people
}

const PEOPLE_DIRECTOR="Réalisateur"
const PEOPLE_SCENARIO="Scénario"
const PEOPLE_DIALOGS="Dialogues"
const PEOPLE_PHOTO_DIRECTOR="Directeur de la photographie"
const PEOPLE_DECOR="Décors"
const PEOPLE_COSTUMES="Costumes"
const PEOPLE_MONTAGE="Montage"
const PEOPLE_BO="Musique originale"
const PEOPLE_SOUND_ENGINEER="Ingénieur du son"
const PEOPLE_PRODUCTOR="Producteur"
const PEOPLE_PRODUCTION="Société de production"
const PEOPLE_RIGHTHOLDERS="Ayant droit"

const CASTING = [
	PEOPLE_DIRECTOR,
	PEOPLE_SCENARIO,
	PEOPLE_DIALOGS,
	PEOPLE_PHOTO_DIRECTOR,
	PEOPLE_DECOR,
	PEOPLE_COSTUMES,
	PEOPLE_MONTAGE,
	PEOPLE_BO,
	PEOPLE_SOUND_ENGINEER,
	PEOPLE_PRODUCTOR,
	PEOPLE_PRODUCTION,
	PEOPLE_RIGHTHOLDERS
]

const sortPeople = (a, b) => {
	const aIndex = CASTING.indexOf(a.role)
	const bIndex = CASTING.indexOf(b.role)
	if (aIndex == bIndex) return 0
	else if (aIndex == -1) return 1
	else if (bIndex == -1) return -1
	else if (aIndex < bIndex) return -1
	else return 1
}

const getRoleTranslation = (role) => {
	switch (role) {
		case PEOPLE_DIRECTOR: return "role-director"
		case PEOPLE_SCENARIO: return "scenario"
		case PEOPLE_DIALOGS: return "dialogs"
		case PEOPLE_PHOTO_DIRECTOR: return "photo-director"
		case PEOPLE_DECOR: return "decor"
		case PEOPLE_COSTUMES: return "costumes"
		case PEOPLE_MONTAGE: return "editing"
		case PEOPLE_BO: return "original-music"
		case PEOPLE_SOUND_ENGINEER: return "sound-engineer"
		case PEOPLE_PRODUCTOR: return "productor"
		case PEOPLE_PRODUCTION: return "production-company"
		case PEOPLE_RIGHTHOLDERS: return "distributor"
		default: return role
	}
}

const subtitlesTranslationKeys = {
	Anglais: "english",
	EN: "english",
	English: "english",
	Deutsch: "german",
	German: "german",
	FR: "french",
	Français: "french",
	Nederlands: "dutch",
	FrenchSME: {
		key: "Français - SME",
		name: "french - SME"
	},
	SME: "french - SME"
}

export const manageBundleVersions = (bundle, c) => {
	let versionAudio = {
		name: "version-audio",
		value: c(metadata(bundle, FEATURE_VERSION_AUDIO))
	}
	let versionDubbing = {
		name: "version-dubbing",
		value: c(metadata(bundle, FEATURE_VERSION_DUBBING))
	}
	let subtitles = {
		name: "version-subtitles",
		value: c(metadata(bundle, FEATURE_BUNDLE_SUBTITLES))
	}
	let sme = {
		name: "version-loss",
		value: c(metadata(bundle, FEATURE_BUNDLE_SUBTITLES_SME))
	}

	const filmVersions = [versionAudio, versionDubbing, subtitles, sme ]

	let versions = []

	filmVersions.forEach((filmVersion, index) => {
		let value = "", tooltipValue = ""
		let allValues = !filmVersion.value ? [] : filmVersion.value.split(",").map(val => {
		  return c(val)
		})

		const values = allValues.filter((val, index) => {
			return allValues.indexOf(val) === index
		})

		if (!values.length) {
			value = c("none")
		}
		else if (values.length > 2) {
			value = `${values[0]} ${c("and-more", { count: values.length - 1 })}`
			tooltipValue = values.join(", ")
		}
		else {
			value = values.join(", ")
		}
		versions[index] = {
			name: filmVersion.name,
			value: value,
			tooltipValue: tooltipValue
		}
	})

	return versions
}

export const manageFilmVersions = (details, c) => {
	let versionAudio = {
		name: "version-audio",
		value: ""
	}
	let versionDubbing = {
		name: "version-dubbing",
		value: ""
	}

	if (details && details.extension.videos.items.length) {
		details.extension.videos.items.forEach(video => {
			if (!/Freebox/.test(video.name)) {
				if (versionAudio.value.indexOf(c(metadata(video, FEATURE_VERSION_AUDIO))) === -1) {
					versionAudio.value += versionAudio.value ? "," + c(metadata(video, FEATURE_VERSION_AUDIO)) : c(metadata(video, FEATURE_VERSION_AUDIO))
				}
				if (versionDubbing.value.indexOf(c(metadata(video, FEATURE_VERSION_DUBBING))) === -1) {
					versionDubbing.value += versionDubbing.value ? "," + c(metadata(video, FEATURE_VERSION_DUBBING)) : c(metadata(video, FEATURE_VERSION_DUBBING))
				}
			}
		})
	}

	const { subtitles, sme } = manageFilmSubtitles(details, c)

	const filmVersions = [versionAudio, versionDubbing, subtitles, sme]

	let versions = []

	filmVersions.forEach((filmVersion, index) => {
		let value = "", tooltipValue = ""
		let allValues = !filmVersion.value ? [] : filmVersion.value.split(",").map(val => {
		  return c(val)
		})

		const values = allValues.filter((val, index) => {
			return allValues.indexOf(val) === index
		})

		if (!values.length) {
			value = c("none")
		}
		else if (values.length > 2) {
			value = `${values[0]} ${c("and-more", { count: values.length - 1 })}`
			tooltipValue = values.join(", ")
		}
		else {
			value = values.join(", ")
		}
		versions[index] = {
			name: filmVersion.name,
			value: value,
			tooltipValue: tooltipValue
		}
	})

	return versions
}

const manageFilmSubtitles = (details, c) => {
	let embeddedSubtitle = ""
	if (details && details.extension.videos.items.length) {
		details.extension.videos.items.forEach(video => {
			if (!/Freebox/.test(video.name) && c(metadata(video, FEATURE_EMBEDDED_SUBTITLES)) && !embeddedSubtitle.includes(c(metadata(video, FEATURE_EMBEDDED_SUBTITLES)))) {
				embeddedSubtitle = !embeddedSubtitle ? c(metadata(video, FEATURE_EMBEDDED_SUBTITLES)) : `${embeddedSubtitle}, ${c(metadata(video, FEATURE_EMBEDDED_SUBTITLES))}`
			}
		})
	}

	let subtitles = {
		name: "version-subtitles",
		value: ""
	}

	let sme = {
		name: "version-loss",
		value: ""
	}

	if (embeddedSubtitle) {
		subtitles.value = embeddedSubtitle
	}

	if (details && details.extension.videos.items.length) {
		details.extension.videos.items.forEach(video => {
			if (!/Freebox/.test(video.name)) {
				video.subtitles.forEach(subtitle => {
					let name = subtitle.name

					if (subtitlesTranslationKeys[subtitle.name]) {
						name = subtitlesTranslationKeys[subtitle.name]
					}
					else if (name === subtitlesTranslationKeys["FrenchSME"].key) {
						name = subtitlesTranslationKeys["FrenchSME"].name
					}

					if (name.indexOf("SME") !== -1 && sme.value.indexOf(c(name.replace(/\s/g, "").replace("-SME", "").toLowerCase())) === -1) {
						sme.value += sme.value ? ", " + c(name.replace(" - SME", "").toLowerCase()) : c(name.replace(/\s/g, "").replace("-SME", "").toLowerCase())
					}
					else if (name.indexOf("SME") === -1 && subtitles.value.indexOf(c(name.toLowerCase())) === -1) {
						subtitles.value += subtitles.value ? ", " + c(name.toLowerCase()) : c(name.toLowerCase())
					}
				})
			}
		})
	}

	if (!subtitles.value) {
		subtitles.value = c("none")
	}

	if (!sme.value) {
		sme.value = c("none")
	}

	return { subtitles, sme }
}

export const manageTechnicals = (details) => {
	let technicals = {}
	if (details) {
		for (var key in videoFormats) {
			details.extension.videos.items.forEach(video => {
				if (!technicals[key] && metadata(video, videoFormats[key])) {
					technicals[key] = metadata(video, videoFormats[key])
				}
			})
			if (!technicals[key] && metadata(details, videoFormats[key])) {
				technicals[key] = metadata(details, videoFormats[key])
			}
		}
		technicals = {...technicals, ...mapTechnicals(details)}
	}

	return technicals
}

export const restoredVersion = (details, c) => {
	let restored = false
	if (details && details.extension.videos.items.length) {
		details.extension.videos.items.forEach(video => {
			if (!/Freebox/.test(video.name)) {
					if (metadata(video, FEATURE_RESTORED_VERSION) === "oui") {
						restored = true
					}
			}
		})
	}

	return restored
}

export const getFilmNote = (details) => {
	let note = ""
	let asNote = false

	if (details?.extension?.videos?.items?.length) {
		details.extension.videos.items.forEach(video => {
			if (!/Freebox/.test(video.name)) {
				const content = metadata(video, FEATURE_NOTE_FILM)
				if (content) {
					asNote = true
					const endOfParagraphOpenTagPosition = content.indexOf(">") + 1
					note = [content.slice(0, endOfParagraphOpenTagPosition), '<span class="icon-infos"></span> ', content.slice(endOfParagraphOpenTagPosition)].join('')
				}
			}
		})
	}

	if (!asNote && details?.metadata?.length) {
		const content = metadata(details, FEATURE_NOTE_FILM)
		if (content) {
			asNote = true
			const endOfParagraphOpenTagPosition = content.indexOf(">") + 1
			note = [content.slice(0, endOfParagraphOpenTagPosition), '<span class="icon-infos"></span> ', content.slice(endOfParagraphOpenTagPosition)].join('')
		}
	}

	return { asNote, note }
}

export const getBundleNote = (bundle) => {
	let note = ""
	let asNote = false
	const content = metadata(bundle, FEATURE_BUNDLE_NOTE)
	if (content) {
		asNote = true
		const endOfParagraphOpenTagPosition = content.indexOf(">") + 1
		note = [content.slice(0, endOfParagraphOpenTagPosition), '<span class="icon-infos"></span> ', content.slice(endOfParagraphOpenTagPosition)].join('')
	}
	return { asNote, note }
}
