import HtmlParser from "react-html-parser"

import { useTranslation } from "lib/i18n/translation"
import { overlayClicSubscriptionDataLayer } from "lib/gtm/interactions"

import SkeletonLink from "components/common/skeleton-link"

export const LoginSvodCard = ({ product = null }) => {
	const { c } = useTranslation()

	return (
		<>
			<h2 className="hidden">{c("login-svod-no-subscriber")}</h2>
			<p className="introduction normal"><b>{HtmlParser(c("login-svod-must-subscribe"))}</b></p>
			<p>{c("login-svod-seven-days-free")}</p>
			<SkeletonLink href="/ways-to-watch/subscription">
				<a
					title={c("login-svod-see-subscriptions")}
					className="button"
					onClick={(e) => product && overlayClicSubscriptionDataLayer(product)}
					>
					{c("i-subscribe")}
				</a>
			</SkeletonLink>
		</>
	)
}
