import { useState } from "react"
import { Form, Input } from "@rocketseat/unform"
import * as Yup from "yup"

import { useAuth } from "lib/auth"
import { useTranslation } from "lib/i18n/translation"

const schema = (c) => {
	return Yup.object().shape({
		password: Yup.string()
			.min(5)
			.required(c("password_required")),
		passwordConfirmation: Yup.string()
     .oneOf([Yup.ref("password"), null], c("passwords_must_match")),
	})
}

const handleResetPassword = async ({ values, auth, resetForm }) => {
	return await auth.resetPassword(values)
}

const ResetPasswordForm = ({token}) => {

	const auth = useAuth()
	const { c } = useTranslation("common")

	const [error, setError] = useState(false)
	const [resetting, setResetting] = useState(false)
	const [success, setSuccess] = useState(false)

	const handleSubmit = async (values, { resetForm }) => {
		setResetting(true)
		setError(false)
		values["token"] = token
		handleResetPassword({values, auth, resetForm}).then(result => {
			if (result.password == false) setError(true)
			else setSuccess(true)
			setResetting(false)
		})
	}

	if (success) {
		return (
			<>
				<div className="description">{c("reset-password-success-desc")}</div>
				<a onClick={() => auth.showLogin()} className="button">{c("connection")}</a>
			</>
		)
	} else {
		return (
			<>
				<Form schema={schema(c)} onSubmit={handleSubmit} aria-label={c("reset-password")}>
					<fieldset>
						<legend className="hidden">{c("to-set-new-password")}</legend>
						<ul className="columns has-gutter">
							<li>
								<span className="form-label hidden">{c("ph_new_password")}</span>
								<span className="input-container icon-lock-on">
									<Input name="password" type="password" placeholder={c("ph_new_password")} className="login-pw" />
								</span>
								<span className="form-label hidden">{c("ph_new_password_confirm")}</span>
								<span className="input-container icon-lock-on">
									<Input name="passwordConfirmation" type="password" placeholder={c("ph_new_password_confirm")} />
								</span>
							</li>
						</ul>
						<p><button type="submit" title= {c("reset-password")} aria-label={c("i-reset-my-password")} className={`icon-check full ${resetting ? "loader" : ""}`}>{c("reset-password")}</button></p>
						{ error
							? <span>{c("unable_to_connect")}</span>
							: null
						}
					</fieldset>
				</Form>
			</>
		)
	}
}

export default ResetPasswordForm
