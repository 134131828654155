import { useTranslation } from "lib/i18n/translation"
import { GiftForm } from "components/forms/gift"
import { GiftOptionsForm } from "components/forms/gift-options"
import { useCart } from "lib/cart"
import { useModal } from "lib/modal"

export default ({gift, isFilm}) => {

	const { c } = useTranslation("common")
	const { hide } = useModal()
	const { updateGift } = useCart()

	const handleSubmitted = (data) => {
		updateGift(gift.id, data)
			.then(() => {
				hide()
			})
	}

	return (
		<>
			<h2 className="overlay-title icon-reel">{c("gift-infos")}</h2>
			{ isFilm
				? <GiftForm
						gift={gift}
						onSubmit={handleSubmitted}
					/>
				: <GiftOptionsForm
						gift={gift}
						active={true}
						onSubmit={handleSubmitted}
						update={true}
					/>
			}
		</>
	)
}
