import Link from "next/link"

import { replace, getWithLanguage } from "lib/redirects"
import { useLanguages } from "lib/languages"
import { useCountry } from "lib/country"

export default (props) => {
	const { href, as, loading, children, parameters } = props
	const { current } = useLanguages()
	const { country, countrySlug } = useCountry()

	if (loading) {
		return children
	}

	let realTarget = getWithLanguage(href, current)
	if (realTarget == "/") realTarget = ""


	let realHref = realTarget
	if (typeof href === 'object' && href !== null) {
		if (href.pathname) {
			realHref.pathname = `/${countrySlug}${href.pathname}`
			realTarget = null
		}
	} else {
		realHref = `/[lang]${realTarget}`
		realTarget = `/${countrySlug}${as || realTarget}`

		let variables = []
		const matches = realHref.match(/\[[^\]]+\]/g)
		const splittedPath = realHref.split("/")
		const splittedAsPath = realTarget.split("/")
		matches.forEach((match, index) => {
			if (match == "[lang]") {
				variables.push(countrySlug)
			}else {
				variables.push(splittedAsPath[splittedPath.indexOf(match)])
			}
		})
		realTarget = replace(realHref, ...variables)
	}

	if (parameters) {
		realHref = realHref + `?${parameters}`
	}

	return (
		<Link href={realHref} as={realTarget} scroll={ false } passHref prefetch={false}>
			{ children }
		</Link>
	)
}
