import React from "react"
import * as Sentry from "@sentry/node"

import initApollo from "./init-apollo"
import Head from "next/head"

import { start, stop } from "lib/tools/timer"

import { getDataFromTree, getMarkupFromTree } from "@apollo/react-ssr"
import { renderToString } from "react-dom/server";

import cookies from "next-cookies"
import { isBrowser } from "lib/tools"

import { Bootstrap } from "lib"

export default App => {
  return class Apollo extends React.Component {
    static displayName = 'withApollo(App)'
    static async getInitialProps (ctx) {

			const clientIP = (ctx.ctx && ctx.ctx.req) ? (ctx.ctx.req.headers['x-forwarded-for'] || ctx.ctx.req.connection.remoteAddress) : null

			let lang = ctx.ctx.res ? (ctx.ctx.res.language || "fr") : (cookies(ctx.ctx)["next-i18next"] || "fr")
			if (!lang) {
				lang = cookies(ctx.ctx)["next-i18next"]
				if (!lang) {
					lang = "fr"
				}
			}

			const adminToken = cookies(ctx.ctx)["admin-auth-token"]

      let apollo = initApollo(null, adminToken, lang, clientIP, null)
			ctx.ctx.apolloClient = apollo

			const {
        Component,
        router,
        ctx: { req, res, pathname }
      } = ctx

			let appProps = { }

				let accessCookies = null, originalAccessCookies = null
				if (!req) {
					accessCookies = cookies(ctx.ctx)["access"].split("-")
					originalAccessCookies = cookies(res ? res : ctx.ctx)["original-access"].split("-")
				}

				let objAccess = req ? req.access : { purchase: (accessCookies[0] == "1"), country: accessCookies[1] }
				const objOriginalAccess = req ? req.originalAccess : { purchase: (originalAccessCookies[0] == "1"), country: originalAccessCookies[1] }

				if (!objAccess) {
					console.log(`Request exists : ${req !== null && req !== undefined}`)
					if (req) {
						console.log(req.url)
						console.log(req.access)
						console.log(req.originalAccess)
					} else {
						console.log(accessCookies)
						console.log(originalAccessCookies)
					}
				}

				const bootstraped = await Bootstrap.boot({
					client: apollo, req, res, pathname, adminToken,
					cookies: {
						prehome: Number(cookies(ctx.ctx)["prehome"] || 0),
						prehomeSession: Number(cookies(ctx.ctx)["prehomeSession"] || 0),
						prehomeEvent: Number(cookies(ctx.ctx)["prehomeEvent"] || 0),
						prehomeEventId: Number(cookies(ctx.ctx)["prehomeEventId"] || 0),
						access: objAccess,
						"original-access": objOriginalAccess
					}
				})

				const { languages, currentIsoCode, countries, country, userCountry, availableCountry, access, originalAccess, festivals, subscriptions, inMaintenance } = bootstraped

				appProps["currentIsoCode"] = currentIsoCode
				appProps["languages"] = languages
				appProps["countries"] = countries
				appProps["country"] = country
				appProps["inMaintenance"] = inMaintenance
				appProps["userCountry"] = userCountry
				appProps["availableCountry"] = availableCountry
				appProps["access"] = access
				appProps["originalAccess"] = originalAccess
				appProps["ssrFilmsBase"] = { fr: [], en: [] }
				appProps["festivals"] = festivals
				appProps["subscriptions"] = subscriptions
				ctx.ctx.country = country

				if (App.getInitialProps) {
					appProps = { ...appProps, ...(await App.getInitialProps(ctx)) }
				}

				// Run all GraphQL queries in the component tree
				// and extract the resulting data
				if (!isBrowser) {
					try {

						start("tree")

						await getDataFromTree(
							<App
								{...appProps}
								Component={Component}
								router={router}
								apolloClient={apollo}
							/>
						)
						stop("tree")

						start("markup")
						await getMarkupFromTree({
							renderFunction: renderToString,
							tree: (
								<App
									{...appProps}
									Component={Component}
									router={router}
									apolloClient={apollo}
								/>
							)
						})
						stop("markup")
					} catch (error) {
						// Prevent Apollo Client GraphQL errors from crashing SSR.
						// Handle them in components via the data.error prop:
						// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
						//
						if (error.message == "auth_exception") {
							res.redirect("/")
						} else {
							console.error('Error while running `getDataFromTree`', error)
							console.log(Object.keys(error))
							console.log("STACK")
							console.log(error.stack)
							console.log("-------")
							Sentry.captureException(error, {
								tags: {
									context: "getDataFromTree"
								}
							})
						}
					}

					// getDataFromTree does not call componentWillUnmount
					// head side effect therefore need to be cleared manually
					Head.rewind()
				}
			//}

			// Extract query data from the Apollo store
			const apolloState = apollo.cache.extract()

      return {
        ...appProps,
        apolloState,
				adminToken,
				lang,
				clientIP,
      }
    }

    constructor (props) {
      super(props)
      this.apolloClient = initApollo(props.apolloState, props.adminToken, props.lang, props.clientIP, props.token)
    }

    render () {
      return <App apolloClient={this.apolloClient} {...this.props} />
    }
  }
}
