import { useState, useEffect } from "react"

import { getBundles } from "lib/bundle-fetcher"
import { useLanguages } from "lib/languages"
import { useCountry } from "lib/country"
import { baseProduct } from "components/common/skeleton"


export default ({ bundles: bundleIds, loading: externalLoading, children }) => {

	const { current } = useLanguages()
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const { country } = useCountry()

	useEffect(() => {
		if (!externalLoading) {
			setLoading(true)
			getBundles(bundleIds.map(p => p && p.id), current, country.isoCode)
				.then(result => {
					setData(result)
					setLoading(false)
				})
				.catch(e => { console.log(e) })
		}
	}, [current])

	return children(
		loading
			? [...Array(data.length)].map((i, index) => baseProduct)
			: data.filter(p => p !== undefined && p !== null),
		loading
	)
}
