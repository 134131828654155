import React, { useState, useEffect, useContext, createContext } from "react"
import * as Sentry from "@sentry/node"
import moment from "moment"
import { useMutation, useApolloClient } from "@apollo/react-hooks"
import Router from "next/router"
import { useTranslation } from "react-i18next"

import isPrivate from "lib/profile/is-private"
import { useModal } from "lib/modal"
import { overlayDataLayer } from "lib/gtm/interactions"
import { setToken, setRefreshToken } from "lib/graphql/apollo/token"

import { Login, Signup, ForgotPassword, ResetPassword } from "components/forms"
import ConfirmPassword from "components/modals/confirm-password"
import { LoginSvodCard } from "components/subscription/login-card"

import {
	AUTHENTICATE_MUTATION,
	FB_AUTHENTICATE_MUTATION,
	ANONYMOUS_AUTHENTICATE_MUTATION,
	REMOVE_ACCOUNT_MUTATION,
	FORGOT_PASSWORD_MUTATION,
	RESET_PASSWORD_MUTATION,
	UPDATE_PROFILE,
	UPDATE_EMAIL,
	UPDATE_PASSWORD,
	SIGNUP_MUTATION,
	REFRESH_TOKEN,
	REMOVE_DEVICE,
	TOGGLE_FILM_NOTIFICATION,
	FETCH_PROFILE,
	FETCH_FILM_NOTIFICATIONS,
	FETCH_RENEWALS
} from "lib/graphql"

const debug = require("debug")("lacinetek:auth")

export const ADMIN_AUTH_TOKEN = "admin-auth-token"
export const AUTH_TOKEN = "auth-token"
export const SSR_TOKEN = "ssr-token"

import { Cookies } from "react-cookie"

const authContext = createContext()

export const AuthConsumer = authContext.Consumer

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth = ({ children, resetPasswordToken, inMaintenance }) => {
	const auth = useProvideAuth(resetPasswordToken, inMaintenance)

	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth(resetPasswordToken, inMaintenance) {
	const client = useApolloClient()
	const cookies = new Cookies()
	const { show, hide } = useModal()
	const { t } = useTranslation()

	useEffect(() => {
		if (resetPasswordToken) {
			showResetPassword(resetPasswordToken)
		}
	}, [])

	let current_admin = null

	const [user, setUser] = useState(null)
	const [connecting, setConnecting] = useState(true)
	const [updating, setUpdating] = useState(false)
	const [authentified, setAuthentified] = useState(false)
	const [filmNotifications, setFilmNotifications] = useState([])

	const [removingAccount, setRemovingAccount] = useState(false)

	const [admin, setAdmin] = useState(current_admin)
	const [isKinowAdmin, setIsKinowAdmin] = useState(false)
	const [token, setStateToken] = useState()

	let [inHandlers, setInHandlers] = useState([])
	let [outHandlers, setOutHandlers] = useState([])

	const [authenticateMutation] = useMutation(AUTHENTICATE_MUTATION)
	const [fbAuthenticateMutation] = useMutation(FB_AUTHENTICATE_MUTATION)
	const [removeAccountMutation] = useMutation(REMOVE_ACCOUNT_MUTATION)
	const [anonymousAuthenticateMutation] = useMutation(ANONYMOUS_AUTHENTICATE_MUTATION)

	const [signupMutation] = useMutation(SIGNUP_MUTATION)

	const [updateProfileMutation, data] = useMutation(UPDATE_PROFILE)
	const [updateEmailMutation] = useMutation(UPDATE_EMAIL)
	const [updatePasswordMutation] = useMutation(UPDATE_PASSWORD)

	const [removingDevice, setRemovingDevice] = useState(false)

	const [togglingFilmNotification, setTogglingFilmNotification] = useState(false)

	const manageUserData = async (baseUser = null, productsIds = null) => {
		return await Promise.all([
			fetchProfile(),
		])
			.then((data) => {
				let tmpUser = null

				if (data[0]) {
					tmpUser = {
						...baseUser,
						...data[0],
						accesses: {
							items: []
						}
					}

					setIsKinowAdmin(tmpUser.groups.find(group => group.id === process.env.MAINTENANCE_GROUP_ID) !== undefined)
				}

				setUser(tmpUser)
				setConnecting(false)

				return tmpUser
			})
			.catch(error => {
				console.error(error)
				setConnecting(false)

				Sentry.captureException(error, {
					tags: {
						section: "auth"
					},
					user: user ? user.profile : null,
					extra: {
						step: "manageUserData"
					}
				})
			})
	}


	const fetchFilmNotifications = async () => {
		const { data: { filmNotifications } } = await client
			.query({
				query: FETCH_FILM_NOTIFICATIONS,
				variables: { user: parseInt(user.profile.id) },
				context: { clientName: "admin" },
				fetchPolicy: "network-only"
			})

		setFilmNotifications(filmNotifications)
	}

	const fetchProfile = () => {
		if (!token) {
			return
		}

		return client.query({ query: FETCH_PROFILE, fetchPolicy: "network-only" })
			.then(({ data }) => {
				if (!data.user) {
					setUser(null)
				} else {
					setUser({
						...data.user,
						accesses: {
							items: []
						}
					})
				}
				setAuthentified(true)
				if (data.user) {
					setIsKinowAdmin(data.user.groups.find(group => group.id === process.env.MAINTENANCE_GROUP_ID) !== undefined)
				}

				return data.user
			})
			.catch(error => {
				setConnecting(false)
				console.log("Error when fetching profile")
			})
	}

	const fetchRenewal = () => {
		return client.query({
			query: FETCH_RENEWALS,
			variables: { user: parseInt(user.profile.id) },
			context: { clientName: "admin" }
		}).then(data => {
			if (data.renewal) {
				setUser(previousState => {
					return {
						...previousState,
						...data
					}
				})
			}
		}).catch(error => {
			console.log(error)
		})
	}

	const updateRefreshToken = () => {
		const token = cookies.get(AUTH_TOKEN)
		let tokenMutation = { mutation: ANONYMOUS_AUTHENTICATE_MUTATION }

		if (token) {
			tokenMutation = { mutation: REFRESH_TOKEN, variables: { refreshToken: token } }
		}

		return client
			.mutate(tokenMutation)
			.then((response) => {
				if (response.data.signin) {
					cookies.set(AUTH_TOKEN, response.data.signin.refreshToken, {
						path: "/",
						expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
					})
					cookies.set(SSR_TOKEN, response.data.signin.accessToken, {
						path: "/",
						expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
					})
					setToken(response.data.signin.accessToken)
					setStateToken(response.data.signin.accessToken)
					setRefreshToken(response.data.signin.refreshToken)
				} else {
					setConnecting(false)
				}
			})
			.catch(error => {
				console.log(error)
				setConnecting(false)
			})
	}

	useEffect(() => {
		updateRefreshToken()
	}, [])

	useEffect(() => {
		if (!token) {
			return
		}

		manageUserData()
	}, [token])

	useEffect(() => {
		if (authentified) {
			setConnecting(false)
		}
	}, [authentified])

	useEffect(() => {
		if (!user) {
			return
		}

		fetchRenewal()
		fetchFilmNotifications()
		inHandlers.map(({ handler }) => handler(user))
	}, [user])

	useEffect(() => {
		if (inMaintenance && isKinowAdmin && !Router.query.admin) {
			Router.push({
				pathname: Router.asPath,
				query: { admin: process.env.MAINTENANCE_ADMIN_AUTH }
			})
		}
	}, [isKinowAdmin])

	const signin = async ({ email, password, fbToken }) => {
		setConnecting(true)
		try {
			let authentication

			if (email && password) {
				authentication = await authenticateMutation({
					variables: { email, password }
				})
			}
			if (authentication.data.signin) {
				const cookies = new Cookies()

				cookies.set(AUTH_TOKEN, authentication.data.signin.refreshToken, {
					path: "/",
					expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
				})
				cookies.set(SSR_TOKEN, authentication.data.signin.accessToken, {
					path: "/",
					expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
				})
				setToken(authentication.data.signin.accessToken)
				setStateToken(authentication.data.signin.accessToken)
				setRefreshToken(authentication.data.signin.refreshToken)

				return true
			}
		} catch (e) {
			setConnecting(false)
			console.log(e)
		}

		return false
	}

	const signInWithTokens = async (accessToken, refreshToken) => {
		setConnecting(true)
		cookies.set(AUTH_TOKEN, refreshToken, {
			path: "/",
			expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
		})
		cookies.set(SSR_TOKEN, accessToken, {
			path: "/",
			expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
		})
		setToken(accessToken)
		setStateToken(accessToken)
		setRefreshToken(refreshToken)
	}

	const signup = async ({
		firstname,
		lastname,
		email,
		password,
		birthdate,
		newsletter
	}) => {
		setConnecting(true)
		try {
			const signup = await signupMutation({
				variables: {
					firstname,
					lastname,
					email,
					password,
					birthdate: moment(birthdate).format("YYYY-MM-DD"),
					newsletter
				}
			})
			if (!signup.data.signup) {
				throw new Error("No signup")
			}

			const cookies = new Cookies()
			cookies.set(AUTH_TOKEN, signup.data.signup.refreshToken, {
				path: "/",
				expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
			})
			cookies.set(SSR_TOKEN, signup.data.signup.accessToken)
			setToken(signup.data.signup.accessToken)
			setStateToken(signup.data.signup.accessToken)
			setRefreshToken(signup.data.signup.refreshToken)

			return signup.data.signup
		} catch (e) {
			setConnecting(false)
			console.log(e)
			return false
		}
	}

	const updateProfile = (values) => {
		return new Promise((resolve, reject) => {
			const updateUserProfile = async (values, currentPassword, resolve) => {
				setUpdating(true)
				const { firstname, lastname, birthdate, email, password } = values
				const finalResult = await updateProfileMutation({
					variables: {
						firstname,
						lastname,
						birthdate: moment(birthdate).format("YYYY-MM-DD")
					}
				})
					.then(({ data: { updateUserProfile: profile } }) => {
						const updateUser = { ...user, profile }
						setUser(updateUser)
						return updateEmail(email, currentPassword)
							.then((result) => {
								if (result && password) {
									return updatePassword(password, currentPassword).then((result) => {
										return result ?? false
									})
								}
								setUpdating(false)
								return result ?? false
							})
					}).catch(error => {
						setUpdating(false)
						return false
					})
				resolve(finalResult)
			}

			show({
				id: "update-password",
				children: (
					<ConfirmPassword
						title="update-profile"
						description="update-profile-desc"
						values={values}
						callback={updateUserProfile}
						resolve={resolve}
					/>
				),
				className: "modal short",
				onHide: () => {
					setUpdating(false)
					hide()
					resolve("confirm")
				}
			})
		})
	}

	const updateEmail = (email, password) => {
		return updateEmailMutation({ variables: { email, password } }).then(
			({ data }) => data.updateCredentials.email
		)
	}

	const updatePassword = (password, oldPassword) => {
		return updatePasswordMutation({
			variables: { password, oldPassword }
		}).then(({ data }) => {
			setUpdating(false)
			return data.updateCredentials.password
		})
	}

	const signout = async () => {
		if (isPrivate(Router)) {
			Router.push("/")
		}

		const cookies = new Cookies()
		setToken(null)
		setStateToken(null)
		setRefreshToken(null)
		const anonymousAuthentication = await anonymousAuthenticateMutation()
		cookies.set(AUTH_TOKEN, anonymousAuthentication.data.signin.refreshToken, {
			path: "/",
			expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
		})
		cookies.set(SSR_TOKEN, anonymousAuthentication.data.signin.accessToken, {
			path: "/",
			expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
		})
		setToken(anonymousAuthentication.data.signin.accessToken)
		setStateToken(anonymousAuthentication.data.signin.accessToken)
		setRefreshToken(anonymousAuthentication.data.signin.refreshToken)
		setUser(null)
		setIsKinowAdmin(false)

		if (Router.query.admin) {
			Router.push({ pathname: Router.asPath.split("?")[0] })
			Router.reload()
		}

		client.stop()
		await client.resetStore()

		setTimeout(() => {
			outHandlers.map(({ handler }) => handler())
		}, 300)
	}

	const removeAccount = (password = null) => {
		return new Promise((resolve, reject) => {
			overlayDataLayer("Supprimer le compte")
			show({
				id: "remove-account-modal",
				children: (
					<ConfirmPassword
						title="delete-account"
						description="delete-account-desc"
						callback={confirmRemoveAccount}
						loading={removingAccount}
						resolve={resolve}
					/>
				),
				className: "modal short",
				onHide: () => {
					hide()
					resolve("confirm")
				}
			})
		})
	}

	const confirmRemoveAccount = async (_, password, resolve) => {
		setRemovingAccount(true)

		const accountDeletion = await removeAccountMutation({ variables: { password } })
			.then(async () => {
				setRemovingAccount(false)
				await signout()

				return true
			})
			.catch(error => {
				setRemovingAccount(false)

				return false
			})

		resolve(accountDeletion)
	}

	const forgotPassword = ({ email }) => {
		return client.mutate({
			mutation: FORGOT_PASSWORD_MUTATION,
			variables: { email }
		})
	}

	const resetPassword = ({ password, passwordConfirm, token }) => {
		return client.mutate({
			mutation: RESET_PASSWORD_MUTATION,
			variables: { password, token }
		})
	}



	const signout_admin = () => {
		cookies.remove(ADMIN_AUTH_TOKEN)
		setAdmin(null)
	}

	const showForgotPassword = (email = null) => {
		overlayDataLayer("Mot de passe oublié")
		show({
			id: "forgot-password",
			children: <ForgotPassword email={email} />,
			footerClassName: "grey",
			className: "modal short"
		})
	}

	const showLogin = (defaultRedirect = true, email) => {
		overlayDataLayer("Connexion")
		show({
			id: "login",
			children: <Login defaultRedirect={defaultRedirect} email={email} />,
			footerClassName: "grey",
			footer: (
				<nav className="_inline">
					<h3>{t("not_subscribed_yet")}</h3>
					<a href="#" onClick={e => { e.preventDefault(); showSignup(defaultRedirect) }} rel="nofollow noopener" title={t("create-an-account")} className="button small icon-user">{t("create-an-account")}</a>
				</nav>
			),
			className: "modal short"
		})
	}

	const showLoginSvod = (product = null) => {
		overlayDataLayer("Connexion")
		show({
			id: "login-svod",
			contentClassName: "svod-color centered",
			children: <LoginSvodCard product={product} />,
			footer: <Login title={t("login-svod-are-you-subscriber")} titleClassName="icon-login" />,
			className: "modal short"
		})
	}

	const showSignup = (defaultRedirect = true) => {
		overlayDataLayer("Inscription")
		show({
			id: "subscription",
			title: t("signup"),
			children: <Signup defaultRedirect={defaultRedirect} />,
			className: "modal short",
			footerClassName: "grey",
			footer: (
				<nav className="_inline">
					<h3>{t("already-an-account")}</h3>
					<a href="#" onClick={e => { e.preventDefault(); showLogin(defaultRedirect) }} rel="nofollow noopener" title={t("login")} className="button small icon-user">{t("login")}</a>
				</nav>
			)
		})
	}

	const showResetPassword = (token) => {
		overlayDataLayer("Mot de passe réinitialisé")
		show({
			id: "reset-password",
			title: t("reset-password"),
			children: <ResetPassword token={token} />,
			className: "modal short"
		})
	}

	const onSignedIn = (key, handler) => {
		inHandlers.push({ key, handler })
		setInHandlers([...inHandlers])
	}

	const removeOnSignedIn = (key) => {
		inHandlers = inHandlers.filter((h) => h.key !== key)
		setInHandlers([...inHandlers])
	}

	const onSignedOut = (key, handler) => {
		outHandlers.push({ key, handler })
		setOutHandlers([...outHandlers])
	}

	const removeOnSignedOut = (key) => {
		outHandlers = outHandlers.filter((h) => h.key !== key)
		setOutHandlers([...outHandlers])
	}

	const updateDevices = (devices) => {
		setUser({
			...user,
			devices
		})
	}

	const removeDevice = (id) => {
		setRemovingDevice(id)
		return client
			.mutate({ mutation: REMOVE_DEVICE, variables: { id } })
			.then(({ data: { removeDevice: devices } }) => {
				updateDevices(devices)
				setRemovingDevice(false)
			})
			.catch(() => {
				setRemoving(false)
			})
	}

	const toggleFilmNotification = (film) => {
		setTogglingFilmNotification(true)

		client
			.mutate({
				mutation: TOGGLE_FILM_NOTIFICATION,
				variables: { user: parseInt(user.profile.id), film: parseInt(film) },
				context: { clientName: "admin" }
			})
			.then(({ data: { toggleFilmNotification: filmNotifications } }) => {
				setFilmNotifications(filmNotifications)
				setTogglingFilmNotification(false)
			})
			.catch(() => setTogglingFilmNotification(false))
	}

	const setRenewal = (renewal) => {
		setUser({
			...user,
			renewal
		})
	}

	const removeRenewal = (renewal) => {
		setUser({
			...user,
			renewal: null
		})
	}

	return {
		connecting,
		updating,
		user,
		isKinowAdmin,
		admin,
		signin,
		signInWithTokens,
		signup,
		signout,
		forgotPassword,
		resetPassword,

		removeAccount,
		removingAccount,

		updateProfile,
		updatePassword,
		setRenewal,
		removeRenewal,

		updateDevices,
		removeDevice,
		removingDevice,
		setRemovingDevice,

		filmNotifications,
		toggleFilmNotification,
		togglingFilmNotification,

		onSignedIn,
		removeOnSignedIn,
		onSignedOut,
		removeOnSignedOut,

		showLogin,
		showLoginSvod,
		showForgotPassword,
		showSignup,
		showResetPassword
	}
}

export const needAuth = (BaseComponent) => (props) => {
	const auth = useAuth()
	if (auth.user) return <BaseComponent {...props} auth={auth} />
	if (typeof window !== "undefined") {
		Router.push("/")
	} else {
		throw new Error("auth_exception")
	}
	return null
}

export const avoidAuth = (BaseComponent) => (props) => {
	const auth = useAuth()
	if (!auth.user) return <BaseComponent {...props} />
	if (typeof window !== "undefined") {
		Router.push("/")
	} else {
		throw new Error("auth_exception")
	}
	return null
}

export const avoidAdminAuth = (BaseComponent) => (props) => {
	const auth = useAuth()
	if (!auth.admin) return <BaseComponent {...props} />
	if (typeof window !== "undefined") {
		Router.push("/")
	} else {
		throw new Error("auth_exception")
	}
	return null
}
