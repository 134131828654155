import {
	TREASURES,
	LISTS,
	LIFE_LISTS,
	SELECTION,
	MOVEMENT,
} from "./categories-list"

import { metadata, FEATURE_DATE_FROM, FEATURE_DATE_TO, FEATURE_DIRECTOR_ID } from "lacinetek-common/src/metadata"

import moment from "moment"

export const inListsOf = (product, director, ids) => {
	return product.extension.categories.items.filter(c => (c.parent && (c.parent.id == ids[LISTS] && (!director || director.id !== metadata(c, FEATURE_DIRECTOR_ID)))))
}
export const inLifeList = (category, ids) => {
	return category.parent.id == ids[LIFE_LISTS]
}
export const inListOf = (product, ids) => {
	const lists = inListsOf(product, ids)
	if (lists.length > 0) {
		const index = Math.floor(Math.random() * Math.floor(lists.length))
		return lists[index]
	} else {
		return null
	}
}
export const isInMonthSelection = (product, ids) => {
	const selections = product.extension.categories.items.filter(c => c.parent && c.parent.parent && c.parent.parent.id == ids[SELECTION])
	let result = false
	if (selections.length > 0) {
		const previousSelection = selections.find(s => s.name == moment().subtract(1, "month").format("MM/YYYY"))
		const currentSelection = selections.find(s => s.name == moment().format("MM/YYYY"))
		const nextSelection = selections.find(s => s.name == moment().add(1, "month").format("MM/YYYY"))
		result = isCurrentMonthSelection(currentSelection) || isCurrentMonthSelection(previousSelection) || isCurrentMonthSelection(nextSelection)
	}
	return result
}

const isCurrentMonthSelection = (selection) => {
	let result = false
	if (selection) {
		const now = moment()
		const dateFrom = moment.parseZone(metadata(selection, FEATURE_DATE_FROM), "DD/MM/YYYY")
		const dateTo = moment.parseZone(metadata(selection, FEATURE_DATE_TO), "DD/MM/YYYY").endOf("day")
		if (dateFrom < now && dateTo >= now) {
			result = true
		}
	}
	return result
}

export const inTreasures = (product, ids) => {
	const treasures = product.extension.categories.items.filter(c => c.parent && c.parent.id == ids[TREASURES])
	return treasures.length ? treasures : false
}

export const inMovements = (product, ids) => {
	const movements = product.extension.categories.items.filter(c => c.parent && c.parent.id == ids[MOVEMENT])
	return movements.length ? movements : false
}
