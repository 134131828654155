import slugify from "slugify"

export const BLOG_CLASSICS = "classics"
export const BLOG_CINETEK = "cinetek"

export const BLOG_POST_EVENT = "Événements"
export const BLOG_POST_INTERVIEW = "Entretiens"
export const BLOG_POST_NEWS = "Nouveautés"

export const BLOG_POST_TYPES = [
	slugify(BLOG_POST_EVENT),
	slugify(BLOG_POST_INTERVIEW),
	slugify(BLOG_POST_NEWS)
]

export const getMainTag = (post) => {
	let keyword = null

	try {
		if (post.metaKeywords) {
			const keywords = post.metaKeywords.split(",")
			if (keywords.length) {
				keyword = keywords.find(w => BLOG_POST_TYPES.includes(slugify(w)))
				if (!keyword) keyword = keywords[0]
			}
		}
	} catch(e) {}

	return keyword
}
