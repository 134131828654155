import { useEffect, useRef, useState } from "react"
import { useApolloClient } from "@apollo/react-hooks"
import { useIntersectionObserver } from "@researchgate/react-intersection-observer"
import isBrowser from "lib/tools/is-browser"

import { useCart } from "lib/cart"
import { useModal } from "lib/modal"
import { useAuth } from "lib/auth"
import { useTranslation } from "lib/i18n/translation"
import { useVideoPlayer } from "lib/video-player"
import openPlayer from "lib/tools/player"
import { useProducts } from "lib/products"
import { useCountry } from "lib/country"
import { useAccesses } from "lib/accesses"
import { FETCH_ACCESS_VIDEO } from "lib/graphql"

import ToggleNotif from "components/modals/toggle-notification"
import PlaylistAdd from "components/modals/playlist-add"
import { overlayDataLayer } from "lib/gtm/interactions"

const ProductAccessLink = ({ product, alt, onlyShowOrBuy, forceAvailable, forceAlt, canDownload = false }) => {

	const { addToCart } = useCart()
	const client = useApolloClient()
	const { c, ec } = useTranslation("common")
	const { show } = useModal()
	const {
		user,
		filmNotifications,
		togglingFilmNotification,
		showLogin
	} = useAuth()
	const { hasAccessToProduct } = useAccesses()
	const { purchasable, originalCountry, showAnotherCountry } = useCountry()
	const { seeFilm } = useVideoPlayer()
	const { isAvailable } = useProducts()

	const [ access, setAccess ] = useState()
	const [ isVisible, setIsVisible ] = useState(false)

	const manageAddToCart = (event) => {
		event.preventDefault()

		if (!originalCountry) {
			showAnotherCountry()
		} else {
			addToCart(product)
		}
	}

	const handleAddToPlaylist = (event, product) => {
		event.preventDefault()
		if (!user) {
			return
		}
		show({
			id: "playlist",
			children: <PlaylistAdd productId={product.id} />,
			className: "modal short"
		})
	}

	const toggle = (product) => {
		overlayDataLayer("Notification")

		show({
			id: `toggle-notification-on-${product.id}`,
			title: `${product.name} / ${c("film-notification")}`,
			className: "modal short",
			children: <ToggleNotif
				mode={filmNotifications.includes(parseInt(product.id)) ? "off" : "on"}
				product={product}
			/>,
		})
	}

	const download = (event) => {
		event.preventDefault()
		client.query({
			query: FETCH_ACCESS_VIDEO,
			variables: {
				id: product.id
			}
		})
			.then(({ data }) => {
				const downloadProduct = data.cms.products.items[0]?.extension?.videos.items
					.sort((a, b) => a.name.includes("VO") ? -1 : 1)
					.filter(v => v.accessInfo.streaming)[0]

				openPlayer(downloadProduct.id)
			})
	}

	useEffect(() => {
		if (!isVisible) {
			return
		}

		let shouldUpdate = true

		hasAccessToProduct(product)
			.then(access => {
				if (!shouldUpdate) {
					return
				}

				setAccess(access)
			})
			.catch(error => console.error(error))

		return () => {
			shouldUpdate = false
		}
	}, [user, isVisible])

	const handleChange = (entry, unobserve) => {
		if (!entry.isIntersecting) {
			return
		}

		setIsVisible(true)
		unobserve()
	}

	let ref
	if (isBrowser) {
		[ref] = useIntersectionObserver(handleChange)
	} else {
		ref = useRef()
	}

	const available = product.available || isAvailable(product) || forceAvailable

	let productIsAvailableSomewhere = false
	if (typeof product.countriesAvailability === "object") {
		productIsAvailableSomewhere = Object.keys(product.countriesAvailability).map(key => product.countriesAvailability[key]).filter(o => !!o).length > 0
	} else {
		productIsAvailableSomewhere = Object.keys(product.available).map(key => product.available[key]).filter(o => !!o).length > 0
	}

	const isLoading = access === undefined
	const hasAccess = access === true

	return (
		<>
			{
				isLoading &&
				alt && (
					<a ref={ref} className="loader" href="#" rel="nofollow noopener" onClick={event => event.preventDefault()}></a>
				)
			}
			{
				isLoading
				&& !alt
				&& (
					<div ref={ref} className="film-is-loading"><div className="loader"></div></div>
			)}
			{
				!isLoading
				&& hasAccess
				&& (
					<a
						href="#"
						onClick={e => { e.preventDefault(); seeFilm(product) }}
						title={c("watch-the-movie")}
						data-overlay="#overlay-player"
						className={`icon-play ${(alt && !onlyShowOrBuy) ? "alt" : ""}`}
					>
						{c("watch-the-movie")}
					</a>
				)
			}
			{
				!isLoading
				&& hasAccess
				&& canDownload
				&& (
					<a
						href="#"
						onClick={download}
						rel="nofollow noopener"
						title={c("download")}
						className={`icon-download ${(productIsAvailableSomewhere) ? "" : "disabled"}`}
					>
						{ec("download-movie")}
					</a>
				)
			}
			{
				!isLoading
				&& !hasAccess
				&& purchasable
				&& available
				&& (
					<a
						href="#"
						onClick={manageAddToCart}
						rel="nofollow noopener"
						title={c("add-to-cart")}
						className={`${alt ? "icon-play" : "add-to-cart"} ${(alt && (!onlyShowOrBuy || forceAlt)) ? "alt" : ""}`}
					>
						{alt ? c("watch-the-movie") : c("add-to-cart")}
					</a>
				)
			}
			{
				!isLoading
				&& !hasAccess
				&& purchasable
				&& !available
				&& (
					<a
						href="#"
						rel="nofollow noopener"
						title={c("notify-me")}
						className={`${togglingFilmNotification == product.id ? "is-loading" : "icon-notification"} ${(alt && !onlyShowOrBuy) ? "alt" : ""} ${(filmNotifications.includes(parseInt(product.id))) ? "active" : ""}`}
						onClick={e => {
							e.preventDefault(); user
								? toggle(product)
								: showLogin()
						}}
					>
						{alt ? product.status : c("notify-me")}
					</a>
				)
			}
			{
				!onlyShowOrBuy
				&& (
					!alt
					|| productIsAvailableSomewhere
				)
				&& (
					<a
						href="#"
						onClick={e => { e.preventDefault(); addToCart(product, { gift: true }) }}
						rel="nofollow noopener"
						title={c("offer-film")}
						className={`icon-gift ${(productIsAvailableSomewhere) ? "" : "disabled"}`}
					>
						{ec("offer-film")}
					</a>
				)
			}
			{
				!onlyShowOrBuy && (
					<a
						href="#"
						onClick={event => handleAddToPlaylist(event, product)}
						rel="nofollow noopener"
						title={c("add-to-playlist")}
						className={`icon-playlist-add ${user ? "" : "disabled"}`}
					>
						{ec("add-to-playlist")}
					</a>
				)
			}
		</>
	)
}

export default ProductAccessLink
