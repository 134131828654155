import { ProductImage } from "components/common"
import { useTranslation } from "lib/i18n/translation"
import Gift from "components/cart/gift"

const Product = ({product, accessMode, remove, removing, isGift, play, tiny, loading}) => {
	const { c } = useTranslation("common")

	if (loading) return null

	return (
		<div className="tr">
			<div className="td large">
				<div className="inline">
					<ProductImage product={product} />
					<div className="infos">
						<h3 className="title">{product.name}</h3>
						<span className="director">{c("by")} {product.director}</span>
					</div>
				</div>
				{ !tiny
					? <div className="infos">
							<p>
								<span className="format">{accessMode.quality.toUpperCase()} / {c(`get-type-${accessMode.type.toLowerCase()}`)}</span>
							</p>
						</div>
					: null
				}
			</div>
			<div className={`td ${tiny ? "" : "large"}`}>
				{ tiny
					? <div className="infos">
							<span className="format">{accessMode.quality.toUpperCase()} / {c(`get-type-${accessMode.type.toLowerCase()}`)}</span>
						</div>
					: null
				}
				{ isGift
					? tiny
						? <div className="infos">
								<span className="icon-gift" />
							</div>
						: <Gift gift={isGift} isFilm={true} />
					: null
				}
			</div>
			<div className="td">
				<span className="price">{accessMode.price}&nbsp;€</span>
			</div>
			<div className="td actions">
				{ remove
					? <a
						href="#"
						rel="nofollow noopener"
						title={c("remove")}
						className={`
							${removing == product.id? "is-loading" : "remove"}
							${tiny ? "" : "alt"}
						`}
						onClick={e => { e.preventDefault(); remove(product, { accessMode, isGift }) }}>{c("remove")}
					</a>
					: null
				}
				{ !isGift && play
					? <a href="#" onClick={e => { e.preventDefault(); play() }} rel="nofollow noopener" title={c("watch-the-movie")} data-overlay="#overlay-player" className="icon-play">{c("watch-the-movie")}</a>
					: null
				}
			</div>
		</div>
	)
}

export default Product
