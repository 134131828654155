import isBrowser from "lib/tools/is-browser"

let films = { fr: [], en: [], de: [] }

export const initFilms = (data) => {
	let count = 0
	Object.keys(films).forEach(key => count += films[key].length)
	if (count == 0) {
		films = data
	}
}

export const fetchAllFilms = () => { return films }

export const getFilm = async (id, lang, country) => {
	let film = films[lang] ? films[lang].find(f => f && f.id == id) : null

	if (!film) {
		return fetch(`/fetch-film/${id}`, { headers: country })
			.then(response => {
				return response.json()
					.then(json => {
						films[lang].push(json)
						return json
					})
			})
	} else {
		return (new Promise((r, rj) => r(film)))
	}
}

export const pushFilms = (films, lang, filmsBase) => {
	if (!isBrowser) {
		if (!filmsBase) {
			filmsBase = { fr: [], en: [], de: []}
		}
		if (!filmsBase[lang]) {
			filmsBase[lang] = []
		}
		films.forEach(film => {
			let index = filmsBase[lang].map(f => f.id).indexOf(film.id)
			if (index == -1) {
				filmsBase[lang].push(film)
			} else {
				filmsBase[lang][index] = film
			}
		})
	}
}

let filmPromises = {}

export const getFilms = (ids, lang, country) => {
	if (isBrowser) {
		const missing = missingFilms(ids, lang)
		const keys = `${JSON.stringify(ids)}-${lang}-${country}`

		if (missing.length > 0 && !filmPromises[keys]) {
			filmPromises[keys] = fetch(`/fetch-films/${JSON.stringify(missing)}`, { headers: { country } })
				.then(response => {
					return response.json()
						.then(json => {
							json.forEach(jFilm => {
								let index = films[lang].map(f => f.id).indexOf(jFilm.id)
								if (index == -1) {
									films[lang].push(jFilm)
								} else {
									films[lang][index] = jFilm
								}
							})
							return getFilmsSync(ids, lang)
						})
				})
		} else {
			return (new Promise((r, rj) => r(getFilmsSync(ids, lang))))
		}

		return filmPromises[keys]
	} else {
		const result = ids.map(id => films[lang].find(f => f !== null && (isNaN(id) ? f.linkRewrite == id : f.id == id)))

		return (new Promise((r, rj) => r(result)))
	}
}

export const getFilmsSync = (ids, lang) => {
	return ids.map(id => films[lang].find(f => f !== null && (isNaN(id) ? f.linkRewrite == id : f.id == id)))
}

export const filmsExists = (ids, lang) => {
	return missingFilms(ids, lang).length == 0
}

export const missingFilms = (ids, lang) => {
	if (!films[lang]) {
		films[lang] = []
	}

	let existingIds = films[lang] ? films[lang].filter(f => f !== null && (ids.includes(f.linkRewrite) || ids.includes(f.id))).map(f => f.id) : []
	let missingFilms = ids.filter(id => !existingIds.includes(id))

	return missingFilms
}

export const fetchOthers = async (id, country) => {
	const response = await fetch(`/fetch-others/${id}`, { headers: { country }})
	const json = await response.json()

	return json
}
