import { useEffect, useState } from "react"
import moment from "moment"

import { useCountry } from "lib/country"
import { useTranslation } from "lib/i18n/translation"

export default ({ product, onCountryChanged }) => {
	const { c } = useTranslation("common")
	const { countries, country } = useCountry()
	const [currentCountry, setCurrentCountry] = useState((!product
		? []
		: (
				(!product.countriesAvailability || product.countriesAvailability[country.isoCode])
				&& (!country.svod_from || moment(country.svod_from) <= moment())
			)
			? country
			: countries.find(country => product.countriesAvailability[country.isoCode]
				&& country.svod
				&& (!country.svod_from || moment(country.svod_from) <= moment()))
	))

	const setCountry = (ctry) => {
		setCurrentCountry(ctry)
	}

	useEffect(() => {
		if (onCountryChanged) onCountryChanged(currentCountry)
	}, [currentCountry])

	if (countries.length == 1) return null

	const availableInCountry = (country) => {
		let available = true

		if (product) {
			available = false
			const key = Object.keys(product.countriesAvailability).find(key => key == country.isoCode)
			if (key) {
				available = product.countriesAvailability[key]
			}
		}

		return available
	}

	return (
		<>
			<div className="offers"></div>
			<h3>{c("gift-countries")}</h3>
			<ul className="radios inline countries" role="radiogroup">
				{countries.map((country, index) => (
					<li key={`country-${index}`}>
						<span className={`radio-container ${!availableInCountry(country) ? "disabled" : ""}`}>
							<input
								type="radio"
								name="gift-country"
								aria-label={c(country.name)}
								checked={country.isoCode == currentCountry.isoCode ? "checked" : false}
								onChange={(e) => setCountry(country)} disabled={!availableInCountry(country)}
							/>
							<span className="form-label">{c(country.name)}</span>
						</span>
					</li>
				))}
			</ul>
		</>
	)
}
