import { useEffect, useState } from "react"
import { Form, Input } from "@rocketseat/unform"
import * as Yup from "yup"
import classNames from "classnames"

import { useAuth } from "lib/auth"
import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"

const schema = (c) => {
	return Yup.object().shape({
		email: Yup.string()
			.email(c("email_invalid"))
			.required(c("email_required")),
		password: Yup.string()
			.required(c("password_required")),
	})
}

const LoginForm = ({ email, title, titleClassName }) => {
	const { signin, user, connecting, showForgotPassword } = useAuth()
	const { c, ec } = useTranslation("common")
	const { hide } = useModal()

	const [error, setError] = useState(false)
	const initialData = {}

	const handleSubmit = async (values, { resetForm }) => {
		const result = await signin(values)

		setError(!result)
	}

	if (email) {
		initialData.email = email
	}

	useEffect(() => {
		if (!connecting && user) {
			hide()
		}
	}, [user, connecting])

	useEffect(() => {
		if (connecting) {
			setError(false)
		}
	}, [connecting])

	return (
		<>
			<h2 className={classNames("overlay-title", titleClassName)}>{title || c("login-form-title")}</h2>
			<Form schema={schema(c)} initialData={initialData} onSubmit={handleSubmit} aria-label={c("login-form-title")}>
				<h3 className="nowrap" dangerouslySetInnerHTML={{ __html: c("connect-with-my-cinetek-account") }} />
				<label htmlFor="email" className="hidden" style={{ display: "block" }}>{c("my-id")}</label>
				<span className="input-container icon-user">
					<Input name="email" type="text" autoComplete="username" placeholder={c("ph_email")} className="login-id" />
				</span>
				<label htmlFor="password" className="hidden" style={{ display: "block" }}>{c("my-password")}</label>
				<div className="inputs">
					<span className="input-container icon-lock-on">
						<Input name="password" type="password" autoComplete="current-password" placeholder={c("ph_password")} className="login-pw" />
					</span>
					<button type="submit" aria-label={c("login-me")} className={`check ${connecting ? "is-loading" : ""}`}>{c("connexion")}</button>
				</div>
				{ error && <p>{c("unable_to_connect")}</p> }
				<p><a href="#" onClick={e => { e.preventDefault(); showForgotPassword() }} rel="nofollow noopener" title={c("forgot_password")}>{ec("forgot_password")}</a></p>
			</Form>
		</>
	)
}

export default LoginForm
