import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import UpdateGift from "components/modals/update-gift"
import moment from "moment"

export default ({gift, isFilm}) => {

	const { c } = useTranslation("common")
	const { show } = useModal()

	const getOption = () => {
		let option
		if (gift.custom) {
			option = "send-card"
		} else if (gift.email) {
			option = "send-mail"
		} else {
			option = "print"
		}
		return option
	}

	const option = getOption()

	const handleUpdate = () => {
		show({
			id: "update-gift",
			children: <UpdateGift gift={gift} isFilm={isFilm} />,
			className: "modal"
		})
	}

	return (
		<>
			<div className="infos">
				<p><span className="icon-gift"></span> <b>{c("gift-infos")}</b></p>
				<p>
					<a href="#" onClick={e => { e.preventDefault(); handleUpdate() }} rel="nofollow noopener" title={c("see-alter")} data-overlay="#overlay-gift-options-cinepass">{c("see-alter")}</a>
				</p>
			</div>
			<div className="gift-infos">
				{ isFilm
					? <p>
							{gift.firstname} {gift.lastname}
							<br />
							{gift.email}
						</p>
					: <p>
							<b>{c(`cart-gift-${option}`)}</b>
							<br />
							{c("send-to")} {gift.lastname}
							<br />
							{ option == "send-card"
								? <>
										gift.custom
									</>
								: option == "send-mail"
									? `${moment(gift.dateSend).format('DD MMM YYYY')} ${c("at")} ${gift.email}`
									: null
							}
						</p>
				}
			</div>
		</>
	)
}
