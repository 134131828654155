import ReactMarkdown from 'react-markdown'
import classNames from "classnames/bind"

export const BlockTitle = ({ block, rail = false, className }) => {
	const Tag = rail ? "h3" : "h2"

	let title = ""
	let titleClass = ""
	const iconClassMatch = block.descriptionShort.match(/\[(.+)\]/)

	if (iconClassMatch) {
		title = block.descriptionShort.replace(iconClassMatch[0], "")
		titleClass = iconClassMatch[1]
	} else {
		title = block.descriptionShort
	}


	return (
		<Tag
			className={classNames(
				{ "section-title": !className },
				className,
				titleClass
			)}
		>
			<ReactMarkdown
				allowedElements={["strong"]}
				unwrapDisallowed={true}
			>
				{title}
			</ReactMarkdown>
		</Tag>
	)
}
