import baseSelection from "./base-selection"

export default {
	id: 999999,
	children: {
		items: [ 
			baseSelection,
			baseSelection,
			baseSelection,
			baseSelection
		]
	}
}
