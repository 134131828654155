import moment from "moment"

export const RENT = "rent"
export const BUY = "buy"

export const getExpiredIn = (access, menuKey) => {
	if (!access.dateExp || menuKey !== "rent") {
		return
	}

	const video = access.product.extension.videos.items
		.reduce( (previousValue, currentValue)=> {
			if (!previousValue){
				return currentValue
			}
			if (moment(currentValue.accessInfo.expires).isBefore(moment(previousValue.accessInfo.expires))){
				return currentValue
			}
			return previousValue
		}, null)

	let accessExpires = moment(access.dateExp)
	let videoExpire = moment()

	if (video) {
		if(video.accessInfo.expires) {
			videoExpire = moment(video.accessInfo.expires)
		}
		if (videoExpire.isBefore(accessExpires)) {
			accessExpires = videoExpire
		}
	}

	let diff = accessExpires.diff(moment(), "days")

	if (diff < 0) {
		return
	}

	if (diff < 2) {
		let diffHours = accessExpires.diff(moment(), "hours")

		if (diffHours < 0) {
			return
		}

		return `${diffHours}h`
	}

	return `${diff + 1}j`
}
