import SkeletonLink from "components/common/skeleton-link"
import { useTranslation } from "lib/i18n/translation"
import image, { DIRECTOR_AVATAR } from "lacinetek-common/src/images"
import Image from "components/common/image"
import { useMainCategories } from "lib/tools/categories"

const ProductRecommender = ({ recommender, loading }) => {

	if (!recommender) {
		return null
	}

	const { c } = useTranslation("common")

	const { allLists } = useMainCategories()

	const listSentence = recommender.sentence
	recommender = recommender.recommender
	if (!recommender) {
		return null
	}

	const list = allLists.find(l => (l.director && l.director.id == recommender))
	if (!list) return null

	const directorImage = image(list.director, DIRECTOR_AVATAR)

	return (
		<SkeletonLink href="/lists/[id]" as={`/lists/${list.linkRewrite}`}>
			<a title="Voir la liste" className="is-recommended">
				<figure className={`avatar ${loading ? "skeleton" : ""}`}>
					<picture>
						<Image src={directorImage} alt={list.director.name} />
					</picture>
				</figure>
				<p className={loading ? "skeleton" : ""}>
					<b>{c(listSentence)}</b>
					<span className="list-link">{list.name.replace(" | ", " ")}</span>
				</p>
			</a>
		</SkeletonLink>
	)
}

export default ProductRecommender
