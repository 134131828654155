import { EditMyPlaylist, MyPlaylists } from "."

const PlaylistPage = ({ id, title, playlist, products, menu }) => {
	if (!id) {
		return <MyPlaylists title={title} menu={menu} />
	}

	return <EditMyPlaylist id={id} title={title} playlist={playlist} products={products} menu={menu} />
}

export default PlaylistPage
