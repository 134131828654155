import { MyFilms } from "components/profile/my-cinetek"

import {
	MyCoords,
	MySubscription,
	MyScreens,
	MyPersonalData,
	MyPaymentHistory
} from "components/profile/params"

import {
	MyCard,
	MyCinepass,
	MyFilms as MyFilmsBilling
} from "components/profile/billing"

import {
	MySponsors,
	MyRewards
} from "components/profile/sponsor"

import PromoCode from "components/modals/promo-code"
import { PlaylistPage } from "components/profile/my-playlist"

export default [
	{
		target: "/profile",
		title: "my-films",
		icon: "icon-chair",
		children: [
			{
				title: "my-films",
				icon: "icon-chair",
				component: MyFilms
			}
		]
	},
	{
		target: "/subscription",
		title: "my-subscription",
		icon: "icon-selection"
	},
	{
		target: "/profile/playlists",
		title: "my-playlists",
		icon: "icon-playlist",
		children: [
			{
				title: "create-playlist",
				icon: "icon-playlist-add disabled",
				action: "createPlaylist"
			},
			{
				title: "my-playlists",
				icon: "icon-playlist",
				component: PlaylistPage,
				action: "showPlaylists"
			}
		]
	},
	{
		target: "/profile/parameters",
		title: "my-params",
		icon: "icon-parameters",
		children: [
			{
				title: "my-coords",
				icon: "icon-parameters",
				component: props => <MyCoords {...props} />
			},
			{
				title: "my-subscription",
				icon: "icon-selection",
				component: props => <MySubscription {...props} />
			},
			{
				title: "my-payment-history",
				icon: "icon-history",
				component: props => <MyPaymentHistory {...props} />
			},
			{
				title: "my-screens",
				icon: "icon-screen",
				component: props => <MyScreens {...props} />
			},
			{
				title: "my-personal-data",
				icon: "icon-lock-on",
				component: props => <MyPersonalData {...props} />
			}
		]
	},
	{
		target: "/profile/billing",
		title: "my-billing",
		icon: "icon-card",
		children: [
			{
				title: "my-card",
				icon: "icon-card",
				component: MyCard
			},
			{
				title: "my-cinepass",
				icon: "icon-cinepass",
				component: MyCinepass
			},
			{
				title: "my-credits",
				icon: "icon-ticket",
				component: MyFilmsBilling
			}
		]
	},
	{
		target: "/profile/sponsor",
		title: "sponsor-friends",
		description: "sponsor-friends-desc",
		icon: "icon-user",
		children: [
			{
				title: "my-sponsors",
				icon: "icon-guest",
				component: MySponsors
			},
			{
				title: "my-rewards",
				icon: "icon-reward",
				component: MyRewards
			}
		]
	},
	{
		title: "launch-player",
		icon: "icon-play bordered",
		action: "launchPlayer",
		props: {
			"data-overlay": "#overlay-play"
		}
	},
	{
		target: "/profile/apply-code",
		title: "apply-promo-code",
		icon: "icon-favorite",
		action: "showPromoCode",
		children: [
		],
		component: (props) => {
			return (<section>
				<PromoCode {...props} />
			</section>)
		},
		componentProps: {
			showTitle: false
		}
	}
]
