import { createContext, useContext, useEffect, useState } from "react"

const anchorNavContext = createContext()

export const ProvideAnchorNav = ({ children, entries }) => {
	const anchorNav = provideAnchorNav( entries )

	return (
		<anchorNavContext.Provider value={ anchorNav }>
			{ children }
		</anchorNavContext.Provider>
	)
}

export const useAnchorNav = () => {
	return useContext( anchorNavContext )
}

export const provideAnchorNav = () => {

	const [sections, _setSections] = useState( null )
	const [isSectionsSetted, setIsSectionsSetted] = useState( false )
	const [isSectionsLoaded, setIsSectionsLoaded] = useState( false )

	const setSections = sections => {
		if( sections && sections.length > 0 ) {
			const newSections = {}
			sections.forEach( section => { newSections[section.title] = false } )

			_setSections( newSections )
		} else {
			_setSections( null )
		}

		setIsSectionsSetted( true )
	}

	const setSectionLoaded = sectionId => {
		if( hasSectionSetted( sectionId ) ) {
			sections[sectionId] = true

			setIsSectionsLoaded( Object.values( sections ).every( loaded => loaded === true ) )
		}
	}

	const hasSection = sectionId => sections && sections[sectionId] !== undefined

	const hasSectionSetted = sectionId => hasSection( sectionId ) && sections[sectionId] === false

	useEffect(
		() => {
			const sectionHash = document.location.hash.replace('#', '')

			if( isSectionsLoaded && document && hasSection( sectionHash ) ) {
				const elem = document.getElementById( sectionHash )

				if( elem ) {
					window.scroll( 0, elem.offsetTop )
				}
			}
		},
		[isSectionsLoaded]
	)

	return {
		setSections,
		setSectionLoaded,
		isSectionsSetted,
		isSectionsLoaded
	}
}
