import { useCart } from "lib/cart"
import { useTranslation } from "lib/i18n/translation"

import Bundles from "components/common/bundles"
import Products from "components/common/products"
import Product from "./product"
import Bundle from "./bundle"
import Subscription from "./subscription"
import Pass from "./pass"
import PromoInput from "./promo"

const Cart = ({ tiny }) => {
	const { c } = useTranslation("common")
	const {
		cart,
		realTotalPrice,
		totalReduction,
		orderProducts,
		removeFromCart,
		removing
	} = useCart()

	const products = orderProducts()

	return (
		<div className="films table cart short has-actions">
			<div className="tbody">
				{(products.bundle.length > 0 || products.tvod.length > 0)
					?
					<div className="tr table-section">
						<div className="td">
							<h3 className="section-title icon-reel">{c("films")}</h3>
						</div>
					</div>
					: ""
				}
				{products.bundle.length > 0
					&& <Bundles bundles={products.bundle.map(t => t.product)}>
						{(baseBundles, loading) => (
							<>
								{products.bundle.map((baseProduct, index) => {
									const product = baseBundles.find(p => p.id == baseProduct.product.id)
									return (product && (
										<Bundle
											key={`product-${index}-${loading}`}
											loading={loading}
											product={product}
											accessMode={product.id !== 9999999 ? baseProduct.accessMode : null}
											remove={removeFromCart}
											removing={removing}
											isGift={products.bundle[index].gift}
											tiny={tiny}
										/>
									))
								})}
							</>
						)}
					</Bundles>
				}
				{products.tvod.length > 0
					&& <Products products={products.tvod.map(t => t.product)}>
						{(baseProducts, loading) => (
							<>
								{products.tvod.map((baseProduct, index) => {
									const product = baseProducts.find(p => p.id == baseProduct.product.id)
									return (product && (
										<Product
											key={`product-${index}-${loading}`}
											loading={loading}
											product={product}
											accessMode={product.id !== 9999999 ? baseProduct.accessMode : null}
											remove={removeFromCart}
											removing={removing}
											isGift={products.tvod[index].gift}
											tiny={tiny}
										/>
									))
								})}
							</>
						)}
					</Products>
				}
				{products.svod.length > 0
					&& <>
						{products.svod.map(({ product: subscription, accessMode, gift }, index) => (
							<Subscription
								key={`subscription-${index}`}
								subscription={subscription}
								accessMode={accessMode}
								remove={removeFromCart}
								removing={removing}
								isGift={gift}
								tiny={tiny}
							/>
						))}
					</>
				}
				{products.pass.length > 0
					&& <>
						<div className="tr table-section">
							<div className="td">
								<h3 className="section-title icon-cinepass">{c("cinepass-big-infos")}</h3>
							</div>
						</div>
						{products.pass.map(({ product: pass, accessMode, gift }, index) => (
							<Pass
								key={`cinepass-${index}`}
								pass={pass}
								accessMode={accessMode}
								remove={removeFromCart}
								removing={removing}
								isGift={gift}
								tiny={tiny}
							/>
						))}
					</>
				}
			</div>
			<div className="tfoot">
				{(products.svod.length > 0 || products.tvod.length > 0 || products.pass.length > 0)
					&& <>
						<PromoInput />
						{totalReduction > 0
							&& <>
								<div className="tr">
									<div className="td large">
										<span className="total">{c("total")}</span>
									</div>
									<div className="td large"></div>
									<div className="td">
										<span className="price">{realTotalPrice}&nbsp;€</span>
									</div>
									<div className="td actions disabled">
										<a
											href="#"
											onClick={e => { e.preventDefault(); }}
											rel="nofollow noopener"
											title={c("remove")}
											className="remove"
										>
											{c("remove")}
										</a>
									</div>
								</div>
								<div className="tr">
									<div className="td large">
										<span className="total">{c("vouchers-amount")}</span>
									</div>
									<div className="td large"></div>
									<div className="td">
										<span className="price">-{totalReduction}&nbsp;€</span>
									</div>
									<div className="td actions disabled">
										<a
											href="#"
											onClick={e => { e.preventDefault(); }}
											rel="nofollow noopener"
											title={c("remove")}
											className="remove"
										>
											{c("remove")}
										</a>
									</div>
								</div>
							</>
						}
						<div className="tr">
							<div className="td large">
								<span className="total">{c("net-total")}</span>
							</div>
							<div className="td large" />
							<div className="td">
								<span className="price total">{cart.totalPrice - cart.totalTrial}&nbsp;€</span>
							</div>
							<div className="td actions disabled">
								<a
									href="#"
									onClick={e => { e.preventDefault(); }}
									rel="nofollow noopener"
									title={c("remove")}
									className="remove"
								>
									{c("remove")}
								</a>
							</div>
						</div>
					</>
				}
			</div>
		</div>
	)
}

export default Cart
