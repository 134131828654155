import {
	metadata,
	FEATURE_STATUS,
	FEATURE_VALUE_STATUS_ACQUISITION,
	FEATURE_VALUE_STATUS_CATALOG,
	FEATURE_VALUE_STATUS_AVAILABLE_SOON
} from 'lacinetek-common/src/metadata'

export const isAvailable = (product) => {
	return metadata(product, FEATURE_STATUS, { oneOf: [FEATURE_VALUE_STATUS_ACQUISITION, FEATURE_VALUE_STATUS_CATALOG]})
}

export const isAvailableSoon = (product) => {
	return metadata(product, FEATURE_STATUS, { isEqual: FEATURE_VALUE_STATUS_AVAILABLE_SOON })
}
