import { useState } from "react"
import image, { DIRECTOR_AVATAR } from "lacinetek-common/src/images"
import SkeletonLink from "components/common/skeleton-link"
import { BONUS_ARCHIVES, BONUS_EXCLUSIFS } from "lib/tools/product"
import { Director, Actor } from "components/common"
import {
	metadata,
	FEATURE_BONUS_TYPE,
	FEATURE_DIRECTOR_ID,
	FEATURE_ACTOR_ID,
	FEATURE_BONUS_ARCHIVE_TITLE,
	FEATURE_BONUS_ARCHIVE_YEAR
} from "lacinetek-common/src/metadata"
import { Slider } from "components/common"
import { useVideoPlayer } from "lib/video-player"
import { useTranslation } from "lib/i18n/translation"
import { useBonus } from "lib/bonus"
import BonusesQuery from "components/common/bonuses-query"
import { baseBonus } from "components/common/skeleton"
import { useMainCategories } from "lib/tools/categories"
import Image from "components/common/image"
import classNames from "classnames"

export default ({
	productId,
	bonuses,
	type,
	director,
	loading,
	overlayed,
	innerRef,
	product,
	className,
	selected
}) => {
	if (bonuses) {
		return (
			<Content
				key={`external-bonuses-${type}${loading ? "-loading" : ""}`}
				bonuses={bonuses}
				type={type}
				director={director}
				loading={loading}
				overlayed={overlayed}
			/>
		)
	} else {
		return (
			<BonusesQuery
				productId={productId}
				type={type}
				renderLoading={() => (
					<Content
						key={"bonuses-loading"}
						product={product}
						productId={productId}
						bonuses={[baseBonus]}
						type={type}
						director={director}
						overlayed={overlayed}
						loading={true}
					/>
				)}
			>
				{(bonuses) => (
					<Content
						key={`bonuses-${type}`}
						product={product}
						productId={productId}
						bonuses={bonuses}
						type={type}
						director={director}
						overlayed={overlayed}
						innerRef={innerRef}
						className={className}
						selected={selected}
					/>
				)}
			</BonusesQuery>
		)
	}
}

const Slide = (props) => {
	const {
		bonus,
		type,
		director,
		loading,
		index
	} = props

	if (type == BONUS_ARCHIVES || director) {
		return <Bonus key={`bonuses-content-${index}`} {...props} loading={loading} />
	} else if (metadata(bonus, FEATURE_DIRECTOR_ID)) {
		return (
			<Director key={`bonuses-content-${index}`} id={metadata(bonus, FEATURE_DIRECTOR_ID)}>
				{(director) => (
					<Bonus {...props} director={director} />
				)}
			</Director>
		)
	} else if (metadata(bonus, FEATURE_ACTOR_ID)) {
		return (
			<Actor key={`bonuses-content-${index}`} id={metadata(bonus, FEATURE_ACTOR_ID)}>
				{(actor) => <Bonus {...props} director={actor} />}
			</Actor>
		)
	}
}

const SliderNavButton = ({ bonus, type, loading }) => {
	const { c } = useTranslation("common")

	return (
		<>
			<span className="label">{c("see-bonus")}</span>
			<figure>
				<picture>
					<Image
						src={
							!loading && bonus.cover
								? bonus.cover
								: "/static/images/default_corpus.jpg"
						}
						alt={`${c("bonus-single")} ${bonus.name}`}
					/>
				</picture>
			</figure>
			{type == BONUS_ARCHIVES
				? (
					<div>
						<div className={`quote${loading ? " skeleton" : ""}`}>
							<div className="source">
								<span>{bonus.name}</span>
								<br />
								<span className="title">{metadata(bonus, FEATURE_BONUS_ARCHIVE_TITLE)}</span>
								<br />
								<span className="source-date">{metadata(bonus, FEATURE_BONUS_ARCHIVE_YEAR)}</span>
							</div>
						</div>
					</div>
				)
				: (
					<>
						<div className={`is-recommended${loading ? " skeleton" : ""}`}>
							<p>
								<span className="list-link">
									{bonus.name}
								</span>
							</p>
						</div>
						<div className={`is-about${loading ? " skeleton" : ""}`}>
							{c("bonuses-about")}{" "}
							<SkeletonLink
								href="/film/[id]"
								as={`/film/${bonus.product.linkRewrite}`}
							>
								<span title={c("watch-the-movie")}>
									{bonus.product.name}
								</span>
							</SkeletonLink>
							<DirectorSpan bonus={bonus} />
						</div>
					</>
				)
			}
		</>
	)
}

const Content = ({
	bonuses,
	type,
	productId,
	director,
	loading,
	overlayed,
	innerRef,
	product,
	className,
	selected
}) => {
	const { seeBonus } = useVideoPlayer()
	const { c } = useTranslation("common")

	if (bonuses.length == 0) {
		return null
	}

	const typeClassName = type === BONUS_ARCHIVES ? " archives" : " exclusive"

	let overlayedBonuses = false

	const filteredBonuses = bonuses.filter(
		(bonus) =>
			!!bonus.product &&
			metadata(bonus, FEATURE_BONUS_TYPE) == type &&
			(type == BONUS_ARCHIVES ||
				director ||
				metadata(bonus, FEATURE_DIRECTOR_ID) ||
				metadata(bonus, FEATURE_ACTOR_ID))
	)

	if (filteredBonuses.length == 0) {
		return null
	}

	if (filteredBonuses.length > 1) {
		overlayedBonuses = true
	} else if (filteredBonuses.length === 1 && className) {
		switch (type) {
			case BONUS_EXCLUSIFS:
				return (
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault()
							seeBonus(filteredBonuses[0], true)
						}}
						rel="nofollow noopener"
						title={c("watch-exclusifs-bonus")}
						data-overlay="#overlay-bonus-exclusive"
						className="icon-bonus-exclusive exclusive"
					>
						{c("bonus-exclusifs-title")}
					</a>
				)
			case BONUS_ARCHIVES:
				return (
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault()
							seeBonus(filteredBonuses[0], true)
						}}
						rel="nofollow noopener"
						title={c("watch-archives-bonus")}
						data-overlay="#overlay-bonus-archives"
						className="bonus icon-bonus-archives archives"
					>
						{c("bonus-archives-title")}
					</a>
				)
		}
	}

	return (
		<section ref={innerRef || null}>
			<h2
				className={`section-title ${!overlayed ?
						type == BONUS_ARCHIVES ? "icon-bonus-archives" : "icon-bonus-exclusive"
						: null
					}`}
			>
				{c("bonus-single")}{" "}
				<strong>
					{type == BONUS_ARCHIVES
						? c("archives-single")
						: c("exclusifs-single")}
				</strong>
			</h2>
			<Slider
				slides={filteredBonuses}
				slideComponent={Slide}
				slidePropKey="bonus"
				slideRelatedProps={{
					overlayed,
					type,
					director,
					seeBonus,
					overlayedBonuses,
					product
				}}
				active={selected}
				loading={loading}
				className={classNames(
					"bonus",
					{ "list": !overlayed },
					{ "has-thumbnails": overlayed },
					typeClassName
				)}
				dataCount={filteredBonuses.length < 4 ? filteredBonuses.length : 4}
				navButtonComponent={overlayed ? SliderNavButton : undefined}

			/>
		</section>
	)
}

const Bonus = (props) => {
	let Component = null

	if (props.overlayed) {
		Component = OverlayedBonus
	} else {
		Component = FlatBonus
	}

	return <Component {...props} />
}

const FlatBonus = ({
	bonus,
	type,
	director,
	loading,
	seeBonus,
	overlayedBonuses,
	product,
	index
}) => {
	const { c } = useTranslation("common")
	const { allLists } = useMainCategories()
	const { showBonuses } = useBonus()

	let list
	if (metadata(bonus, FEATURE_DIRECTOR_ID)) {
		if (!director) {
			list = allLists.find(
				(l) =>
					l.director && l.director.id == metadata(bonus, FEATURE_DIRECTOR_ID)
			)
			if (list) {
				director = list.director
			}
		} else {
			list = director
				? allLists.find((l) => l.director && l.director.id == director.id)
				: null
		}
	}

	const bonusImage = bonus.cover
	const overlayTitle = null

	return (
		<>
			<a
				href="#"
				onClick={(e) => {
					e.preventDefault()
					overlayedBonuses && product
						? showBonuses(product, type, overlayTitle, index)
						: seeBonus(bonus, true)
				}}
				rel="nofollow noopener"
				title={c("see-bonus")}
				data-overlay={
					type === BONUS_ARCHIVES
						? "#overlay-bonus-archive"
						: "#overlay-bonus-exclusive"
				}
				className={`video-link ${type == BONUS_ARCHIVES ? "archives" : ""} ${loading ? "skeleton" : ""
					}`}
			>
				<span className="label">{c("see-bonus")}</span>
				<figure>
					<picture>
						<Image
							src={
								loading || !bonusImage
									? "/static/images/default_corpus.jpg"
									: bonusImage
							}
							alt={`${c("bonus-single")} ${bonus.name}`}
						/>
					</picture>
				</figure>
			</a>
			{type == BONUS_ARCHIVES ? (
				<div className="quote">
					<div className="source">
						<span>{bonus.name}</span>
						<br />
						<span className="title">{metadata(bonus, FEATURE_BONUS_ARCHIVE_TITLE)}</span>
						<br />
						<span className="source-date">{metadata(bonus, FEATURE_BONUS_ARCHIVE_YEAR)}</span>
					</div>
				</div>
			) : (
				<div className="infos">
					{director ? (
						list ? (
							<SkeletonLink
								href="/lists/[id]"
								as={`/lists/${list ? list.linkRewrite : null}`}
								loading={loading}
							>
								<a
									title={c("see-list")}
									className={`is-recommended ${loading ? "skeleton" : ""}`}
								>
									<figure className="avatar">
										<picture>
											<Image
												src={image(director, DIRECTOR_AVATAR)}
												alt={`${c("bonus-single")} ${bonus.name}`}
											/>
										</picture>
									</figure>
									<p>
										<span className="list-link">{director.name}</span>
									</p>
								</a>
							</SkeletonLink>
						) : (
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault()
								}}
								rel="nofollow noopener"
								className={`is-recommended ${loading ? "skeleton" : ""}`}
							>
								<figure className="avatar">
									<picture>
										<Image
											src={image(director, DIRECTOR_AVATAR)}
											alt={`${c("bonus-single")} ${bonus.name}`}
										/>
									</picture>
								</figure>
								<p>
									<span className="list-link">{director.name}</span>
								</p>
							</a>
						)
					) : null}
					<p className={`is-about ${loading ? "skeleton" : ""}`}>
						{c("bonuses-about")}{" "}
						<SkeletonLink
							href="/film/[id]"
							as={`/film/${bonus.product.linkRewrite}`}
						>
							<a title={c("watch-the-movie")}>
								<i>{bonus.product.name}</i>
							</a>
						</SkeletonLink>{" "}
						<DirectorSpan bonus={bonus} />
					</p>
					{bonus.description && bonus.description !== "" ? (
						<div className={`quote ${loading ? "hidden" : ""}`}>
							<blockquote>{bonus.description}</blockquote>
						</div>
					) : null}
				</div>
			)}
		</>
	)
}

const OverlayedBonus = ({ bonus, type, director, loading }) => {
	const { c } = useTranslation("common")
	const { allLists } = useMainCategories()
	const { seeBonus } = useVideoPlayer()
	const [bonusPlayer, setBonusPlayer] = useState(null)

	let list
	if (metadata(bonus, FEATURE_DIRECTOR_ID)) {
		if (!director) {
			list = allLists.find(
				(l) =>
					l.director && l.director.id == metadata(bonus, FEATURE_DIRECTOR_ID)
			)
			if (list) {
				director = list.director
			}
		} else {
			list = director
				? allLists.find((l) => l.director && l.director.id == director.id)
				: null
		}
	}

	const bonusImage = bonus.cover

	const showBonus = () => {
		setBonusPlayer(seeBonus(bonus))
	}

	return (
		<div className="slide-content">
			<div className="splitted vertical-centered">
				{bonusPlayer ? (
					bonusPlayer
				) : (
					<div className="videoplayer-container">
						<div
							className="youtube videoplayer"
							style={{
								backgroundImage: `url(${loading || !bonusImage
									? "/static/images/default_corpus.jpg"
									: bonusImage
									})`
							}}
						>
							<div className="play" onClick={() => showBonus()}></div>
						</div>
					</div>
				)}
				{type == BONUS_ARCHIVES ? (
					<div>
						<div className={`quote ${loading ? "skeleton" : ""}`}>
							<div className="source">
								<span>{bonus.name}</span>
								<br />
								<span className="title">{metadata(bonus, FEATURE_BONUS_ARCHIVE_TITLE)}</span>
								<br />
								<span className="source-date">{metadata(bonus, FEATURE_BONUS_ARCHIVE_YEAR)}</span>
							</div>
						</div>
					</div>
				) : (
					<div className="infos">
						{director ? (
							list ? (
								<SkeletonLink
									href="/lists/[id]"
									as={`/lists/${list.linkRewrite}`}
									loading={loading}
								>
									<a
										title={c("see-list")}
										className={`is-recommended ${loading ? "skeleton" : ""}`}
									>
										<figure className="avatar">
											<picture>
												<Image
													src={image(director, DIRECTOR_AVATAR)}
													alt={`${c("bonus-single")} ${bonus.name}`}
												/>
											</picture>
										</figure>
										<p>
											<span className="list-link">{director.name}</span>
										</p>
									</a>
								</SkeletonLink>
							) : (
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault()
									}}
									rel="nofollow noopener"
									className="is-recommended"
								>
									<figure className="avatar">
										<picture>
											<Image
												src={image(director, DIRECTOR_AVATAR)}
												alt={`${c("bonus-single")} ${bonus.name}`}
											/>
										</picture>
									</figure>
									<p>
										<span className="list-link">{director.name}</span>
									</p>
								</a>
							)
						) : null}
						<p className={`is-about ${loading ? "skeleton" : ""}`}>
							{c("bonuses-about")}{" "}
							<SkeletonLink
								href="/film/[id]"
								as={`/film/${bonus.product.linkRewrite}`}
							>
								<a title={c("watch-the-movie")}>
									<i>{bonus.product.name}</i>
								</a>
							</SkeletonLink>{" "}
							<DirectorSpan bonus={bonus} />
						</p>
						{bonus.description && bonus.description !== "" ? (
							<div className="quote">
								<blockquote>{bonus.description}</blockquote>
							</div>
						) : null}
					</div>
				)}
			</div>
		</div>
	)
}

const DirectorSpan = ({ bonus }) => {
	const { c } = useTranslation("common")
	const director = bonus.product?.extension?.directors?.items[0]

	if (!director) {
		return null
	}

	return (
		<>
			<br />
			{c("of")}{" "}
			<span>
				{director.director?.name}
			</span>
		</>
	)
}
