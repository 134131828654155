import { useEffect } from "react"
import { useQuery } from "@apollo/react-hooks"
import HtmlParser from "react-html-parser"
import { SVOD_BLOCK_TEXT, SVOD_BLOCK_TYPE, metadata } from "lacinetek-common/src/metadata"
import { CATEGORY_SELECTION, imagesOfType } from "lacinetek-common/src/images"

import { useCountry } from "lib/country"
import { useTranslation } from "lib/i18n/translation"
import { FETCH_SVOD_CATEGORIES } from "lib/graphql"
import { useLanguages } from "lib/languages"
import { isSubscriptionOffer } from "tools/subscription"

import { BlockTitle } from "components/subscription/block-title"
import { FooterOffer } from "components/subscription/footer-offer"
import SkeletonLink from "../common/skeleton-link"
import Image from "../common/image"

import OffersSingleIcon from "static/assets/icons/offers-single.svg"
import OffersSelectionIcon from "static/assets/icons/offers-selection.svg"
import OffersLacinetekIcon from "static/assets/icons/offers-lacinetek.svg"

export const Offers = () => {
	const { c } = useTranslation("common")
	const { country: { isoCode } } = useCountry()
	const { current } = useLanguages()
	const { data, loading, error, refetch } = useQuery(FETCH_SVOD_CATEGORIES, {
		variables: {
			query: `nos-offres-${isoCode}`
		}
	})

	useEffect(() => {
		refetch()
	}, [current])

	if (loading) {
		return (
			<div className="is-loading centered" />
		)
	}


	const offers = data.cms.categories.items[0]
	const bannerImg = imagesOfType(offers, CATEGORY_SELECTION)[0]

	return (
		<>
			{
				!!bannerImg
				&& <section className="focus full no-fx">
					<figure className="cover full">
						<picture>
							<Image
								src={bannerImg}
								alt={offers.descriptionShort}
							/>
						</picture>
					</figure>
				</section>
			}
			<BlockTitle block={offers} className="main-title" />
			{
				offers.description
				&& HtmlParser(offers.description)
			}
			<nav className="offers blocks">
				{
					offers.children.items
						.filter(offer => metadata(offer, SVOD_BLOCK_TYPE) !== "offrir")
						.map((offer, index) => <OfferComponent key={`offer-${index}`} offer={offer} />)
				}
				<div className="item">
					<SkeletonLink href="/offer-lacinetek" >
						<a href="#" title="En savoir plus" className="item-content">
							<figure className="small">
								<OffersLacinetekIcon title={c("gift-icon")} />
							</figure>
							<div className="infos">
								<h3 className="title">{c("gift-icon")}</h3>
							</div>
						</a>
					</SkeletonLink>
				</div>
			</nav>
			<FooterOffer />
		</>
	)
}

const OfferComponent = ({ offer }) => {
	const { c } = useTranslation("common")

	const target = isSubscriptionOffer(offer) ? "/ways-to-watch/subscription" : "/ways-to-watch/film-by-film"

	return (
		<div className="item">
			<SkeletonLink href={target} >
				<a href="#" title={c("learn-more-about")} className="item-content">
					<div className="inline">
						<figure className="medium">
							{
								isSubscriptionOffer(offer)
									? <OffersSelectionIcon title={c("month-selection-icon-title")} />
									: <OffersSingleIcon title={c("tickets-icon")} />
							}
						</figure>
						<div className="infos">
							<h3 className="title">{isSubscriptionOffer(offer) ? c("renew-for") : c("header-single")}</h3>
							<p className="price" dangerouslySetInnerHTML={{ __html: offer.description }} />
						</div>
					</div>
					{
						metadata(offer, SVOD_BLOCK_TEXT)
						&& <div className="description">
							{HtmlParser(metadata(offer, SVOD_BLOCK_TEXT))}
						</div>
					}
				</a>
			</SkeletonLink>
		</div>
	)
}
