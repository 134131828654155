import { ProductImage } from "components/common"
import { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"
import { useTranslation } from "lib/i18n/translation"

import SkeletonLink from "components/common/skeleton-link"

const Product = ({product, minified, loading}) => {
	const { c } = useTranslation("common")

	if (!product) return null

	return (
		<div className={`reel ${minified ? "min" : ""}`} key={`product-component-${product.id}-${loading}`}>
			<div className="reel-description">
				<SkeletonLink href="/film/[id]" as={`/film/${product.linkRewrite}`}>
					<a title="Voir la fiche" className={`title ${loading ? "skeleton" : ""}`}>{product.name}</a>
				</SkeletonLink>
				<div className={`film-infos ${loading ? "skeleton" : ""}`}>

					<span className="director">{product.director}</span>
					<span className="year">{product.year}</span>
					<span className="country">{product.origin}</span>
					<span className="duration">{product.duration}</span>
				</div>
			</div>
			<SkeletonLink href="/film/[id]" as={`/film/${product.linkRewrite}`}>
				<a title="Voir la fiche" className={`reel-bg ${loading ? "skeleton-container" : ""}`}>
					<ProductImage product={product} type={PRODUCT_PHOTOGRAMME} loading={loading} />
					<ProductImage product={product} type={PRODUCT_PHOTOGRAMME} loading={loading} />
					<ProductImage product={product} type={PRODUCT_PHOTOGRAMME} loading={loading} />
					<ProductImage product={product} type={PRODUCT_PHOTOGRAMME} loading={loading} />
					{ minified
						? <>
								<ProductImage product={product} type={PRODUCT_PHOTOGRAMME} loading={loading} />
								<ProductImage product={product} type={PRODUCT_PHOTOGRAMME} loading={loading} />
								<ProductImage product={product} type={PRODUCT_PHOTOGRAMME} loading={loading} />
								<ProductImage product={product} type={PRODUCT_PHOTOGRAMME} loading={loading} />
							</>
						: null
					}
				</a>
			</SkeletonLink>
		</div>
	)
}

export default Product
