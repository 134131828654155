import slugify from "slugify"

const playlistSlug = (playlist) => {
	const slug = slugify(playlist.name, { lower: true, strict: true })
		.replace(/--+/g, "-")
		.replace(/^-*([^-].*[^-]+)?-*$/g, "$1")

	if (!slug) {
		return `${playlist.id}`
	}

	return `${playlist.id}_${encodeURIComponent(slug)}`
}

export default playlistSlug
