import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import { useTranslation } from "lib/i18n/translation"
import { isBrowser } from "lib/tools"
import { carouselClicSubscriptionDataLayer } from "lib/gtm/interactions"

const SliderNavigation = ({
	slides,
	slidePropKey,
	slideRelatedProps,
	config,
	currentPage,
	setCurrentPage,
	className,
	buttonComponent: ButtonComponent,
	hidePagination,
	paginationClassName,
	gtmAction,
	loading
}) => {
	const { c } = useTranslation("common")

	const previousDisabled = currentPage === 0
	const nextDisabled = currentPage === config.pages - 1

	if (config.pages < 2) {
		return null
	}

	return (
		<nav
			className={`slider-controls${loading ? " skeleton" : ""}${className ? ` ${className}` : ""}`}
			aria-label={c("carrousel-controls")}
		>
			<a
				href="#"
				role="button"
				rel="nofollow noopener"
				className={`slider-arrow slider-arrow--left previous${previousDisabled ? " disabled" : ""}${loading ? " skeleton" : ""}`}
				onClick={(e) => {
					e.preventDefault()
					if (gtmAction) {
						carouselClicSubscriptionDataLayer("Précédent")
					}
					if (previousDisabled) {
						return
					}

					setCurrentPage(page => page - 1)
				}}
			>
				{c("previous")}
			</a>
			{!hidePagination && (
				<nav
					className={`slider-pagination${paginationClassName ? ` ${paginationClassName}` : ""}`}
					aria-label={c("carrousel-pagination")}
				>
					{Array.from({ length: config.pages }).map((_, index) => (
						<a
							key={index}
							href="#"
							rel="nofollow noopener"
							className={`slider-nav-button${currentPage === index ? " slider-nav-button--active active" : ""}`}
							tabIndex="0"
							aria-current={currentPage === index}
							aria-label={`slide ${index + 1} / ${config.pages}`}
							onClick={(e) => {
								e.preventDefault()
								setCurrentPage(index)
							}}
						>
							{ButtonComponent && config.pages === slides.length
								? (
									<ButtonComponent
										{...(slidePropKey
											? {
												[slidePropKey]: slides[index]
											}
											: slides[index]
										)}
										{...slideRelatedProps}
										index={index}
										loading={loading}
									/>
								)
								: <span className="label">{index + 1}</span>
							}

						</a>
					))}
				</nav>
			)}
			<a
				href="#"
				role="button"
				rel="nofollow noopener"
				className={`slider-arrow slider-arrow--right next${nextDisabled ? " disabled" : ""}${loading ? " skeleton" : ""}`}
				onClick={(e) => {
					e.preventDefault()
					if (gtmAction) {
						carouselClicSubscriptionDataLayer("Suivant")
					}
					if (nextDisabled) {
						return
					}

					setCurrentPage(page => page + 1)
				}}
			>
				{c("next")}
			</a>
		</nav>
	)
}

const getKeyExtractor = (item, index) => index

const Slider = ({
	id,
	slides = [],
	slideComponent: SlideComponent,
	slidePropKey,
	slideRelatedProps,
	slideClassName,
	active = 0,
	asList = true,
	dataCount,
	loading,
	className,
	navClassName,
	navButtonComponent,
	hidePagination,
	paginationClassName,
	touchable = true,
	gtmAction = false,
	keyExtractor = getKeyExtractor
}) => {
	const slidesRef = useRef(null)
	const { current: touchableProps } = useRef({
		dragX: undefined,
		dragStart: undefined,
		dragStartTime: undefined
	})

	const [isReady, setIsReady] = useState(false)
	const [config, setConfig] = useState({
		pages: 0,
		slideWidth: 0,
		maxMarginLeft: 0
	})
	const [currentPage, setCurrentPage] = useState(active)
	const [marginLeft, setMarginLeft] = useState(0)
	const [hasTransition, setHasTransition] = useState(true)

	const setCurrentPageMargin = () => {
		setMarginLeft((currentPage === (config.pages - 1) ? config.maxMarginLeft : config.slideWidth * currentPage) * -1)
	}

	useEffect(
		() => {
			setCurrentPageMargin()
		},
		[currentPage]
	)

	useEffect(
		() => {
			if (!slidesRef.current || !isBrowser) {
				return
			}

			const initConfig = () => {
				// Because resizeObserver can be triggered even if component was unmounted
				if (!slidesRef.current) {
					return
				}

				const itemWidth = slidesRef.current.firstChild.offsetWidth
				const slideWidth = slidesRef.current.offsetWidth
				const totalWidth = itemWidth * slides.length
				const needOneMorePage = ((totalWidth % slideWidth) / itemWidth) >= 0.5
				const pages = needOneMorePage ? Math.ceil(totalWidth / slideWidth) : Math.round(totalWidth / slideWidth)
				const maxMarginLeft = pages > 1 ? totalWidth - slideWidth : 0

				setHasTransition(false)

				setConfig({
					slideWidth,
					pages,
					maxMarginLeft
				})

				// Force marginLeft
				if (active === currentPage) {
					setMarginLeft((currentPage === (pages - 1) ? maxMarginLeft : slideWidth * currentPage) * -1)
				} else {
					setCurrentPage(active)
				}

				setTimeout(
					() => {
						setHasTransition(true)
					},
					10
				)
			}

			initConfig()

			const resizeObserver = new ResizeObserver(initConfig)
			resizeObserver.observe(slidesRef.current)

			setIsReady(true)

			return () => {
				if (!slidesRef.current) {
					return
				}

				resizeObserver.unobserve(slidesRef.current)
			}
		},
		[slides]
	)

	const onTouchStart = (e) => {
		touchableProps.dragX = e.touches[0].pageX
		touchableProps.dragStart = e.touches[0].pageX
		touchableProps.dragStartTime = new Date()
		setHasTransition(false)
	}

	const onTouchMove = (e) => {
		const x = e.touches[0].pageX
		const offset = touchableProps.dragX - x
		const ratio = offset < 0 && currentPage === 0 || offset > 0 && currentPage === (config.pages - 1) ? .1 : 1
		touchableProps.dragX = x
		const SCROLL_OFFSET_TO_STOP_SCROLL = 30

		// Stop scrolling if you slide more than 30 pixels
		if (Math.abs(offset) > SCROLL_OFFSET_TO_STOP_SCROLL) {
			e.stopPropagation()
			e.preventDefault()
		}

		setMarginLeft(marginLeft => (marginLeft - offset * ratio))
	}

	const onTouchEnd = () => {
		const timeElapsed = new Date().getTime() - touchableProps.dragStartTime.getTime()
		const offset = (touchableProps.dragX - touchableProps.dragStart) / config.slideWidth
		const velocity = Math.round((offset / timeElapsed) * 10000)

		setHasTransition(true)

		// Reset margin if velocity is not strong enougth
		if (Math.abs(velocity) < 5) {
			setCurrentPageMargin()

			return
		}

		let newPage = currentPage - (offset >= 0 ? Math.ceil(offset) : Math.floor(offset))

		if (newPage < 0) {
			newPage = 0
		} else if (newPage >= config.pages) {
			newPage = config.pages - 1
		}

		// Force marginLeft
		if (newPage === currentPage) {
			setCurrentPageMargin()
		}

		setCurrentPage(newPage)
	}

	if (slides.length === 0) {
		return null
	}

	const SlidesTag = asList ? "ul" : "div"
	const SlideTag = asList ? "li" : "div"

	return (
		<div
			id={id}
			className={`slider${className ? ` ${className}` : ""}`}
			data-count={dataCount}
		>
			<div
				className="slider-container"
				{...(
					touchable
						? {
							onTouchStart,
							onTouchMove,
							onTouchEnd
						}
						: {}
				)}
			>
				<SlidesTag
					ref={slidesRef}
					className="slides"
					style={{
						transform: `translate3d(${marginLeft}px, 0px, 0px)`,
						transitionDuration: hasTransition ? "250ms" : undefined,
						overflow: "visible"
					}}
					aria-live="polite"
				>
					{slides.map((slide, index) => (
						<SlideTag
							key={keyExtractor(slide, index)}
							className={`slide${slideClassName ? ` ${slideClassName}` : ""}`}
							role="group"
							aria-label={`${index + 1} / ${slides.length}`}
						>
							<SlideComponent
								{...(slidePropKey
									? {
										[slidePropKey]: slide
									}
									: slide
								)}
								{...slideRelatedProps}
								index={index}
								loading={loading || !isReady}
								gtmAction={gtmAction}
							/>
						</SlideTag>
					))}
				</SlidesTag>
			</div>
			<SliderNavigation
				slides={slides}
				slidePropKey={slidePropKey}
				slideRelatedProps={slideRelatedProps}
				config={config}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				className={navClassName || className}
				buttonComponent={navButtonComponent}
				hidePagination={hidePagination}
				paginationClassName={paginationClassName}
				loading={loading || !isReady}
				gtmAction={gtmAction}
			/>
		</div>
	)
}

Slider.propTypes = {
	id: PropTypes.string,
	slides: PropTypes.array.isRequired,
	slideComponent: PropTypes.elementType.isRequired,
	slidePropKey: PropTypes.string,
	slideRelatedProps: PropTypes.object,
	slideClassName: PropTypes.string,
	active: PropTypes.number,
	asList: PropTypes.bool,
	dataCount: PropTypes.number,
	loading: PropTypes.bool,
	gtmAction: PropTypes.bool,
	className: PropTypes.string,
	navClassName: PropTypes.string,
	/**
	 * navButtonComponent is rendered only if slide width is 100 %
	 */
	navButtonComponent: PropTypes.elementType,
	hidePagination: PropTypes.bool,
	paginationClassName: PropTypes.string,
	touchable: PropTypes.bool
}

export default Slider
