import { useTranslation } from "lib/i18n/translation"
import { useAuth } from "lib/auth"
import { useAnchorNav } from "lib/anchor-nav"
import { useEffect, useState } from "react"
import slugify from "slugify"

const MyPersonalData = ({title}) => {

	const { c } = useTranslation("common")
	const { removeAccount, removingAccount } = useAuth()
	const [error, setError] = useState(false)

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	const handleAccountDeletion = (event) => {
		event.preventDefault()
		setError(false)
		removeAccount().then(result => {
			if (typeof result === "boolean" ) {
				setError(!result)
			}
		})
	}

	useEffect(
		() => {
			setSectionLoaded( title )
		},
		[isSectionsSetted]
	)

	return (
		<section id={ slugify( c( title ) ).toLowerCase() } className="section-anchor">
			<h2 className="section-title icon-lock-on">{c("my-personal-data")}</h2>
			<h3>{c("access-to-my-data")}</h3>
			<p>{c("access-data-text")}</p>
			<nav className="buttons">
				<a href="#" onClick={e => { e.preventDefault(); }} rel="nofollow noopener" title={c("export-data-pdf")} className="icon-download disabled">{c("export-data-pdf")}</a>
				<a href="#" onClick={e => { e.preventDefault(); }} rel="nofollow noopener" title={c("export-data-csv")} className="icon-download disabled">{c("export-data-csv")}</a>
				<a href="#" onClick={handleAccountDeletion} rel="nofollow noopener" title={c("delete-account")} className={`icon-trash ${removingAccount ? "is-loading" : ""}`}>{c("delete-account")}</a>
			</nav>
			{ error
				? <span className="red">{c("unable-to-delete-account")}</span>
				: null
			}
			<h3>{c("request-rectification-deletion")}</h3>
			<p>{c("request-text")}</p>
		</section>
	)
}

export default MyPersonalData
