import { useEffect, useState } from "react"
import moment from "moment"
import slugify from "slugify"
import { useApolloClient } from "@apollo/react-hooks"
import classNames from "classnames"

import Orders from "components/common/orders"

import { useTranslation } from "lib/i18n/translation"
import { FETCH_INVOICE } from "lib/graphql"
import { useAnchorNav } from "lib/anchor-nav"
import { useModal } from "lib/modal"

const PaymentHistory = ({ title }) => {
	const { show } = useModal()
	const { c } = useTranslation("common")
	const client = useApolloClient()

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	const [ordersLoaded, setOrdersLoaded] = useState(false)
	const [loadingMore, setLoadingMore] = useState(false)

	const getGiftInOrder = (order) => {
		let gifts = []

		order.cart.content.items.forEach(item => {
			if (item.gift !== null && item.product.type !== "TVOD") {
				gifts.push(item.gift)
			}
		})

		return gifts
	}

	const openInvoice = (orderId) => {
		client.query({
			query: FETCH_INVOICE,
			variables: { id: orderId },
			fetchPolicy: 'cache-first'
		})
			.then(({ data: { billing: { orders: { items: [{ invoice }] } } } }) => window.open(invoice))
	}

	const paymentHistoryGiftmodal = (orderId, gifts) => {
		show({
			id: "add-svod-to-cart",
			children: <div class="overlay-content">
				<h2 class="overlay-title icon-gift" dangerouslySetInnerHTML={{ __html: c("my-gifts") }} />
				<div class="table cart short">
					<div class="thead hidden">
						<div class="tr">
							<div class="td"><span>{c("beneficiary")}</span></div>
							<div class="td min"><span>PDF</span></div>
						</div>
					</div>
					<div class="tbody">
						{gifts.map(gift => {
							return (
								<div class="tr">
									<div class="td"><span>{gift.firstname} {gift.lastname}</span></div>
									<div class="td min">
										<nav class="buttons small centered">
											<a
												href={`/gift-pdf/${orderId}/${gift.id}`}
												target="_blank"
												rel="nofollow noopener"
												title="Télécharger le PDF pour nom_de_l_utilisateur"
												class="icon-download with-icon-after">
												<span>PDF</span>
											</a>
										</nav>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>,
			className: "modal"
		})
	}

	useEffect(
		() => {
			if (ordersLoaded) {
				setSectionLoaded(title)
			}
		},
		[isSectionsSetted, ordersLoaded]
	)

	return (
		<section id={slugify(c(title)).toLowerCase()} className="section-anchor">
			<h2 className="section-title icon-history">{c("payment-history-strong")}</h2>
			<Orders
				renderLoading={() => <div className="is-loading" />}
				onError={() => <span style={{ paddingTop: "10px" }}>{c("error-thrown")}</span>}
			>
				{({ orders, hasMoreToLoad, fetchMore }) => {
					setOrdersLoaded(true)

					const loadMoreBills = () => {
						setLoadingMore(true)
						fetchMore()
							.finally(() => {
								setLoadingMore(false)
							})
					}

					if (orders.length === 0) {
						return (
							<div className="description">
								{c("dont-have-orders-here-yet")}
							</div>
						)
					}

					return (
						<>
							<table className="table">
								<thead className="thead">
									<tr className="tr">
										<td className="td"><span>{c("date")}</span></td>
										<td className="td"><span>{c("amount-ttc")}</span></td>
										<td className="td"><span>{c("invoice")}</span></td>
									</tr>
								</thead>
								<tbody className="tbody">
									{orders.map(order => {
										const gifts = getGiftInOrder(order)

										return (
											<tr key={`order-item-${order.id}`} className="tr">
												<td className="td">
													<span>{moment(order.dateAdd).format("DD/MM/YYYY HH:mm")}</span>
												</td>
												<td className="td">
													<span>{order.cart ? order.cart.totalPrice : "-"} €</span>
												</td>
												<td className="td">
													<nav className="buttons small centered">
														<a
															href="#"
															onClick={e => { e.preventDefault(); openInvoice(order.id) }}
															rel="nofollow noopener"
															title={c("see-invoice")}
															className="icon-file"
														>
															{c("see-invoice")}
														</a>

														{gifts.length == 1 && (
															<a
																target="_blank"
																href={`/gift-pdf/${order.id}/${gifts[0].id}`}
																rel="nofollow noopener"
																title={c("see-gift")}
																className="icon-gift"
															>
																{c("see-gift")}
															</a>
														)}

														{gifts.length > 1 && (
															<a
																rel="nofollow noopener"
																onClick={e => { e.preventDefault(); paymentHistoryGiftmodal(order.id, gifts) }}
																title={c("see-gifts")}
																className="icon-gift"
															>
																{c("see-gifts")}
															</a>
														)}
													</nav>
												</td>
											</tr>
										)
									})}
								</tbody>

							</table>
						{hasMoreToLoad && (
							<div>
								<button
									className={classNames("button full centered", {
										"icon-more": !loadingMore,
										"is-loading": loadingMore
									})}
									disabled={loadingMore}
									onClick={() => loadMoreBills()}
								>
									{c("show-more")}
								</button>
							</div>
						)}
						</>
					)
				}}
			</Orders>
		</section>
	)
}

export default PaymentHistory
